import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { sampleImage1 } from '../../../assets/images';
import {
  iconArrowSmall,
  iconCalendar,
  iconComment,
  iconList,
} from '../../../assets/images/icon';
import useFetchData from '../../../hooks/useFetchData';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import { actions as homeActions } from '../../../store/home';
import LoadingContent from '../LoadingContent';

function StackDiaryQnaList(): JSX.Element {
  const { removeStackScreen, replaceStackScreen } = useStack();
  const { selectedDiaryId, selectedDiaryInfo } = useSelector(
    (state: RootState) => ({
      selectedDiaryId: state.common.selectedDiaryId,
      selectedDiaryInfo: state.common.selectedDiaryInfo,
    }),
    shallowEqual,
  );
  const { questionListDate, questionList } = useSelector(
    (state: RootState) => ({
      questionListDate: state.home.questionListDate,
      questionList: state.home.questionList,
    }),
    shallowEqual,
  );

  const fetchQuestionList = async () => {
    if (questionListDate.year === null || questionListDate.month === null)
      return;

    dispatch(
      homeActions.setValue('questionList', {
        loading: true,
        data: null,
        error: null,
      }),
    );

    try {
      const { data } = await axios.get(
        `/diaries/${selectedDiaryId}/questions/list`,
        {
          params: {
            year: questionListDate.year,
            month: questionListDate.month,
          },
        },
      );
      if (data.success) {
        console.log('question list : ', data);
        dispatch(
          homeActions.setValue('questionList', {
            loading: false,
            data: data.questions,
            error: null,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dispatch = useDispatch();
  const setCurrentDate = () => {
    if (questionListDate.year !== null) return;

    const year = selectedDiaryInfo.data?.leftAt?.split('-')[0];
    const month = selectedDiaryInfo.data?.leftAt?.split('-')[1];

    dispatch(
      homeActions.setValue('questionListDate', {
        year,
        month,
      }),
    );
  };

  useEffect(() => {
    fetchQuestionList();
  }, [questionListDate, selectedDiaryId]);

  useEffect(() => {
    setCurrentDate();
  }, [questionListDate, selectedDiaryInfo]);

  const onChangeYear = useCallback(
    (e) => {
      dispatch(
        homeActions.setValue('questionListDate', {
          year: e.target.value,
          month: questionListDate.month,
        }),
      );
    },
    [questionListDate],
  );

  const onChangeMonth = useCallback(
    (e) => {
      dispatch(
        homeActions.setValue('questionListDate', {
          year: questionListDate.year,
          month: e.target.value,
        }),
      );
    },
    [questionListDate],
  );

  // const { fetchDiaryDetail } = useFetchData();
  const onClickQuestionItem = useCallback(
    (date: string) => {
      // fetchDiaryDetail(date);
      dispatch(commonActions.setValue('selectedDiaryDate', date));
      removeStackScreen();
    },
    [removeStackScreen],
  );

  return (
    <Container>
      <StackHeader
        title={
          selectedDiaryInfo.data
            ? `${selectedDiaryInfo.data.name}(${selectedDiaryInfo.data.members.length})`
            : ''
        }
        btnRight={
          <BtnListType>
            <button type="button" className="btn-list">
              <span />
            </button>
            <span className="divider" />
            <button
              type="button"
              className="btn-calendar"
              onClick={() => replaceStackScreen('StackDiaryQnaCalendar')}
            >
              <span />
            </button>
          </BtnListType>
        }
      />
      <Content>
        <div className="month-selector">
          <div className="selector-wrap">
            <select
              value={questionListDate.year ? questionListDate.year : '2022'}
              onChange={onChangeYear}
            >
              <option value="2022">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
              <option value="2017">2017</option>
              <option value="2016">2016</option>
              <option value="2015">2015</option>
              <option value="2014">2014</option>
              <option value="2013">2013</option>
              <option value="2012">2012</option>
              <option value="2011">2011</option>
              <option value="2010">2010</option>
            </select>
            <img src={iconArrowSmall} alt="화살표 아이콘" />
          </div>
          <div className="selector-wrap">
            <select
              value={questionListDate.month ? questionListDate.month : '01'}
              onChange={onChangeMonth}
            >
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <img src={iconArrowSmall} alt="화살표 아이콘" />
          </div>
        </div>
        {questionList.loading && !questionList.data && <LoadingContent />}

        {!questionList.loading && questionList.data && (
          <DiaryQnaList>
            <div className="top">
              <p className="total">전체({questionList.data.totalCount})</p>
              {questionList.data.unAnswered.length > 0 && (
                <p className="no-answer">
                  미답변({questionList.data.unAnswered.length})
                </p>
              )}
            </div>

            {questionList.data.unAnswered.map((question) => (
              <DiaryQnaItem
                key={question.id}
                onClick={() => onClickQuestionItem(question.createdAt)}
              >
                <div className="content">
                  <div className="info">
                    <span className="num color-main">Q. {question.number}</span>
                    <span className="date">{question.createdAt}</span>
                  </div>
                  <p className="desc">{question.content}</p>
                  <div className="comment-count">
                    <img src={iconComment} alt="말풍선 아이콘" />
                    <span>{question.totalCommentCount}</span>
                  </div>
                </div>
              </DiaryQnaItem>
            ))}

            {questionList.data.answered.length > 0 &&
              questionList.data.unAnswered.length > 0 && <Divider />}

            {questionList.data.answered.map((question) => (
              <DiaryQnaItem
                key={question.id}
                onClick={() => onClickQuestionItem(question.createdAt)}
              >
                <div className="content">
                  <div className="info">
                    <span className="num complete">Q. {question.number}</span>
                    <span className="date">{question.createdAt}</span>
                  </div>
                  <p className="desc">{question.content}</p>
                  <div className="comment-count">
                    <img src={iconComment} alt="말풍선 아이콘" />
                    <span>{question.totalCommentCount}</span>
                  </div>
                </div>
              </DiaryQnaItem>
            ))}
          </DiaryQnaList>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BtnListType = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 0 !important;
    padding: 8px;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 24px;
    }
  }

  .btn-list {
    span {
      background-image: url(${iconList});
      background-position-y: -24px;
    }
  }
  .btn-calendar {
    padding-right: 0;
    span {
      background-image: url(${iconCalendar});
    }
  }

  .divider {
    display: inline-block;
    width: 1px;
    height: 15px;
    background-color: ${({ theme }) => theme.color.gray2};
  }
`;

const Content = styled.div`
  flex: 1;

  .month-selector {
    text-align: center;
    padding: 10px 0;

    .selector-wrap {
      display: inline-block;
      position: relative;

      select {
        font-family: 'NanumSquare';
        font-weight: 700;
      }

      img {
        width: 24px;
        transform: rotate(90deg);
      }
    }
  }
`;

const DiaryQnaList = styled.div`
  flex: 1;

  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    .total {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
    }

    .no-answer {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.main1};
    }
  }
`;

const DiaryQnaItem = styled.div`
  display: flex;
  padding: 15px;

  .content {
    flex: 1;
    min-height: 76px;
    position: relative;

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;

      .num {
        font-size: 1.2rem;
        font-weight: 700;

        &.color-main {
          color: ${({ theme }) => theme.color.main1};
        }
      }
      .date {
        font-size: 1rem;
        color: ${({ theme }) => theme.color.gray1};
      }
    }

    .desc {
      width: 85%;
    }

    .comment-count {
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        margin-right: 5px;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }
`;

const Divider = styled.div`
  margin: 15px 0;
  height: 1px;
  border-top: 1px dashed ${({ theme }) => theme.color.gray2};
`;

export default StackDiaryQnaList;

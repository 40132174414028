import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { btnClose, sampleProfile1 } from '../../../assets/images';
import useFetchData from '../../../hooks/useFetchData';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';

interface IProps {
  isDiaryComment?: boolean;
  // content: string;
  commentWriteStatus: 'post' | 'edit';
  setCommentWriteStatus: (status: 'post' | 'edit') => void;
  setCommentInputView?: (visible: boolean) => void;
}

function WriteCommentInput({
  isDiaryComment = false,
  // content,
  commentWriteStatus,
  setCommentWriteStatus,
  setCommentInputView,
}: IProps): JSX.Element {
  const {
    myProfile,
    selectedDiaryDetail,
    selectedComment,
    selectedOpenSpaceId,
    safeArea,
    fetchOpenSpaceFn,
  } = useSelector(
    (state: RootState) => ({
      myProfile: state.common.myProfile,
      selectedDiaryDetail: state.common.selectedDiaryDetail,
      selectedComment: state.common.selectedComment,
      selectedOpenSpaceId: state.common.selectedOpenSpaceId,
      safeArea: state.common.safeArea,
      fetchOpenSpaceFn: state.common.fetchOpenSpaceFn,
    }),
    shallowEqual,
  );
  const [commentContent, setCommentContent] = useState('');
  const commentInputRef = useRef<HTMLTextAreaElement>(null);
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedComment?.content.length === 0) setCommentContent('');
    if (selectedComment?.content) setCommentContent(selectedComment.content);
  }, [selectedComment]);

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (commentInputRef.current) {
      commentInputRef.current.style.height = '0';
      if (commentInputRef.current.scrollHeight < 90) {
        commentInputRef.current.style.height = `${commentInputRef.current.scrollHeight}px`;
      } else {
        commentInputRef.current.style.height = '84px';
      }
    }

    setCommentContent(e.target.value);
  };

  const { fetchDiaryDetail, fetchOpenSpaceDetail } = useFetchData();

  const onSubmitCommentToDiary = useCallback(async () => {
    if (isSubmittingComment) return;
    setIsSubmittingComment(true);

    const method = commentWriteStatus === 'edit' ? 'put' : 'post';
    let url = `/comments/${selectedComment?.id}/comments`;
    if (commentWriteStatus === 'edit') {
      url = `/comments/${selectedComment?.id}`;
    }

    try {
      const { data } = await axios({
        method,
        url,
        data: {
          comment: commentContent,
          taggedMemberId: selectedComment?.taggedMemberId,
        },
      });
      if (data.success) {
        if (setCommentInputView) setCommentInputView(false);

        await fetchDiaryDetail(selectedDiaryDetail.data?.date);
        dispatch(commonActions.setValue('selectedComment', null));
        setCommentWriteStatus('post');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmittingComment(false);
    }
  }, [
    commentContent,
    commentWriteStatus,
    selectedComment,
    fetchDiaryDetail,
    selectedDiaryDetail,
  ]);

  const onSubmitCommentToFeed = useCallback(async () => {
    if (isSubmittingComment) return;
    setIsSubmittingComment(true);

    const method = commentWriteStatus === 'edit' ? 'put' : 'post';
    let url = `/openspaces/${selectedOpenSpaceId}/comments`;
    if (selectedComment?.taggedMemberId) {
      url = `/comments/${selectedComment?.id}/comments`;
    }
    if (commentWriteStatus === 'edit') {
      url = `/comments/${selectedComment?.id}`;
    }

    try {
      const { data } = await axios({
        method,
        url,
        data: {
          comment: commentContent,
          taggedMemberId: selectedComment?.taggedMemberId,
        },
      });
      if (data.success) {
        fetchOpenSpaceFn();
        await fetchOpenSpaceDetail();
        // setCommentInputView(false);
        dispatch(
          commonActions.setValue('selectedComment', {
            id: null,
            content: '',
            taggedMemberId: null,
            taggedMemberNickname: null,
          }),
        );
        setCommentWriteStatus('post');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmittingComment(false);
    }
  }, [
    commentContent,
    commentWriteStatus,
    selectedComment,
    fetchOpenSpaceDetail,
  ]);

  const onClickCloseReply = useCallback(() => {
    setCommentWriteStatus('post');
    if (isDiaryComment && setCommentInputView) {
      setCommentInputView(false);
    } else {
      dispatch(commonActions.setValue('selectedComment', null));
    }
  }, []);

  // const [inputFocus, setInputFocus] = useState(false);

  return (
    <Container safeAreaBottom={safeArea.bottom}>
      {selectedComment?.taggedMemberId && (
        <CommentTarget>
          <p>{selectedComment?.taggedMemberNickname}님에게 답글 남기는 중</p>
          <button type="button" onClick={onClickCloseReply}>
            <img src={btnClose} alt="닫기 버튼" />
          </button>
        </CommentTarget>
      )}
      <div className="inner-wrap">
        <span
          className="thumb"
          style={{ backgroundImage: `url(${myProfile?.profileUrl})` }}
        />
        <div className="textarea-wrap">
          <textarea
            value={commentContent}
            onChange={onChangeComment}
            ref={commentInputRef}
            placeholder="댓글을 입력하세요"
            // onFocus={() => setInputFocus(true)}
            // onBlur={() => setInputFocus(false)}
          />
          <button
            className="btn-send"
            type="button"
            onClick={
              isDiaryComment ? onSubmitCommentToDiary : onSubmitCommentToFeed
            }
          >
            {commentWriteStatus === 'post' ? '입력' : '수정'}
          </button>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div<{ safeAreaBottom: number }>`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray3};

  .inner-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: ${({ safeAreaBottom }) => `8px 15px ${safeAreaBottom + 8}px`};

    .thumb {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 10px;
    }

    .textarea-wrap {
      flex: 1;
      position: relative;
      padding: 10px 0;
      border-radius: 25px;
      background-color: #fff;

      textarea {
        border: 0;
        width: 100%;
        height: 21px;
        vertical-align: top;
        padding: 0 50px 0 15px;
        background-color: transparent;
      }

      .btn-send {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px 15px;
        color: #007aff;
      }
    }
  }
`;

const CommentTarget = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 15px;
  border-top: 1px solid ${({ theme }) => theme.color.gray3};

  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.gray1};
  }
  button {
    img {
      width: 24px;
    }
  }
`;

export default WriteCommentInput;

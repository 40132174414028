import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { iconCautionCircle } from '../../../assets/images/icon';
import StackHeader from '../../../layouts/StackHeader';
import ModalConfirm from '../../common/ModalConfirm';
import Button from '../../parts/Button';
import Checkbox from '../../parts/Checkbox';
import { clearStackScreen } from '../../../store/common';

function StackWithdrawal(): JSX.Element {
  const [modalConfirmWithdrawal, setModalConfirmWithdrawal] = useState(false);
  const [checkConfirm, setCheckConfirm] = useState(false);

  const onToggleCheckConfirm = useCallback(() => {
    setCheckConfirm((v) => !v);
  }, []);

  const leftUser = async () => {
    try {
      const { data } = await axios.delete(`/me/left`);
      if (data.success) {
        window.flutter_inappwebview.callHandler('logout');

        setModalConfirmWithdrawal(false);
        localStorage.removeItem('accessToken');
        clearStackScreen();
        window.location.href = '/login';
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <StackHeader title="회원탈퇴" />
      <Content>
        <div className="notice">
          <div className="inner">
            <div className="icon-wrap">
              <img src={iconCautionCircle} alt="주의 아이콘" />
            </div>
            <p className="title">
              탈퇴하기 전,
              <br />꼭 확인해 주세요.
            </p>
            <p className="sub-text">
              <span>1.</span>
              탈퇴 시, 회원님이 참여했던 질문 다이어리는
              <br /> 모두 종료됩니다. 함께 진행하는 분들의
              <br /> 동의 후 탈퇴를 진행해 주세요.
            </p>
            <p className="sub-text">
              <span>2.</span>
              탈퇴 시, 그동안 회원님이 질문 다이어리에
              <br /> 작성했던 답변은 삭제 되지 않습니다.
            </p>
          </div>
        </div>
        <div className="btns-wrap">
          <div className="inner-wrap">
            <div className="check-wrap">
              <Checkbox
                checked={checkConfirm}
                onChange={onToggleCheckConfirm}
                label="네, 확인했습니다."
              />
            </div>
            <Button
              fullWidth
              color={checkConfirm ? 'main' : 'gray'}
              onClick={() => setModalConfirmWithdrawal(true)}
            >
              탈퇴하기
            </Button>
          </div>
        </div>
      </Content>

      <ModalConfirm
        visible={modalConfirmWithdrawal}
        title="확인"
        description="정말 탈퇴 하시겠어요?"
        btnCancel="취소"
        btnCancelClick={() => setModalConfirmWithdrawal(false)}
        btnConfirm="탈퇴"
        btnConfirmClick={leftUser}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .notice {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrap {
      text-align: center;

      img {
        width: 60px;
        margin-bottom: 30px;
      }
    }

    .title {
      font-size: 2.8rem;
      text-align: center;
      margin-bottom: 80px;
    }

    .sub-text {
      font-size: 1.2rem;
      position: relative;
      margin: 15px 0;

      span {
        font-size: 1.2rem;
        position: absolute;
        top: 0;
        left: -14px;
      }
    }
  }

  .btns-wrap {
    margin-bottom: 50px;

    .check-wrap {
      text-align: center;
      margin-bottom: 30px;
    }
  }
`;

export default StackWithdrawal;

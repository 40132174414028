import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { iconScrap } from '../../../assets/images/icon';

function ScrapListLoading(): JSX.Element {
  return (
    <div>
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
    </div>
  );
}

function LoadingItem(): JSX.Element {
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => {
      setLoadingAnimation((v) => !v);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container
      animation={loadingAnimation}
      role="button"
      className="scrap-item"
    >
      <div className="padding-wrap">
        <h3 className="name"> </h3>
        <div className="info" />
        <p className="question" />
        <div className="cover-image" />
        <BtnScrap type="button" active={false}>
          <span />
        </BtnScrap>
      </div>
    </Container>
  );
}

const Container = styled.div<{ animation: boolean }>`
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 120px;
  }

  .padding-wrap {
    min-height: 140px;
    padding: 15px;

    .name {
      font-size: 1.2rem;
      font-family: 'NanumSquare';
      font-weight: 700;
      margin-bottom: 3px;
      border-radius: 4px;
      height: 18px;
      width: 50px;
      background-color: #ccc;
      transition: all 0.5s ease;
      opacity: ${({ animation }) => (animation ? '1' : '0.5')};
    }

    .info {
      width: 100px;
      height: 17px;
      background-color: #ddd;
      margin-bottom: 10px;
      border-radius: 4px;
      transition: all 0.5s ease;
      opacity: ${({ animation }) => (animation ? '1' : '0.5')};
    }

    .question {
      width: 200px;
      height: 21px;
      background-color: #eee;
      border-radius: 4px;
      transition: all 0.5s ease;
      opacity: ${({ animation }) => (animation ? '1' : '0.5')};
    }

    .cover-image {
      width: 100px;
      height: 70px;
      border-radius: 6px;
      background-color: #eee;
      position: absolute;
      top: 55px;
      right: 15px;
      overflow: hidden;
      transition: all 0.5s ease;
      opacity: ${({ animation }) => (animation ? '1' : '0.5')};
    }
  }

  .bottom {
    padding: 10px 15px;
    border-top: 1px solid ${({ theme }) => theme.color.gray3};
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        margin-right: 6px;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }
`;

const BtnScrap = styled.button<{ active: boolean }>`
  position: absolute;
  top: 0;
  right: 15px;

  span {
    display: inline-block;
    width: 28px;
    height: 28px;
    background-image: url(${iconScrap});
    background-repeat: no-repeat;
    background-position: ${({ active }) => (active ? '0 -28px' : '0 0')};
    background-size: 28px;
  }
`;

export default ScrapListLoading;

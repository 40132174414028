import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { btnBack } from '../../assets/images';
import { actions } from '../../store/common';

interface IProps {
  title?: string;
  onClickBtnLeft?: () => void;
  btnRight?: JSX.Element;
  hideBtnLeft?: boolean;
  selectedTabMenu?: string;
  tabMenuLeft?: string;
  tabMenuRight?: string;
  tabMenuClick?: (tabName: string) => void;
}

function StackHeader({
  title,
  onClickBtnLeft,
  btnRight,
  hideBtnLeft = false,
  selectedTabMenu,
  tabMenuLeft,
  tabMenuRight,
  tabMenuClick,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const onClickBackBtn = () => {
    if (onClickBtnLeft) onClickBtnLeft();
    dispatch(actions.removeStackScreen());
  };

  const onTabMenuClick = (tabName: string) => {
    if (tabMenuClick) {
      tabMenuClick(tabName);
    }
  };

  return (
    <Container>
      <HeaderBar>
        {!hideBtnLeft && (
          <button className="btn-left" type="button" onClick={onClickBackBtn}>
            <img src={btnBack} alt="뒤로가기 버튼" />
          </button>
        )}
        {title && <h1>{title}</h1>}
        {btnRight && <div className="btn-right">{btnRight}</div>}
      </HeaderBar>
      {tabMenuLeft && tabMenuRight && (
        <TabMenu>
          <button
            type="button"
            className={selectedTabMenu === tabMenuLeft ? 'active' : ''}
            onClick={() => onTabMenuClick(tabMenuLeft)}
          >
            {tabMenuLeft}
          </button>
          <button
            type="button"
            className={selectedTabMenu === tabMenuRight ? 'active' : ''}
            onClick={() => onTabMenuClick(tabMenuRight)}
          >
            {tabMenuRight}
          </button>
        </TabMenu>
      )}
    </Container>
  );
}

const Container = styled.div``;

const HeaderBar = styled.div`
  position: relative;
  height: 50px;

  h1 {
    font-family: 'NanumSquare';
  }

  .btn-left {
    position: absolute;
    top: 0;
    left: 0;
    padding: 11px 10px;

    img {
      width: 28px;
    }
  }

  .btn-right {
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    padding: 11px 14px 11px 10px;
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
    }

    img {
      width: 28px;
    }
  }

  h1 {
    text-align: center;
    line-height: 5rem;
  }

  .btn-save {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.color.main1};
    font-weight: 300;
  }
`;

const TabMenu = styled.div`
  display: flex;

  button {
    height: 40px;
    flex: 1;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid transparent;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.gray2};

    &.active {
      color: #222;
      border-bottom: 3px solid ${({ theme }) => theme.color.main1};
    }
  }
`;

export default StackHeader;

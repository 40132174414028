import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

interface IProps {
  controls?: boolean;
  src: string;
  isVideoPlaying: boolean;
}

function VideoPlayer({
  controls = true,
  src,
  isVideoPlaying,
}: IProps): JSX.Element {
  return (
    <ReactPlayer
      url={src}
      controls={controls}
      width="100%"
      height="auto"
      playing={isVideoPlaying}
      playsinline
      muted
    />
  );
}

export default VideoPlayer;

import React from 'react';
import styled from 'styled-components';
import { logoGray } from '../../../assets/images';

interface IProps {
  ment: string;
}

function NoList({ ment }: IProps): JSX.Element {
  return (
    <Container>
      <img src={logoGray} alt="로고" />
      <p>{ment}</p>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 60px;
    margin-bottom: 15px;
  }
  p {
    text-align: center;
    color: ${({ theme }) => theme.color.gray1};
  }
`;

export default NoList;

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { actions as commonActions } from '../../../store/common';
import { RootState } from '../../../store';
import LoadingContent from '../../common/LoadingContent';
import NoList from '../../common/NoList';
import { loading } from '../../../assets/images';

function StackNotice(): JSX.Element {
  const { addStackScreen } = useStack();
  const dispatch = useDispatch();

  const onClickNotice = useCallback((noticeId: number) => {
    dispatch(commonActions.setValue('selectedNoticeId', noticeId));
    addStackScreen('StackNoticeDetail');
  }, []);

  const { notices } = useSelector((state: RootState) => state.common);
  const [noticePage, setNoticePage] = useState(1);
  const [noticeHasMore, setNoticeHasMore] = useState(true);

  const fetchNoticeList = async () => {
    if (!notices.data) {
      dispatch(
        commonActions.setValue('notices', {
          loading: true,
          data: null,
          error: null,
        }),
      );
    }

    try {
      const { data } = await axios.get(
        // `https://dev-api.el-bigs.com/api/v1/cms/notices?page=${noticePage}&length=10&status=1&site=amor`,
        `https://api-mboard.el-bigs.com/api/v1/cms/notices?page=${noticePage}&length=10&status=1&site=amor`,
      );
      if (data.list === 0) {
        return setNoticeHasMore(false);
      }

      setNoticePage((v) => v + 1);
      dispatch(
        commonActions.setValue('notices', {
          loading: false,
          data: {
            list: data.list,
            page: data.page,
            totalCount: data.totalCount,
          },
          error: null,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchNoticeList();
  }, []);

  const dateFormat = (time: string) => {
    const date = dayjs(time);
    const month = Number(date.format('MM'));
    const day = Number(date.format('DD'));
    const isAm = date.format('a') === 'am' ? '오전' : '오후';

    return date.format(`YYYY년${month}월${day}일 ${isAm} h:mm`);
  };

  return (
    <Container>
      <StackHeader title="공지사항" />
      <Content>
        {notices.loading && <LoadingContent />}
        {!notices.loading && notices.data?.list.length === 0 && (
          <NoList ment="공지사항이 없어요" />
        )}
        {!notices.loading && notices.data && notices.data.list.length > 0 && (
          <div className="list-content">
            <InfiniteScroll
              dataLength={notices.data.list.length}
              next={fetchNoticeList}
              hasMore={noticeHasMore}
              loader={
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={loading}
                    alt="로딩 이미지"
                    style={{ width: '80px' }}
                  />
                </div>
              }
              scrollableTarget="ScrapScrollWrap"
            >
              {notices.data?.list.map((notice) => (
                <div
                  role="button"
                  key={notice.noticeId}
                  className="list-item"
                  onClick={() => onClickNotice(notice.noticeId)}
                >
                  <p className="title">{notice.title}</p>
                  <p className="date">{dateFormat(notice.createdAt)}</p>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;

  .list-item {
    padding: 15px;

    .title {
      font-size: 1.6rem;
      font-weight: 800;
      font-family: 'NanumSquare';
      margin-bottom: 6px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .date {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
      font-family: 'NanumSquare';
    }
  }
`;

export default StackNotice;

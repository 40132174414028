import axios from 'axios';
import { call, delay, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { IDiary } from './types';
import { actions, asyncSetDiaries, Types } from './actions';

const getDiaries = async () => {
  const { data } = await axios.get<{ success: boolean; diaries: IDiary[] }>(
    '/diaries/main',
  );
  // console.log('get diary : ', data);
  return data.diaries;
};

function* setDiariesSaga(action: any) {
  const { success, failure } = asyncSetDiaries;
  try {
    const diaries: IDiary[] = yield call(getDiaries);
    if (action.payload === 'create') {
      const slideIndex = diaries.filter(
        (diary) => diary.isFavorite === 'Y',
      ).length;
      console.log('create slidelindex : ', slideIndex);
      yield put(actions.setValue('slideToIndex', slideIndex));
    }

    if (action.payload === 'init')
      yield put(actions.setValue('slideToIndex', 0));

    yield put(success(diaries));
  } catch (e: any) {
    yield put(failure(e));
  }
}

export function* diariesSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(Types.SET_DIARIES, setDiariesSaga);
}

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import { btnWriteFloating, loading } from '../../assets/images';
import { iconMyQuestion } from '../../assets/images/icon';
import useStack from '../../hooks/useStack';
import Header from '../../layouts/Header';
import { actions as commonActions } from '../../store/common';
import { RootState } from '../../store';
import { actions as openSpaceActions, IOpenSpace } from '../../store/openSpace';
import NoList from '../../components/common/NoList';
import FeedList from '../../components/openSpace/FeedList';
import useNavigator from '../../hooks/useNavigator';
import OpenSpaceListLoading from '../../components/openSpace/OpenSpaceListLoading';

function OpenSpace({ location }: RouteComponentProps): JSX.Element {
  const [showNavigator, hideNavigator] = useNavigator();

  const { addStackScreen } = useStack();
  const { openSpaceBest, openSpaceNew } = useSelector(
    (state: RootState) => ({
      openSpaceBest: state.openSpace.openSpaceBest,
      openSpaceNew: state.openSpace.openSpaceNew,
    }),
    shallowEqual,
  );

  const [isLoadingEnd, setIsLoadingEnd] = useState(false);
  const [openSpaces, setOpenSpaces] = useState<IOpenSpace[] | null>(null);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);
  const { myProfile, stackScreens, isWatchedAd } = useSelector(
    (state: RootState) => ({
      myProfile: state.common.myProfile,
      stackScreens: state.common.stackScreens,
      isWatchedAd: state.common.isWatchedAd,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [selectedTabMenu, setSelectedTabMenu] = useState('Best');
  const [searchTags, setSearchTags] = useState<string[] | []>([]);
  const query = queryString.parse(location.search);

  useEffect(() => {
    dispatch(commonActions.setValue('currentPage', location.pathname));
    // setOpenSpaces(openSpaceBest?.data?.list || []);
    // setNextPageUrl(openSpaceBest?.data?.nextPageUrl || null);
    fetchOpenSpaces(null);
  }, []);

  useEffect(() => {
    // 푸시로 들어온 경우
    if (query.openSpaceId) {
      dispatch(
        commonActions.setValue(
          'selectedOpenSpaceId',
          Number(query.openSpaceId),
        ),
      );
      addStackScreen('StackQuestionFeedDetail');
    }
    if (query.commentId) {
      dispatch(
        commonActions.setValue('scrollToComment', {
          commentId: Number(query.commentId),
          commentOffset: null,
        }),
      );
    }

    dispatch(
      commonActions.setValue('fetchOpenSpaceFn', callBackFetchOpenSpaces),
    );
  }, []);

  useEffect(() => {
    // console.log('searchTag', searchTags)
    const el = document?.getElementById('ScrollWrap');
    if (el) el.scrollTop = 0;
  }, [searchTags, selectedTabMenu]);

  useEffect(() => {
    if (stackScreens.length === 0) {
      // fetchOpenSpaces(null);
    }
  }, [stackScreens]);

  useEffect(() => {
    // fetchOpenSpaces(null);

    // console.log(selectedTabMenu)
    if (selectedTabMenu === 'Best') {
      setOpenSpaces(openSpaceBest?.data?.list || []);
      setNextPageUrl(openSpaceBest?.data?.nextPageUrl || null);
      setIsLoadingEnd(true);
    } else {
      setOpenSpaces(openSpaceNew?.data?.list || []);
      setNextPageUrl(openSpaceNew?.data?.nextPageUrl || null);
    }
  }, [selectedTabMenu, openSpaceNew, openSpaceBest]);

  const onTabMenuClick = useCallback((tabName: string) => {
    setSelectedTabMenu(tabName);
  }, []);

  const alertMessageHashtag = () => {
    dispatch(
      commonActions.setValue(
        'alertMessage',
        '검색어를 해시태그와 함께 입력해주세요.',
      ),
    );
  };

  const fetchOpenSpaceBest = (
    tags: string[] | null,
    nextUrl: string | null,
  ) => {
    // if (!openSpaceBest.data) {
    //   dispatch(
    //     openSpaceActions.setValue('openSpaceBest', {
    //       loading: true,
    //       data: null,
    //       error: null,
    //     }),
    //   );
    // }

    let url = 'openspaces';
    if (nextUrl) {
      url = nextUrl;
    }
    axios
      .get(url, {
        params: {
          tags: !tags ? searchTags : tags,
          best: 1,
          length: 30,
        },
      })
      .then((data) => data.data)
      .then((data) => {
        // console.log(data.list);
        dispatch(
          openSpaceActions.setOpenSpaceBestSuccess(
            data.list,
            data.nextPageUrl,
            !!nextUrl,
          ),
        );
      })
      .catch((e) => {
        console.log(e);
        // dispatch(scrapActions.setScrapMyDiaryError(e));
      });
  };

  const fetchOpenSpaceNew = (tags: string[] | null, nextUrl: string | null) => {
    let url = 'openspaces';
    if (nextUrl) {
      url = nextUrl;
    }

    axios
      .get(url, {
        params: {
          tags: !tags ? searchTags : tags,
          best: 0,
          length: 30,
        },
      })
      .then((data) => data.data)
      .then((data) => {
        // console.log(data);
        dispatch(
          openSpaceActions.setOpenSpaceNewSuccess(
            data.list,
            data.nextPageUrl,
            !!nextUrl,
          ),
        );
      })
      .catch((e) => {
        // dispatch(scrapActions.setScrapMyDiaryError(e));
      });
  };

  const fetchOpenSpaces = (tags: string[] | null) => {
    if (tags == null && searchTags == null) {
      tags = [];
      setSearchTags([]);
    } else if (tags == null && searchTags) {
      tags = searchTags;
    }

    fetchOpenSpaceBest(tags, null);
    fetchOpenSpaceNew(tags, null);
  };

  const callBackFetchOpenSpaces = () => {
    // if (targetTab) setSelectedTabMenu(targetTab);
    fetchOpenSpaceBest(null, null);
    fetchOpenSpaceNew(null, null);
  };

  const fetchOpenSpaceFn = (targetTab?: string | null) => {
    // console.log("targetTab", targetTab)

    if (!targetTab) targetTab = selectedTabMenu;
    if (targetTab === 'Best') {
      fetchOpenSpaceBest(null, nextPageUrl || null);
    } else {
      fetchOpenSpaceNew(null, nextPageUrl || null);
    }
  };

  const callBackHashTags = (tags: string[] | []) => {
    setSearchTags(tags);
    fetchOpenSpaces(tags);
  };

  const [prevScroll, setPrevScroll] = useState(0);
  const [scrollStatus, setScrollStatus] = useState<'up' | 'down'>('up');

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const currentScroll = e.currentTarget.scrollTop;

    if (prevScroll < 0) {
      setPrevScroll(currentScroll);
      return;
    }

    if (prevScroll < currentScroll && scrollStatus === 'up') {
      setScrollStatus('down');
    }

    if (prevScroll > currentScroll && scrollStatus === 'down') {
      setScrollStatus('up');
    }

    setPrevScroll(currentScroll);
  };

  useEffect(() => {
    if (scrollStatus === 'up') showNavigator();
    if (scrollStatus === 'down') hideNavigator();
  }, [scrollStatus]);

  const [isShowSearchTags, setIsShowSearchTags] = useState(false);

  const onClickWriteQuestion = () => {
    dispatch(commonActions.setValue('selectedOpenSpaceId', null));
    dispatch(openSpaceActions.setValue('openSpaceDetail', null));
    addStackScreen('StackCreateQuestion');

    // // 앱함수 호출
    // if (
    //   process.env.REACT_APP_ENV === 'dev' ||
    //   isWatchedAd ||
    //   !window.flutter_inappwebview
    // ) {
    //   addStackScreen('StackCreateQuestion');
    // } else {
    //   window.flutter_inappwebview.callHandler('showRewardAd');
    // }
  };

  return (
    <Container>
      <Header
        title="Open Space"
        btnRight={
          <BtnMyQuestions
            onClick={() => addStackScreen('StackMyQuestionManagement')}
          >
            <span />
          </BtnMyQuestions>
        }
        // searchText={value}
        // onChangeSearchText={onChangeText}
        selectedTabMenu={selectedTabMenu}
        tabMenuLeft="Best"
        tabMenuRight="New"
        tabMenuClick={onTabMenuClick}
        // onClickTag={onClickTag}
        callBackHashTags={callBackHashTags}
        hasTagList
        setIsShowSearchTags={setIsShowSearchTags}
      />
      <Content
        isShowSearchTags={isShowSearchTags}
        id="ScrollWrap"
        onScroll={scrollHandler}
      >
        {openSpaceBest.loading && (
          <div className="feed-list">
            <OpenSpaceListLoading />
          </div>
        )}
        {!openSpaceBest.loading &&
          openSpaceBest.data &&
          isLoadingEnd &&
          openSpaces &&
          openSpaces.length === 0 && (
            <NoList ment="아직 생성된 게시글이 없어요" />
          )}
        {!openSpaceBest.loading &&
          openSpaceBest.data &&
          isLoadingEnd &&
          openSpaces &&
          openSpaces.length > 0 && (
            <div className="feed-list">
              <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-3035358409986725"
                data-ad-slot="1952094582"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />

              <FeedList
                list={openSpaces}
                nextPageUrl={nextPageUrl}
                fetchOpenSpaceFnForPaging={fetchOpenSpaceFn}
                myNickname={myProfile?.nickname || ''}
                isBest={selectedTabMenu === 'Best'}
              />
            </div>
          )}
        <BtnWriteFloating onClick={onClickWriteQuestion}>
          <img src={btnWriteFloating} alt="질문 작성 버튼" />
        </BtnWriteFloating>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div<{ isShowSearchTags: boolean }>`
  flex: 1;
  box-sizing: border-box;
  padding-top: ${({ isShowSearchTags }) =>
    isShowSearchTags ? '182px' : '128px'};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.gray3};

  .tag-list {
    background-color: #fff;
    overflow-x: auto;

    ul {
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 15px;

      .tag {
        margin-right: 8px;
        display: flex;
        align-items: center;
        padding: 4px 12px;
        border-radius: 25px;
        background-color: ${({ theme }) => theme.color.main2};

        .name {
          font-size: 1.1rem;
          color: ${({ theme }) => theme.color.main1};
        }

        .btn-delete {
          line-height: 0;
          margin-left: 10px;
          img {
            width: 12px;
          }
        }
      }
    }
  }

  .feed-list {
    .feed-item {
      margin-top: 15px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

      &:last-child {
        margin-bottom: 120px;
      }
    }
  }
`;

const BtnMyQuestions = styled.button`
  margin-right: 10px;

  span {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(${iconMyQuestion});
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 24px;
  }
`;

const AdFeed = styled.div`
  background-color: #fff;
  margin-top: 15px;

  img {
    width: 100%;
  }

  .bottom {
    padding: 15px;

    button {
      color: #0075ff;
    }
  }

  video {
    vertical-align: top;
  }
`;

const BtnWriteFloating = styled.button`
  position: fixed;
  right: 15px;
  bottom: 100px;
  box-shadow: 0 0 10px rgba(185, 0, 0, 0.3);
  border-radius: 50%;

  img {
    width: 48px;
  }
`;

const CommentBtnMenu = styled.div`
  button {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 1.6rem;
  }
`;

export default OpenSpace;

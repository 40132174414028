import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import useFetchData from '../../../hooks/useFetchData';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import ModalConfirm from '../../common/ModalConfirm';
import Button from '../../parts/Button';
import { localToUtc } from '../../../utils/AmorDateUtil';

function StackSetQuestionTime(): JSX.Element {
  const { removeStackScreen } = useStack();
  const [modalConfirmView, setModalConfirmView] = useState(false);

  const showModalConfirm = useCallback(() => {
    setModalConfirmView(true);
  }, []);

  const hideModalConfirm = useCallback(() => {
    setModalConfirmView(false);
  }, []);

  const { selectedDiaryId } = useSelector((state: RootState) => state.common);
  const [time, setTime] = useState({
    flag: '오후',
    hours: 13,
    minutes: '00',
  });
  const [hoursForUi, setHoursForUi] = useState(1);

  const onChangeTimeFlag = useCallback(
    (e) => {
      const selectFlag = e.target.value;

      let hours = 0;
      if (selectFlag === '오전' && time.hours === 12) hours = 0;
      if (selectFlag === '오전' && time.hours !== 12) hours = time.hours - 12;
      if (selectFlag === '오후' && time.hours === 24) hours = 12;
      if (selectFlag === '오후' && time.hours !== 12 && time.hours !== 24)
        hours = time.hours + 12;

      setTime({
        ...time,
        flag: e.target.value,
        hours,
      });
    },
    [time],
  );
  const onChangeTimeHours = useCallback(
    (e) => {
      const selectedHours = Number(e.target.value);
      setHoursForUi(selectedHours);

      let hours = 0;
      if (time.flag === '오전' && selectedHours === 12) hours = 0;
      if (time.flag === '오전' && selectedHours !== 12) hours = selectedHours;
      if (time.flag === '오후' && selectedHours === 12) hours = 12;
      if (time.flag === '오후' && selectedHours !== 12)
        hours = selectedHours + 12;

      setTime({
        ...time,
        hours,
      });
    },
    [time],
  );
  const onChangeTimeMinutes = useCallback(
    (e) => {
      setTime({
        ...time,
        minutes: e.target.value,
      });
    },
    [time],
  );

  const { fetchMainDiaries } = useFetchData();

  const completeTimeSetting = useCallback(async () => {

    const utc = localToUtc(new Date(2000, 1, 1, time.hours, Number(time.minutes || 0)));

    const convertHours = utc.getHours() < 10 ? `0${utc.getHours()}` : `${utc.getHours()}`;
    const convertMins = utc.getMinutes() < 10 ? `0${utc.getMinutes()}` : `${utc.getMinutes()}`;

    try {
      const { data } = await axios.post(`/diaries/${selectedDiaryId}/time`, {
        receiveTime: `${convertHours}:${convertMins}`,
      });
      if (data.success) {
        fetchMainDiaries();
        removeStackScreen();
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedDiaryId, removeStackScreen]);

  return (
    <Container>
      <ModalConfirm
        visible={modalConfirmView}
        title="확인"
        description="질문 설정 시간은 7:00 입니다."
        btnCancel="취소"
        btnCancelClick={hideModalConfirm}
        btnConfirm="확인"
        btnConfirmClick={completeTimeSetting}
      />
      <StackHeader />
      <Content>
        <div className="inner-wrap">
          <section className="top">
            <p className="ment">
              질문을 언제
              <br /> 보내드릴까요?
            </p>
            <div className="time-selector">
              <select
                className="unit"
                value={time.flag}
                onChange={onChangeTimeFlag}
              >
                <option value="오전">오전</option>
                <option value="오후">오후</option>
              </select>
              <select
                className="time"
                dir="rtl"
                value={hoursForUi}
                onChange={onChangeTimeHours}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <span className="divider">:</span>
              <select
                className="time"
                dir="rtl"
                value={time.minutes}
                onChange={onChangeTimeMinutes}
              >
                <option value="00">00</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </div>
          </section>
        </div>
        <BtnBottom>
          <p className="caution">
            * 한 번 설정하면 바꿀 수 없으니
            <br /> 고민 후 적절한 시간으로 설정해주세요!
          </p>
          <Button fullWidth onClick={completeTimeSetting}>
            완료
          </Button>
        </BtnBottom>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  .top {
    text-align: center;
    margin: 70px 0 100px;

    .ment {
      font-size: 2.8rem;
      margin-bottom: 25px;
    }

    .time-selector {
      display: inline-flex;
      align-items: center;

      select {
        font-size: 2.3rem;
        font-family: 'NanumSquare';

        &.unit {
          margin-right: 20px;
        }

        &.time {
          width: 32px;
          font-size: 2.5rem;
        }

        option {
          font-size: 1.4rem;
        }
      }

      .divider {
        margin: 0 6px;
      }
    }
  }
`;
const BtnBottom = styled.div`
  position: fixed;
  bottom: 40px;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 0 15px;
  text-align: center;

  .caution {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.main1};
    margin-bottom: 22px;
  }
`;

export default StackSetQuestionTime;

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { sampleImage1, sampleImage4 } from '../../../assets/images';
import { iconCameraWhiteCircle } from '../../../assets/images/icon';
import useFetchData from '../../../hooks/useFetchData';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import {
  removeStackScreen,
  actions as commonActions,
} from '../../../store/common';
import { actions as homeActions, IDiaryTempData } from '../../../store/home';
import { makeFormData } from '../../../utils/makeFormData';
import LoadingLayer from '../../common/LoadingLayer';
import Input from '../../parts/Input';

interface ICover {
  file?: File | string;
  isChanged: boolean;
  type: string;
  url: string;
}
interface FormDataTypes {
  cover: ICover;
  name: string;
  groupId: number;
  defaultCoverImage: string;
}

function StackCreateDiary(): JSX.Element {
  const { replaceStackScreen } = useStack();
  const [formData, setFormData] = useState<FormDataTypes>({
    cover: {
      file: '',
      isChanged: false,
      type: 'image',
      url: '',
    },
    name: '',
    groupId: 1,
    defaultCoverImage: '',
  });

  const onChangeCoverImage = useCallback(
    (e) => {
      const file = e.target.files[0];
      let url = null;
      if (file && file.type.match(/^image\/(png|jpeg)$/)) {
        url = URL.createObjectURL(file);
        setFormData({
          ...formData,
          cover: {
            file,
            isChanged: true,
            type: 'image',
            url,
          },
        });
      } else {
        // setErrorMessage('이미지 파일만 등록할 수 있습니다.');
        // return;
      }
    },
    [formData],
  );

  const onChangeRadio = useCallback(
    (e) => {
      setFormData((data) => ({
        ...data,
        groupId: Number(e.target.value),
      }));

      setRandomDefaultCover(Number(e.target.value));
    },
    [formData],
  );

  const [isErrorInput, setIsErrorInput] = useState(false);

  const onChangeInput = useCallback(
    (e) => {
      setIsErrorInput(false);
      setFormData({
        ...formData,
        name: e.target.value,
      });
    },
    [formData],
  );

  const dispatch = useDispatch();
  const { fetchMainDiaries } = useFetchData();
  const [isUploading, setIsUploading] = useState(false);

  const onClickSaveDiary = useCallback(async () => {
    if (!formData.name) return setIsErrorInput(true);

    setIsUploading(true);

    const newFormData = makeFormData(formData);

    try {
      const { data } = await axios.post('/diaries', newFormData);
      if (data.success) {
        setIsUploading(false);
        dispatch(commonActions.setValue('isWatchedAd', false));
        fetchMainDiaries('create');
        dispatch(commonActions.setValue('selectedDiaryId', data.diary.id));
        if (formData.groupId === 1)
          return replaceStackScreen('StackSetQuestionTime');
        dispatch(homeActions.setValue('diaryTempData', data.diary));
        replaceStackScreen('StackSendInvitation');
      }
    } catch (e) {
      console.log('save diary error : ', e);
    }
  }, [formData, replaceStackScreen, makeFormData]);

  const [groups, setGroups] = useState([]);

  const getDiaryGroup = async () => {
    try {
      const { data } = await axios.get('/diaries/groups');
      if (data.success) {
        setGroups(data.groups);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setRandomDefaultCover = (groupId: number) => {
    let coverNum: string | number = Math.floor(Math.random() * 10 + 1);
    coverNum = coverNum === 10 ? coverNum : `0${coverNum}`;

    let coverName = '';
    if (groupId === 1) coverName = 'me';
    if (groupId === 2) coverName = 'friend';
    if (groupId === 3) coverName = 'family';
    if (groupId === 4) coverName = 'couple';

    setFormData((data) => ({
      ...data,
      defaultCoverImage: `/images/default-cover/${coverName}_${coverNum}.jpg`,
    }));
  };

  useEffect(() => {
    setRandomDefaultCover(1);
    getDiaryGroup();
  }, []);

  return (
    <Container>
      <StackHeader
        title="다이어리 생성"
        btnRight={
          <BtnSave
            type="button"
            active={!!formData.name}
            onClick={onClickSaveDiary}
          >
            SAVE
          </BtnSave>
        }
      />
      <Content>
        <div className="inner-wrap">
          <ImageUpload
            preview={
              formData.cover.url
                ? formData.cover.url
                : formData.defaultCoverImage
            }
          >
            <input type="file" accept="image/*" onChange={onChangeCoverImage} />
            <img
              className="icon-camera"
              src={iconCameraWhiteCircle}
              alt="카메라 아이콘"
            />
          </ImageUpload>
          <Section>
            <h3 className="title">누구와 가까워지고 싶나요?</h3>
            <div className="radios">
              {groups.map(({ id, name }) => (
                <RadioBtn key={id} active={formData.groupId === id}>
                  <input
                    type="radio"
                    name="group"
                    value={id}
                    onChange={onChangeRadio}
                  />
                  <span>{name}</span>
                </RadioBtn>
              ))}
            </div>
          </Section>
          <Section>
            <h3 className="title">어울리는 이름을 지어주세요</h3>
            <div className="input-wrap">
              <Input
                type="text"
                value={formData.name}
                onChange={onChangeInput}
                placeholder="다이어리 이름은 1~10자 문자, 숫자 입력"
              />
              {isErrorInput && (
                <p className="error-message">다이어리 이름을 입력해주세요.</p>
              )}
            </div>
          </Section>
        </div>
      </Content>
      {isUploading && <LoadingLayer />}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;
`;

const BtnSave = styled.button<{ active: boolean }>`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.color.main1 : theme.color.gray1};
`;

const ImageUpload = styled.label<{ preview: string }>`
  display: block;
  width: 194px;
  height: 284px;
  margin: 0 auto;
  border-radius: 16px;
  background-image: url(${({ preview }) => preview || ''});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;

  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .icon-camera {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 36px;
  }
`;

const Section = styled.div`
  margin-bottom: 44px;

  .title {
    font-weight: 700;
    margin-bottom: 20px;
  }

  .radios {
    height: 54px;
    display: flex;
    justify-content: space-evenly;
  }

  .input-wrap {
    .error-message {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.main1};
      margin-top: 4px;
    }
  }
`;

const RadioBtn = styled.label<{ active: boolean }>`
  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  span {
    display: inline-block;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    text-align: center;
    line-height: 54px;
    border: 1px solid ${({ theme }) => theme.color.gray2};

    ${({ active }) =>
      active &&
      css`
        background-color: ${({ theme }) => theme.color.main1};
        color: #fff;
        border-color: ${({ theme }) => theme.color.main1};
      `}
  }
`;

export default StackCreateDiary;

import React from 'react';
import styled from 'styled-components';
import Comment from '../Comment';
import { sampleImage1 } from '../../../assets/images';
import { IComment } from '../../../store/home';

interface IProps {
  listType: 'diary' | 'openSpace';
  isOpen?: boolean;
  isAnswered?: boolean;
  commentList: IComment[];
  setCommentInputView: (visible: boolean) => void;
  setBottomToastModalView: (visible: boolean) => void;
  currentScroll?: number;
}

function CommentList({
  listType,
  isOpen,
  isAnswered,
  commentList,
  setCommentInputView,
  setBottomToastModalView,
  currentScroll,
}: IProps): JSX.Element {
  return (
    <Container>
      {commentList.map((comment, commentIndex) => (
        <CommentWrap key={comment.id}>
          <Comment
            listType={listType}
            commentType="answer"
            commentData={comment}
            commentIndex={commentIndex}
            currentScroll={currentScroll}
            isOpen={isOpen}
            isAnswered={isAnswered}
            setCommentInputView={setCommentInputView}
            setBottomToastModalView={setBottomToastModalView}
            attachedAudio={
              comment.attachment.type === 'audio' ? comment.attachment.url : ''
            }
            attachedVideo={
              comment.attachment.type === 'video' ? comment.attachment.url : ''
            }
            attachedImage={
              comment.attachment.type === 'image' ? comment.attachment.url : ''
            }
          />
          {comment.replies.length > 0 && (
            <ul className="comment-reply">
              {comment.replies.map((reply, replyIndex) => (
                <li key={reply.id}>
                  <Comment
                    listType={listType}
                    commentType="comment"
                    commentData={reply}
                    commentIndex={commentIndex}
                    replyIndex={replyIndex}
                    parentCommentId={comment.id}
                    currentScroll={currentScroll}
                    isCommentReply
                    isOpen={isOpen}
                    isAnswered={isAnswered}
                    // commentReplyTarget="나야나"
                    setCommentInputView={setCommentInputView}
                    setBottomToastModalView={setBottomToastModalView}
                  />
                </li>
              ))}
            </ul>
          )}
        </CommentWrap>
      ))}
    </Container>
  );
}

const Container = styled.div``;

const CommentWrap = styled.div`
  .comment-reply {
    padding-left: 30px;
  }
`;

export default CommentList;

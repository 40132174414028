import React from 'react';
import styled from 'styled-components';
import { loading } from '../../../assets/images';

function LoadingContent(): JSX.Element {
  return (
    <Container>
      <img src={loading} alt="로딩 이미지" />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80px;
  }
`;

export default LoadingContent;

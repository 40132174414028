import React, { useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { isMobile, isIOS } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { actions as commonActions } from '../../store/common';
import {
  logo,
  btnLoginApple,
  btnLoginKakao,
  btnLoginFacebook,
} from '../../assets/images';
import useNavigator from '../../hooks/useNavigator';

import { isAmorApp } from '../../utils/webUtil';

function Login({ location }: RouteComponentProps): JSX.Element {

  const [hideNavigator] = useNavigator();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.setValue('currentPage', location.pathname));
    hideNavigator();
  }, []);

  const onClickSnsLogin = (snsName: string) => {
    if (isMobile) {
      try {
        window?.flutter_inappwebview?.callHandler('loginWithSns', snsName);
      } catch (e: any) {
        // TODO
      }
    } else {
      window.location.href = `${process.env.REACT_APP_API_HOST}/auth/login/kakao`;
    }
  };

  // 자동로그인 : 애드센스 검수완료 이후 삭제
  if (!isAmorApp()) {
    axios.post(`${process.env.REACT_APP_API_HOST}/login`, {
      'socialId': '1987037224',
      'platform': 'kakao',
      'device': 'android',
      'pushToken': 'ertery34y34tgr',
    }).then((res) => {
      console.log('return toekn ', res.data.token);
      localStorage.setItem('accessToken', res.data.token);
      window.location.href = '/';
    });
  }
  return (
    <Container>
      <div className="content">
        <img className="logo" src={logo} alt="로고" />
        <div className="login-btns">
          {isMobile && isIOS && (
            <button type="button" onClick={() => onClickSnsLogin('apple')}>
              <img src={btnLoginApple} alt="애플 로그인" />
            </button>
          )}
          <button type="button" onClick={() => onClickSnsLogin('kakao')}>
            <img src={btnLoginKakao} alt="카카오 로그인" />
          </button>
          <button type="button" onClick={() => onClickSnsLogin('facebook')}>
            <img src={btnLoginFacebook} alt="페이스북 로그인" />
          </button>
        </div>
        <p className="ment">
          시작하기를 클릭하면 아모르 이용약관및 개인정보 처리 방안에 동의한
          것으로 간주됩니다.
        </p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 80%;
    text-align: center;

    .logo {
      width: 90px;
      margin-bottom: 120px;
    }

    .login-btns {
      margin-bottom: 120px;

      button {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    .ment {
      text-align: center;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
    }
  }
`;

export default Login;

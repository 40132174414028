import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface IProps {
  visible: boolean;
  title: string;
  description: string;
  btnCancel?: string;
  btnCancelClick?: () => void;
  btnConfirm?: string;
  btnConfirmClick?: () => void;
}

function ModalConfirm({
  visible,
  title,
  description,
  btnCancel,
  btnCancelClick,
  btnConfirm,
  btnConfirmClick,
}: IProps): JSX.Element {
  return (
    <Container visible={visible}>
      <Layer visible={visible}>
        <h3 className="title">{title}</h3>
        <p className="desc">{description}</p>
        <div className="btn-wrap">
          {btnCancel && (
            <button
              className="btn-cancel"
              type="button"
              onClick={btnCancelClick}
            >
              {btnCancel}
            </button>
          )}
          {btnConfirm && (
            <button
              className="btn-confirm"
              type="button"
              onClick={btnConfirmClick}
            >
              {btnConfirm}
            </button>
          )}
        </div>
      </Layer>
      <Dim visible={visible} />
    </Container>
  );
}

const slideUp = keyframes`
  from {
    margin-top:100px;
  } to {
    margin-top:0
  }
`;
const slideDown = keyframes`
  from {
    margin-top:0
  } to {
    margin-top:100px;
  }
`;

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  display: none;

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}
`;

const Dim = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 5s ease;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transition: opacity 5s ease;
    `}
`;

const Layer = styled.div<{ visible: boolean }>`
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background-color: #fff;
  text-align: center;

  ${({ visible }) =>
    visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideUp};
      animation-fill-mode: forwards;
    `}

  ${({ visible }) =>
    !visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideDown};
      animation-fill-mode: forwards;
    `}

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 20px 0;
  }
  .desc {
    font-size: 1.2rem;
    margin-bottom: 20px;
    padding: 0 30px;
  }
  .btn-wrap {
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.color.gray2};

    button {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 1.5rem;

      &.btn-cancel {
        color: ${({ theme }) => theme.color.gray1};
      }
      &.btn-confirm {
        color: ${({ theme }) => theme.color.main1};
      }
    }
  }
`;

export default ModalConfirm;

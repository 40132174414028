import React, { Fragment, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import QuestionFeed from '../../common/QuestionFeed';
import { loading } from '../../../assets/images';
import { IOpenSpace } from '../../../store/openSpace';
import useNavigator from '../../../hooks/useNavigator';

interface IProps {
  list: IOpenSpace[];
  nextPageUrl: string | null;
  fetchOpenSpaceFnForPaging: () => void;
  myNickname: string;
  isBest: boolean;
}

function FeedList({
  list,
  nextPageUrl,
  fetchOpenSpaceFnForPaging,
  myNickname,
  isBest,
}: IProps): JSX.Element {


  return (
    <InfiniteScroll
      dataLength={list.length || 0}
      next={fetchOpenSpaceFnForPaging}
      hasMore={!!nextPageUrl}
      loader={
        <div style={{ textAlign: 'center' }}>
          <img src={loading} alt="로딩 이미지" style={{ width: '80px' }} />
        </div>
      }
      scrollableTarget="ScrollWrap"
      // endMessage={
      //   <p
      //     style={{
      //       textAlign: 'center',
      //       marginBottom: '50px',
      //       color: '#999',
      //     }}
      //   >
      //     더이상 리스트가 없습니다.
      //   </p>
      // }
    >
      {list &&
        list.map((data) => {
          return (
            <Fragment key={`new_${data.id}`}>
              <QuestionFeed
                openSpaceId={data.id}
                className="feed-item"
                thumb={data.writer.profileUrl}
                nickname={data.writer.nickname}
                isMyFeed={data.writer.nickname === myNickname}
                createdAt={data.createdAt}
                hashtags={data.tags && `#${data.tags.join(' #')}`}
                question={data.content}
                coverImage={data.attachments}
                isScrap={data.scrapYn === 'Y'}
                countScrap={data.scrapsCount}
                countComment={data.commentsCount}
                isMyQuestion
                // fetchListFn={fetchOpenSpaceFn}
              />

              {/*              <AdFeed>
                <img src={sampleAd} alt='이미지 광고' />
                <div className='bottom'>
                  <button type='button'>더 알아보기</button>
                </div>
              </AdFeed>
              <AdFeed>
                <div className='video-wrap'>
                  <video
                    width='100%'
                    height='auto'
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls
                  >
                    <source src='/video/sampleVideo.mp4' type='video/mp4' />
                    <track default kind='captions' />
                  </video>
                </div>
                <div className='bottom'>
                  <button type='button'>더 알아보기</button>
                </div>
              </AdFeed>      */}
            </Fragment>
          );
        })}
    </InfiniteScroll>
  );
}

export default FeedList;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Calendar, { YearView } from 'react-calendar';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { iconCalendar, iconList } from '../../../assets/images/icon';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { actions as homeActions, IQuestionCalendar } from '../../../store/home';

import 'react-calendar/dist/Calendar.css';
import { btnNextCalendar, btnPrevCalendar } from '../../../assets/images';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import useFetchData from '../../../hooks/useFetchData';

interface IQuestionCalendarApi {
  success: boolean;
  data: IQuestionCalendar;
}

function StackDiaryQnaCalendar(): JSX.Element {
  const { replaceStackScreen } = useStack();

  // const [value, onChange] = useState(new Date());

  const { selectedDiaryInfo, selectedDiaryId } = useSelector(
    (state: RootState) => ({
      selectedDiaryInfo: state.common.selectedDiaryInfo,
      selectedDiaryId: state.common.selectedDiaryId,
    }),
    shallowEqual,
  );
  const { questionListDate, questionCalendar } = useSelector(
    (state: RootState) => ({
      questionListDate: state.home.questionListDate,
      questionCalendar: state.home.questionCalendar,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  const fetchQuestionCalendar = async () => {
    if (questionListDate.year === null || questionListDate.month === null)
      return;

    dispatch(
      homeActions.setValue('questionCalendar', {
        loading: true,
        data: null,
        error: null,
      }),
    );

    try {
      const { data } = await axios.get<IQuestionCalendarApi>(
        `/diaries/${selectedDiaryId}/questions/calendar`,
        {
          params: {
            year: questionListDate.year,
            month: questionListDate.month,
          },
        },
      );
      if (data.success) {
        console.log('question calendar : ', data);

        if (checkHasData(data.data.minDate, data.data.maxDate)) {
          console.log('1111');
          dispatch(
            homeActions.setValue('questionCalendar', {
              loading: false,
              data: data.data,
              error: null,
            }),
          );
        } else {
          console.log('2222');

          const currentYear = dayjs(new Date()).format('YYYY');
          const currentMonth = dayjs(new Date()).format('MM');

          setQuestionListDate(currentYear, currentMonth);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkHasData = (minDate: number, maxDate: number) => {
    const minYear = Number(dayjs(minDate).format('YYYY'));
    const minMonth = Number(dayjs(minDate).format('MM'));
    const maxYear = Number(dayjs(maxDate).format('YYYY'));
    const maxMonth = Number(dayjs(maxDate).format('MM'));
    const targetYear = Number(questionListDate.year);
    const targetMonth = Number(questionListDate.month);

    let hasQuestionData = false;

    if (minYear <= targetYear && targetYear <= maxYear) {
      if (minYear === targetYear && minMonth <= targetMonth) {
        // 최소 날짜
        hasQuestionData = true;
      }
      if (maxYear === targetYear && maxMonth >= targetMonth) {
        // 최대 날짜
        hasQuestionData = true;
      }
    }

    return hasQuestionData;
  };

  const setQuestionListDate = (currentYear: string, currentMonth: string) => {
    dispatch(
      homeActions.setValue('questionListDate', {
        year: currentYear,
        month: currentMonth,
      }),
    );
  };

  useEffect(() => {
    fetchQuestionCalendar();
  }, [questionListDate, selectedDiaryId]);

  const { removeStackScreen } = useStack();
  // const { fetchDiaryDetail } = useFetchData();
  const onClickDay = (date: string) => {
    // fetchDiaryDetail(date);
    dispatch(commonActions.setValue('selectedDiaryDate', date));
    removeStackScreen();
  };

  return (
    <Container>
      <StackHeader
        title={
          selectedDiaryInfo.data
            ? `${selectedDiaryInfo.data.name}(${selectedDiaryInfo.data.members.length})`
            : ''
        }
        btnRight={
          <BtnListType>
            <button
              type="button"
              className="btn-list"
              onClick={() => replaceStackScreen('StackDiaryQnaList')}
            >
              <span />
            </button>
            <span className="divider" />
            <button type="button" className="btn-calendar">
              <span />
            </button>
          </BtnListType>
        }
      />
      <Content>
        <div className="inner-wrap">
          <div className="calendar-wrap">
            <div className="calendar-indicators">
              <div className="indicator complete">
                <span className="dot" />
                <span>작성</span>
              </div>
            </div>
            <Calendar
              // onChange={onChange}
              // value={[new Date(2021, 10, 15), new Date(2021, 10, 20)]}
              minDate={new Date(Number(`${questionCalendar.data?.minDate}`))}
              maxDate={new Date(Number(`${questionCalendar.data?.maxDate}`))}
              value={
                new Date(
                  Number(`${questionListDate.year}`),
                  Number(`${questionListDate.month}`) - 1,
                  11,
                )
              }
              onActiveStartDateChange={({ activeStartDate }) => {
                const currentYear = dayjs(activeStartDate).format('YYYY');
                const currentMonth = dayjs(activeStartDate).format('MM');

                setQuestionListDate(currentYear, currentMonth);
              }}
              className="diary-calendar"
              tileClassName={({ date }) => {
                const me = questionCalendar.data?.meDates.includes(
                  date.getDate(),
                )
                  ? 'write-me'
                  : '';

                return `${me}`;
              }}
              prevLabel={
                <BtnNavigator>
                  <img src={btnPrevCalendar} alt="이전달 버튼" />
                </BtnNavigator>
              }
              prev2Label={null}
              nextLabel={
                <BtnNavigator>
                  <img src={btnNextCalendar} alt="다음달 버튼" />
                </BtnNavigator>
              }
              next2Label={null}
              formatDay={(locale, date) => dayjs(date).format('D')}
              onClickDay={(value, e) =>
                onClickDay(dayjs(value).format('YYYY-MM-DD'))
              }
            />
          </div>
          <p className="guide">날짜를 터치하면 그날의 기록을 볼 수 있어요</p>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div``;

const BtnListType = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 0 !important;
    padding: 8px;

    span {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 24px;
    }
  }

  .btn-list {
    span {
      background-image: url(${iconList});
    }
  }
  .btn-calendar {
    padding-right: 0;
    span {
      background-image: url(${iconCalendar});
      background-position-y: -24px;
    }
  }

  .divider {
    display: inline-block;
    width: 1px;
    height: 15px;
    background-color: ${({ theme }) => theme.color.gray2};
  }
`;

const Content = styled.div`
  .calendar-wrap {
    margin: 30px 0 40px;
    position: relative;

    .calendar-indicators {
      position: absolute;
      top: 65px;
      right: 0;
      display: flex;

      .indicator {
        display: flex;
        align-items: center;
        margin-left: 15px;

        .dot {
          display: inline-block;
          width: 6px;
          height: 6px;
          align-items: center;
          border-radius: 50%;
          margin-right: 8px;
        }

        &.complete .dot {
          background-color: #6f90ff;
        }

        span {
          font-size: 1.1rem;
          color: ${({ theme }) => theme.color.gray1};
        }
      }
    }
  }

  .diary-calendar {
    width: 100%;
    border: 0;

    .react-calendar__navigation {
      margin-bottom: 40px;
      justify-content: center;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: transparent;
    }

    .react-calendar__navigation__label {
      flex-grow: 0 !important;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
      color: ${({ theme }) => theme.color.gray1};
    }

    .react-calendar__month-view__days__day--neighboringMonth abbr {
      color: ${({ theme }) => theme.color.gray2};
    }

    /* .react-calendar__tile--active {
      position: relative;
      background-color: transparent;

      &:after {
        display: block;
        content: '';
        width: 4px;
        height: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme.color.main1};
        border-radius: 50%;
        margin-top: 12px;
      }
    } */

    .react-calendar__tile--now {
      background-color: transparent;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: transparent;
    }

    /* .react-calendar__tile--hasActive {
      position: relative;
      background-color: transparent;

      &:after {
        display: block;
        content: '';
        width: 42px;
        height: 42px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${({ theme }) => theme.color.main2};
        border-radius: 50%;
      }

      abbr {
        color: ${({ theme }) => theme.color.main1};
        position: relative;
        z-index: 2;
      }
    } */
    //react-calendar__tile react-calendar__tile--hasActive react-calendar__year-view__months__month write-me
    //react-calendar__tile react-calendar__tile--active react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day write-me
    .react-calendar__tile {
      /* background-color: red; */
      position: relative;

      &.react-calendar__month-view__days__day {
        &:after {
          display: block;
          content: '';
          width: 4px;
          height: 4px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          margin-top: 12px;
        }

        &.write-me {
          &:after {
            background-color: #6f90ff;
          }
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          &:after {
            background-color: unset;
          }
        }
      }
    }

    .react-calendar__navigation button[disabled] {
      background-color: transparent;
    }
  }

  .guide {
    font-size: 1.2rem;
    text-align: center;
    color: ${({ theme }) => theme.color.gray2};
  }
`;

const BtnNavigator = styled.div`
  img {
    width: 20px;
  }
`;

export default StackDiaryQnaCalendar;

const convertBoolean = (status: boolean) => (status ? '1' : '0');

// interface IImage {
//   id: string;
//   image: any;
//   isChanged: boolean;
//   isDeleted: boolean;
// }

export function makeFormData(datas: any) {
  const fd = new FormData();

  Object.keys(datas).forEach((key) => {
    if (Array.isArray(datas[key])) {
      // if (key === 'profiles' || key === 'images') {
      //   datas[key].forEach((item: IImage, idx: number) => {
      //     fd.append(`${key}[${idx}][id]`, item.id ? item.id.toString() : '');
      //     fd.append(`${key}[${idx}][image]`, item.image ? item.image : '');
      //     fd.append(`${key}[${idx}][isChanged]`, item.isChanged ? '1' : '0');
      //     fd.append(`${key}[${idx}][isDeleted]`, item.isDeleted ? '1' : '0');
      //   });
      // }
      return;
    }

    if (key === 'cover') {
      fd.append(`${key}[file]`, datas[key].file);
      fd.append(`${key}[isChanged]`, convertBoolean(datas[key].isChanged));
      fd.append(`${key}[type]`, datas[key].type);
      return;
    }

    if (key === 'answer') {
      fd.append(`${key}[id]`, datas[key].id ? datas[key].id : '');
      fd.append(`${key}[file]`, datas[key].file ? datas[key].file : '');
      fd.append(`${key}[isChanged]`, convertBoolean(datas[key].isChanged));
      fd.append(`${key}[isDeleted]`, convertBoolean(datas[key].isDeleted));
      fd.append(`${key}[type]`, datas[key].type ? datas[key].type : '');
      return;
    }

    if (key === 'profile') {
      fd.append(`${key}[id]`, datas[key].id ? datas[key].id : '');
      fd.append(`${key}[file]`, datas[key].file ? datas[key].file : '');
      fd.append(`${key}[isChanged]`, convertBoolean(datas[key].isChanged));
      fd.append(`${key}[isDeleted]`, convertBoolean(datas[key].isDeleted));
      fd.append(`${key}[type]`, datas[key].type);
      return;
    }

    if (key === 'defaultCoverImage') {
      const imageFileName = datas[key].replace('/images/default-cover/', '');
      fd.append(key, imageFileName);
      return;
    }

    if (typeof datas[key] === 'boolean') {
      fd.append(key, convertBoolean(datas[key]));
      return;
    }

    if (datas[key] === null) {
      fd.append(key, '');
      return;
    }

    fd.append(key, datas[key]);
  });

  return fd;
}

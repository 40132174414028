import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import StackHeader from '../../../layouts/StackHeader';
import { iconCameraWhiteCircle } from '../../../assets/images/icon';
import Button from '../../parts/Button';
import Input from '../../parts/Input';
import { makeFormData } from '../../../utils/makeFormData';
import useStack from '../../../hooks/useStack';
import useMyProfile from '../../../hooks/useMyProfile';
import { RootState } from '../../../store';
import useFetchData from '../../../hooks/useFetchData';
import useNavigator from '../../../hooks/useNavigator';

interface IFormData {
  profile: {
    id: number | null;
    file: null;
    isChanged: boolean;
    isDeleted: boolean;
    type: 'image';
    url: string | null;
  };
  nickname: string;
}

function StackProfileForm(): JSX.Element {
  const [formData, setFormData] = useState<IFormData>({
    profile: {
      id: null,
      file: null,
      isChanged: false,
      isDeleted: false,
      type: 'image',
      url: null,
    },
    nickname: '',
  });
  const [isErrorInput, setIsErrorInput] = useState(false);
  const { removeStackScreen } = useStack();
  const { fetchMyProfile } = useMyProfile();
  const { fetchMainDiaries } = useFetchData();

  const onChangeProfileImage = useCallback(
    (e) => {
      const file = e.target.files[0];
      let url = null;
      if (file && file.type.match(/^image\/(png|jpeg)$/)) {
        url = URL.createObjectURL(file);
        setFormData({
          ...formData,
          profile: {
            ...formData.profile,
            file,
            isChanged: true,
            url,
          },
        });
      } else {
        // setErrorMessage('이미지 파일만 등록할 수 있습니다.');
        // return;
      }
    },
    [formData],
  );

  const onChangeText = useCallback(
    (e) => {
      setFormData({
        ...formData,
        nickname: e.target.value,
      });
    },
    [formData],
  );

  const onSubmitProfile = useCallback(async () => {
    if (!formData.nickname) return setIsErrorInput(true);

    const newFormData = makeFormData(formData);
    newFormData.append('_method', 'PUT');
    try {
      const { data } = await axios.post('/me', newFormData);
      if (data.success) {
        fetchMyProfile();
        fetchMainDiaries();
        removeStackScreen();
        // localStorage.setItem('isJoined', 'true');
      }
    } catch (e) {
      console.log('profile error : ', e);
    }
  }, [formData, makeFormData]);

  const isJoined = localStorage.getItem('isJoined');

  const setProfile = async () => {
    if (!isJoined) return;

    try {
      const { data } = await axios.get('/me/detail');
      if (data.success) {
        console.log('profile : ', data);
        const { user } = data;
        setFormData({
          profile: {
            ...formData.profile,
            id: user.profile.id,
            type: 'image',
            url: user.profile.url,
          },
          nickname: user.nickname,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [showNavigator, hideNavigator] = useNavigator();

  useEffect(() => {
    setProfile();
    hideNavigator();
  }, []);

  return (
    <Container>
      <StackHeader title="프로필 입력" hideBtnLeft={!isJoined} />
      <Content>
        <div className="inner-wrap">
          <ProfileImage
            style={{
              backgroundImage: `url(${
                formData.profile.url
                  ? formData.profile.url
                  : '/images/defaultProfile.png'
              })`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={onChangeProfileImage}
            />
            <img
              className="icon-camera"
              src={iconCameraWhiteCircle}
              alt="카메라 아이콘"
            />
          </ProfileImage>
          <InputWrap>
            <Input
              type="text"
              value={formData.nickname}
              onChange={onChangeText}
            />
            {isErrorInput && (
              <p className="error-message">닉네임을 입력해주세요.</p>
            )}
          </InputWrap>
          <Button fullWidth onClick={onSubmitProfile}>
            확인
          </Button>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;

  text-align: center;
  padding-top: 40px;
`;

const ProfileImage = styled.label`
  display: inline-block;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 35px;

  input {
    width: 0;
    height: 0;
  }

  .icon-camera {
    width: 36px;
    position: absolute;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
`;

const InputWrap = styled.div`
  margin-bottom: 95px;

  .error-message {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.main1};
    text-align: left;
    margin-top: 4px;
  }
`;

export default StackProfileForm;

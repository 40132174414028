import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import {
  btnClose,
  btnRecordStart,
  btnRecordStop,
} from '../../../assets/images';
import 'react-circular-progressbar/dist/styles.css';

interface IProps {
  audioURL?: string;
  isRecording?: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  hideAudioRecorder: () => void;
}

function AudioRecorder({
  hideAudioRecorder,
  audioURL,
  isRecording,
  startRecording,
  stopRecording,
}: IProps): JSX.Element {
  const [recordingTime, setRecordingTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const onClickStartRecording = () => {
    startRecording();
    setTimer(
      setInterval(() => {
        setRecordingTime((v) => v + 1);
      }, 1000),
    );
  };

  const onClickStopRecording = () => {
    if (timer) {
      stopRecording();
      clearInterval(timer);
      hideAudioRecorder();
    }
  };

  useEffect(() => {
    if (recordingTime === 180 && timer) {
      stopRecording();
      clearInterval(timer);
      hideAudioRecorder();
    }

    setMinutes(Math.floor(recordingTime / 60));
    setSeconds(recordingTime % 60);
  }, [recordingTime, timer]);

  return (
    <Container>
      <div
        role="button"
        aria-label="dim"
        className="dim"
        onClick={hideAudioRecorder}
      />
      <Recorder>
        {!isRecording && (
          <button
            className="btn-close"
            type="button"
            onClick={hideAudioRecorder}
          >
            <img src={btnClose} alt="닫기 버튼" />
          </button>
        )}
        <div className="control-wrap">
          {isRecording ? (
            <p className="time">
              {`0${minutes}`}:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p className="ment">녹음은 최대 3분까지 가능합니다.</p>
          )}
          <div className="progress-circle">
            <CircularProgressbarWithChildren
              maxValue={180}
              value={recordingTime}
              styles={buildStyles({
                strokeLinecap: 'butt',
                pathColor: '#FF6957',
                trailColor: '#f5f5f5',
                backgroundColor: '#fff',
              })}
              strokeWidth={2}
              background
            >
              {isRecording ? (
                <button
                  className="btn-record-stop"
                  type="button"
                  onClick={onClickStopRecording}
                >
                  <img src={btnRecordStop} alt="녹음 종료 버튼" />
                </button>
              ) : (
                <button
                  className="btn-record-start"
                  type="button"
                  onClick={onClickStartRecording}
                >
                  <img src={btnRecordStart} alt="녹음 시작 버튼" />
                </button>
              )}
            </CircularProgressbarWithChildren>
          </div>
          <div className="control" />
        </div>
      </Recorder>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  .audio-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const Recorder = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 350px;
  background-color: ${({ theme }) => theme.color.gray3};
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;

    img {
      width: 22px;
    }
  }

  .control-wrap {
    text-align: center;

    .ment {
      color: ${({ theme }) => theme.color.gray1};
      margin-bottom: 25px;
      font-family: 'NanumSquare';
    }

    .time {
      color: ${({ theme }) => theme.color.gray1};
      margin-bottom: 25px;
      font-family: 'NanumSquare';
    }

    .progress-circle {
      width: 128px;
      height: 128px;
      margin: 0 auto;

      .btn-record-start img {
        width: 38px;
      }
      .btn-record-stop img {
        width: 32px;
      }
    }
  }
`;

export default AudioRecorder;

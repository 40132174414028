import { url } from 'inspector';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  iconNavHome,
  iconNavOpenSpace,
  iconNavScrap,
} from '../../../assets/images/icon';
import { RootState } from '../../../store';

function WebNavigator(): JSX.Element {
  const { currentPage } = useSelector((state: RootState) => state.common);

  return (
    <Container>
      <nav>
        <div className="nav-btn">
          <Link to="/open-space">
            <NavIcon
              active={currentPage === '/open-space'}
              style={{ backgroundImage: `url(${iconNavOpenSpace})` }}
            />
          </Link>
        </div>
        <div className="nav-btn">
          <Link to="/">
            <NavIcon
              active={currentPage === '/'}
              style={{ backgroundImage: `url(${iconNavHome})` }}
            />
          </Link>
        </div>
        <div className="nav-btn">
          <Link to="/scrap">
            <NavIcon
              active={currentPage === '/scrap'}
              style={{ backgroundImage: `url(${iconNavScrap})` }}
            />
          </Link>
        </div>
      </nav>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 10000;

  nav {
    display: inline-block;
    padding: 14px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .nav-btn {
      display: inline-block;
      margin: 0 20px;
    }
  }
`;

const NavIcon = styled.span<{ active: boolean }>`
  display: inline-block;
  width: 28px;
  height: 28px;
  background-size: 28px;
  background-position: ${({ active }) => (active ? '0 -28px' : '0 0')};
  background-repeat: no-repeat;
`;

export default WebNavigator;

import React, { useState } from 'react';

type InputTypes = {
  value: string;
  onChangeText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: (value: string) => void;
};

function useInput(): InputTypes {
  const [value, setValue] = useState('');

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return { value, onChangeText, setValue };
}

export default useInput;

import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import StackHeader from '../../../layouts/StackHeader';
import Radio from '../../parts/Radio';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import useFetchData from '../../../hooks/useFetchData';
import useMyProfile from '../../../hooks/useMyProfile';
import useStack from '../../../hooks/useStack';

type Language = 'kr' | 'jp' | 'en' | 'cn';

function StackSettingLanguage(): JSX.Element {
  const { myProfile } = useSelector((state: RootState) => state.common);
  const { fetchMainDiaries } = useFetchData();
  const { fetchMyProfile } = useMyProfile();
  const { removeStackScreen } = useStack();

  const [language, setLanguage] = useState(myProfile?.language);

  const onClickRadio = (e: ChangeEvent<HTMLInputElement>) => {
    const language = e.target.value as Language;
    setLanguage(language);
  };

  const onSubmitLanguageSetting = () => {
    axios
      .put('/me/language', {
        language,
      })
      .then(({ data }) => data.success === true)
      .then(() => {
        fetchMyProfile();
        fetchMainDiaries();
        removeStackScreen();
      });
  };

  return (
    <Container>
      <StackHeader
        title="질문 언어설정"
        btnRight={
          <button
            className="btn-save"
            type="button"
            onClick={onSubmitLanguageSetting}
          >
            SAVE
          </button>
        }
      />
      <Content>
        <div className="inner-wrap">
          <SettingSection>
            <p className="ment">설정 한 언어로 질문이 전송됩니다.</p>
            <div className="setting-item">
              <p>한국어</p>
              <Radio
                name="radio-language"
                value="kr"
                onChange={onClickRadio}
                checked={language === 'kr'}
              />
            </div>
            <div className="setting-item">
              <p>영어</p>
              <Radio
                name="radio-language"
                value="en"
                onChange={onClickRadio}
                checked={language === 'en'}
              />
            </div>
            <div className="setting-item">
              <p>중국어</p>
              <Radio
                name="radio-language"
                value="cn"
                onChange={onClickRadio}
                checked={language === 'cn'}
              />
            </div>
            <div className="setting-item">
              <p>일본어</p>
              <Radio
                name="radio-language"
                value="jp"
                onChange={onClickRadio}
                checked={language === 'jp'}
              />
            </div>
          </SettingSection>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const SettingSection = styled.div`
  padding-top: 30px;

  .ment {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.gray1};
  }

  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    position: relative;
  }
`;

export default StackSettingLanguage;

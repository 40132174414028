import React from 'react';
import styled from 'styled-components';
import { sampleImage1, sampleProfile2 } from '../../../assets/images';
import { iconManager } from '../../../assets/images/icon';
import { IMember } from '../../../store/home';

interface IProps {
  userList: IMember[];
  isWithLargeThumb?: boolean;
}

function UserThumbList({ userList, isWithLargeThumb }: IProps): JSX.Element {
  const listWidth = () => {
    let width = 32;
    if (userList.length > 1 && userList.length <= 4) {
      width += 24 * (userList.length - 1);
    } else if (userList.length > 4) {
      width = 104;
    }
    return `${width}px`;
  };

  return (
    <Container className="list" style={{ width: listWidth() }}>
      {userList.map((user, index) => {
        if (index >= 0 && index <= 2)
          return (
            <li key={user.id}>
              <span
                className="thumb"
                style={{ backgroundImage: `url(${user.profileUrl})` }}
              />
              {user.isOwner && (
                <img
                  className="icon-manager"
                  src={iconManager}
                  alt="방장 아이콘"
                />
              )}
            </li>
          );
        if (index === 3)
          return (
            <li key={user.id}>
              <span
                className="thumb"
                style={{ backgroundImage: `url(/images/defaultProfile.png)` }}
              />
              <div className="rest-count">
                +{isWithLargeThumb ? userList.length - 4 : userList.length - 3}
              </div>
            </li>
          );
        return null;
      })}
    </Container>
  );
}

UserThumbList.defaultProps = {
  isWithLargeThumb: false,
};

const Container = styled.ul`
  height: 32px;
  position: relative;
  display: inline-block;

  li {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    &:nth-child(1) {
      z-index: 4;
    }
    &:nth-child(2) {
      margin-left: 24px;
      z-index: 3;
    }
    &:nth-child(3) {
      margin-left: 48px;
      z-index: 2;
    }
    &:nth-child(4) {
      margin-left: 72px;
      z-index: 1;
    }
  }

  li .thumb {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  li .rest-count {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }

  .icon-manager {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
  }
`;

export default UserThumbList;

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { actions as commonActions } from '../../../store/common';
import Button from '../../parts/Button';
import Radio from '../../parts/Radio';

interface IProps {
  reportId: number;
  hideReportForm: () => void;
}
interface IReportReason {
  id: number;
  reason: string;
}
export interface IReportUserForm {
  reportId: number | null;
  reportDetail: string;
}

function ReportUserForm({ reportId, hideReportForm }: IProps): JSX.Element {
  const dispatch = useDispatch();

  const [reportUserForm, setReportUser] = useState<IReportUserForm>({
    reportId: null,
    reportDetail: '',
  });
  const onChangeRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const radioValue = Number(e.target.value);

      setReportUser({
        reportId: radioValue,
        reportDetail: '',
      });
    },
    [],
  );
  const onChangeReportDescription = useCallback(
    (e) => {
      setReportUser({
        ...reportUserForm,
        reportDetail: e.target.value,
      });
    },
    [reportUserForm],
  );
  const submitReport = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `/openspaces/${reportId}/report`,
        reportUserForm,
      );
      if (data.success) {
        hideReportForm();
        dispatch(
          commonActions.setValue('alertMessage', '신고가 완료되었습니다.'),
        );
      }
    } catch (e: any) {
      hideReportForm();
      dispatch(
        commonActions.setValue(
          'alertMessage',
          e.response.data.errors.message[0],
        ),
      );
    }
  }, [reportId, reportUserForm]);

  const [reportReasons, setReportReasons] = useState<IReportReason[]>([]);

  const getReportReasons = async () => {
    try {
      const { data } = await axios.get('/report/reasons');
      if (data.success) {
        setReportReasons(data.reasons);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getReportReasons();
  }, []);

  return (
    <Container>
      <h2>신고하기</h2>
      <div className="form">
        <div className="inner-wrap">
          <p className="ment">신고하는 이유는 무엇인가요?</p>
          <div className="radio-list">
            {reportReasons.map((item) => (
              <div className="radio-item" key={item.id}>
                <Radio
                  name="report-user"
                  checked={reportUserForm.reportId === item.id}
                  value={`${item.id}`}
                  onChange={onChangeRadio}
                />
                <p>{item.reason}</p>
              </div>
            ))}
            {/* <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 1}
                value="1"
                onChange={onChangeRadio}
              />
              <p>나체 이미지 또는 성적 발언</p>
            </div>
            <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 2}
                value="2"
                onChange={onChangeRadio}
              />
              <p>혐오 발언 또는 상징</p>
            </div>
            <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 3}
                value="3"
                onChange={onChangeRadio}
              />
              <p>폭력 또는 위험한 단체</p>
            </div>
            <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 4}
                value="4"
                onChange={onChangeRadio}
              />
              <p>불법 또는 규제 상품 판매</p>
            </div>
            <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 5}
                value="5"
                onChange={onChangeRadio}
              />
              <p>지적 재산권 침해</p>
            </div>
            <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 6}
                value="6"
                onChange={onChangeRadio}
              />
              <p>자살, 자해 및 섭식 장애</p>
            </div>
            <div className="radio-item">
              <Radio
                name="report-user"
                checked={reportUserForm.reportId === 7}
                value="7"
                onChange={onChangeRadio}
              />
              <p>기타 내용을 직접 작성하시겠습니까?</p>
            </div> */}
          </div>
          <textarea
            disabled={reportUserForm.reportId !== 1}
            value={reportUserForm.reportDetail}
            onChange={onChangeReportDescription}
          />
          <Button
            fullWidth
            color={reportUserForm.reportId !== null ? 'main' : 'gray'}
            onClick={submitReport}
          >
            신고하기
          </Button>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  h2 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 15px;
  }

  .form {
    padding-top: 25px;
    border-top: 1px solid ${({ theme }) => theme.color.gray3};
    text-align: left;

    .ment {
      color: ${({ theme }) => theme.color.gray1};
      margin-bottom: 25px;
    }

    .radio-list {
      margin-bottom: 10px;

      .radio-item {
        display: flex;
        margin-bottom: 15px;

        p {
          flex: 1;
          margin-left: 10px;
        }
      }
    }

    textarea {
      width: 100%;
      height: 80px;
      border: 1px solid ${({ theme }) => theme.color.main1};
      border-radius: 8px;
      margin-bottom: 30px;
      padding: 10px;
      appearance: none;

      &:disabled {
        border-color: ${({ theme }) => theme.color.gray2};
        background-color: ${({ theme }) => theme.color.gray3};
      }
    }
  }
`;

export default ReportUserForm;

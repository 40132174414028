export function localToUtc(date: Date) {
  // eslint-disable-next-line no-underscore-dangle
  const convert = new Date(date.getTime() + (date.getTimezoneOffset() * 60 * 1000));  // UTC 시간으로 변환
  return convert;
}

export function utcToLocal(date: Date) {
  // eslint-disable-next-line no-underscore-dangle
  const convert = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000));  // UTC 시간으로 변환
  return convert;
}

export function getTimezoneOffset(){
  return new Date().getTimezoneOffset() / 60;
}

export function receiveTimeToLocal(receiveTime: string | null) {
  if (!receiveTime) {
    return null;
  }

  // console.log(receiveTime);
  const t = new Date(2000, 1, 1, Number(receiveTime.split(':')[0]), Number(receiveTime.split(':')[1]));
  // const convert = new Date(t.getTime() - (t.getTimezoneOffset() * 60 * 1000));  // UTC 시간으로 변환
  const convert = utcToLocal(t);
  // console.log('convert', convert);
  const hour = convert.getHours() < 10 ? `0${convert.getHours()}` : `${convert.getHours()}`;
  const min = convert.getMinutes() < 10 ? `0${convert.getMinutes()}` : `${convert.getMinutes()}`;
  return `${hour}:${min}`;
}

import axios from 'axios';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { commentBlur, sampleProfile1 } from '../../../assets/images';
import {
  iconLike,
  iconLockWhite,
  iconMoreMenu2,
} from '../../../assets/images/icon';
import { IComment } from '../../../store/home';
import { actions as commonActions } from '../../../store/common';
import { actions as openSpaceActions } from '../../../store/openSpace';
import { CommentContext } from '../StackDiaryDetail';
import VideoPlayer from '../VideoPlayer';
import { RootState } from '../../../store';

interface IProps {
  listType: 'diary' | 'openSpace';
  commentType: 'answer' | 'comment';
  commentData: IComment;
  commentIndex: number;
  replyIndex?: number;
  parentCommentId?: number;
  isOpen?: boolean;
  isAnswered?: boolean;
  attachedAudio?: string;
  attachedImage?: string;
  attachedVideo?: string;
  isCommentReply?: boolean;
  // commentReplyTarget?: string;
  setCommentInputView?: (visible: boolean) => void;
  setBottomToastModalView: (visible: boolean) => void;
  currentScroll?: number;
}

function Comment({
  listType,
  commentType,
  commentData,
  commentIndex,
  replyIndex,
  parentCommentId,
  isOpen,
  isAnswered,
  attachedAudio,
  attachedImage,
  attachedVideo,
  isCommentReply,
  // commentReplyTarget,
  setCommentInputView,
  setBottomToastModalView,
  currentScroll,
}: IProps): JSX.Element {
  const commentContext = useContext(CommentContext);

  const {
    myProfile,
    selectedComment,
    scrollToComment,
    selectedDiaryDetail,
    selectedDiaryInfo,
    selectedOpenSpaceId,
  } = useSelector(
    (state: RootState) => ({
      myProfile: state.common.myProfile,
      selectedComment: state.common.selectedComment,
      scrollToComment: state.common.scrollToComment,
      selectedDiaryDetail: state.common.selectedDiaryDetail,
      selectedDiaryInfo: state.common.selectedDiaryInfo,
      selectedOpenSpaceId: state.common.selectedOpenSpaceId,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const commentRef = useRef<HTMLDivElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    if (commentData.id === scrollToComment?.commentId) {
      if (commentRef.current) {
        console.log('1 : ', commentRef.current.offsetTop);
        const commentOffset =
          commentRef.current.offsetTop > 300
            ? commentRef.current.offsetTop - 300
            : 0;
        dispatch(
          commonActions.setValue('scrollToComment', {
            ...scrollToComment,
            commentOffset,
          }),
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!currentScroll || !attachedVideo) return;
    const screenHeightHalf = window.innerHeight / 2;
    const commentPositionTop = commentRef?.current?.offsetTop;

    if (
      commentPositionTop &&
      currentScroll > commentPositionTop + screenHeightHalf &&
      currentScroll < commentPositionTop + screenHeightHalf + 100
    ) {
      setIsVideoPlaying(true);
    } else {
      setIsVideoPlaying(false);
    }
  }, [currentScroll, attachedVideo]);

  const onToggleLike = useCallback(async () => {
    const method = commentData.isLiked ? 'delete' : 'post';

    try {
      const { data } = await axios({
        method,
        url: `/comments/${commentData.id}/likes`,
      });
      if (data.success) {
        if (listType === 'diary') {
          if (isCommentReply && replyIndex !== undefined) {
            // 대댓글인 경우
            dispatch(
              commonActions.replyLikeToggleOnDiary(commentIndex, replyIndex),
            );
          } else {
            // 댓글인 경우
            dispatch(commonActions.commentLikeToggleOnDiary(commentIndex));
          }
        }

        if (listType === 'openSpace') {
          if (isCommentReply && replyIndex !== undefined) {
            // 대댓글인 경우
            dispatch(
              openSpaceActions.replyLikeToggleOnFeed(commentIndex, replyIndex),
            );
          } else {
            // 댓글인 경우
            dispatch(openSpaceActions.commentLikeToggleOnFeed(commentIndex));
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  }, [commentData, isCommentReply, commentIndex, replyIndex]);

  const onClickWriteReply = useCallback(() => {
    let commentId = commentData.id;
    if (isCommentReply && parentCommentId) {
      commentId = parentCommentId;
    }

    dispatch(
      commonActions.setValue('selectedComment', {
        ...selectedComment,
        id: commentId,
        content: '',
        taggedMemberId: commentData.userId,
        taggedMemberNickname: commentData.nickname,
      }),
    );

    if (setCommentInputView) setCommentInputView(true);
  }, [commentData, isCommentReply, parentCommentId, selectedComment]);

  const onClickCommentMoreMenu = useCallback(() => {
    dispatch(
      commonActions.setValue('selectedComment', {
        id: commentData.id,
        type: commentData.type,
        content: commentData.content,
        taggedMemberId: commentData.userId,
        taggedMemberNickname: commentData.nickname,
      }),
    );

    setBottomToastModalView(true);
  }, [commentData]);

  const fetchOpenspaceDetail = () => {
    axios.get(`/openspaces/${selectedOpenSpaceId}`).then((res) => {
      if (res.data && res.data.success) {
        const { data } = res.data;
        if (data.tags && data.tags.length > 0) {
          data.tagStr = `#${data.tags.join(' #')}`;
        }
        dispatch(openSpaceActions.setValue('openSpaceDetail', data));
      }
    });
  };

  const onClickReportComment = async () => {
    try {
      const { data } = await axios.post(`/comments/${commentData.id}/report`);
      if (data.success) {
        fetchOpenspaceDetail();
        dispatch(
          commonActions.setValue('alertMessage', '신고가 완료되었습니다'),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container ref={commentRef}>
      <Thumb
        profileImage={commentData.profileUrl}
        isCommentReply={isCommentReply}
      />
      <div className="content-wrap">
        <div>
          <div className="top">
            <p className="name">{commentData.nickname}</p>
            {((selectedDiaryDetail.data?.isAnswered &&
              selectedDiaryDetail.data?.isOpen) ||
              listType === 'openSpace') && (
              <span className="time">{commentData.createdAt}</span>
            )}
          </div>
          <div className="content">
            <div className="inner">
              {commentData.content && isAnswered && isOpen && (
                <p className="desc">
                  {commentData.taggedMemberNickname && (
                    <span className="target-user">
                      @{commentData.taggedMemberNickname}
                    </span>
                  )}
                  {commentData.content}
                </p>
              )}
              {attachedAudio && isAnswered && isOpen && (
                <audio
                  style={{ width: '100%', height: '40px' }}
                  controlsList="nodownload"
                  controls
                >
                  <source src={attachedAudio} type="audio/mpeg" />
                </audio>
              )}
              {attachedImage && isAnswered && isOpen && (
                <PreviewImage
                  src={attachedImage}
                  alt="첨부 이미지"
                  onClick={() =>
                    commentContext?.showZoomView(commentData.attachment.url)
                  }
                  className="preview-image"
                />
              )}
              {attachedVideo && isAnswered && isOpen && (
                <div className="video-wrap">
                  <VideoPlayer
                    src={attachedVideo}
                    isVideoPlaying={isVideoPlaying}
                  />
                </div>
              )}
              {(!isAnswered || !isOpen) && listType === 'diary' && (
                <div className="blur-comment">
                  <div className="dim" />
                  <div className="text-wrap">
                    <img
                      className="icon-lock"
                      src={iconLockWhite}
                      alt="자물쇠 아이콘"
                    />
                    {!isAnswered && (
                      <p>
                        세상에 공짜는 없어요 :)
                        <br /> 나의 답변을 쓰고 내용을 확인해보세요
                      </p>
                    )}
                    {!isOpen && isAnswered && (
                      <p>첫 질문의 답변은 1년 뒤 공개됩니다!</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            {((selectedDiaryInfo.data?.status === 'P' &&
              selectedDiaryDetail.data?.isAnswered &&
              selectedDiaryDetail.data?.isOpen) ||
              listType === 'openSpace') && (
              <BtnLike
                active={commentData.isLiked}
                className="btn-like"
                onClick={onToggleLike}
              >
                <span className="icon-like" />
              </BtnLike>
            )}
          </div>
        </div>
        <div className="btns">
          <div className="left">
            {((selectedDiaryInfo.data?.status === 'P' &&
              selectedDiaryDetail.data?.isAnswered &&
              selectedDiaryDetail.data?.isOpen) ||
              listType === 'openSpace') && (
              <>
                <span className="like-count">
                  좋아요 {commentData.likesCount}개
                </span>
                <button type="button" onClick={onClickWriteReply}>
                  댓글달기
                </button>
              </>
            )}
          </div>
          <div className="right">
            {((selectedDiaryInfo.data?.status === 'P' &&
              selectedDiaryDetail.data?.isAnswered &&
              selectedDiaryDetail.data?.isOpen) ||
              listType === 'openSpace') &&
              myProfile?.id === commentData.userId && (
                <button type="button" onClick={onClickCommentMoreMenu}>
                  <img src={iconMoreMenu2} alt="더보기 버튼" />
                </button>
              )}

            {listType === 'openSpace' && myProfile?.id !== commentData.userId && (
              <button type="button" onClick={onClickReportComment}>
                신고하기
              </button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 28px;
  display: flex;

  .content-wrap {
    flex: 1;
    padding-left: 15px;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .name {
        font-size: 1.2rem;
        font-weight: 700;
      }

      .time {
        font-size: 1.2rem;
      }
    }

    .content {
      display: flex;

      .inner {
        flex: 1;
        padding-right: 12px;

        audio {
          width: 100%;
          margin-top: 12px;
        }

        .desc {
          font-size: 1.2rem;

          .target-user {
            font-size: 1.2rem;
            color: ${({ theme }) => theme.color.gray1};
            margin-right: 8px;
          }
        }

        .video-wrap {
          border-radius: 16px;

          video {
            vertical-align: top;
            margin-top: 12px;
          }
        }

        .preview-image {
          margin-top: 12px;
        }

        .blur-comment {
          height: 88px;
          border-radius: 16px;
          background: url(${commentBlur}) no-repeat center;
          background-size: cover;
          text-align: center;
          position: relative;
          margin-top: 12px;

          .dim {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 16px;
          }

          .text-wrap {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            padding-top: 15px;

            .icon-lock {
              width: 11px;
              margin-bottom: 10px;
            }

            p {
              font-size: 1.2rem;
              color: #fff;
            }
          }
        }
      }

      .btn-like {
        width: 24px;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;

    .left {
      /* display: flex;
      align-items: center; */

      span {
        vertical-align: top;
      }

      button {
        vertical-align: top;
        margin-top: -1px;
      }
    }

    .right {
      margin-top: -6px;

      button {
        margin-right: 0;
      }

      img {
        width: 28px;
      }
    }

    & span,
    & button {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
      margin-right: 6px;
    }

    .like-count {
      font-family: 'NanumSquare';
    }
  }
`;

const Thumb = styled.div<{ profileImage: string; isCommentReply?: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  ${({ isCommentReply }) =>
    isCommentReply &&
    css`
      width: 32px;
      height: 32px;
    `}

  ${({ profileImage }) =>
    profileImage &&
    css`
      background-image: url(${profileImage});
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

const BtnLike = styled.button<{ active: boolean }>`
  width: 24px;
  height: 24px;
  background-image: url(${iconLike});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 24px;

  ${({ active }) =>
    active &&
    css`
      background-position-y: -24px;
    `}
`;

const PreviewImage = styled.img<{
  onClick: () => void | null;
}>`
  width: 100%;
  border-radius: 16px;
`;

export default Comment;

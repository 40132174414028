import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import produce from 'immer';
import Parser from 'html-react-parser';
import { loading, logoGray, sampleImage1 } from '../../../assets/images';
import StackHeader from '../../../layouts/StackHeader';
import { IAsyncData } from '../../../store/common';
import LoadingContent from '../LoadingContent';
import NoList from '../NoList';

interface IAlarm {
  id: number;
  category: string;
  content: string;
  imageUrl: string;
  landingUrl: string;
  sentAt: string;
}
interface IAlarmList {
  list: IAlarm[];
  nextPageUrl: string;
}

interface IAlarmListApi {
  nextPageUrl: string;
  pushLogs: IAlarm[];
  success: boolean;
}

function StackAlarm(): JSX.Element {
  const [alarmList, dispatch] = useReducer(reducer, {
    loading: false,
    data: {
      list: [],
      nextPageUrl: '',
    },
    error: null,
  });

  const fetchAlarmList = async () => {
    if (alarmList.data.list.length === 0)
      dispatch({ type: 'FETCH_ALARM_LIST' });

    let url = `/me/push`;
    if (alarmList.data && alarmList.data.nextPageUrl.length > 0)
      url = alarmList.data?.nextPageUrl;

    try {
      const { data } = await axios.get<IAlarmListApi>(url);
      if (data.success) {
        dispatch({
          type: 'FETCH_ALARM_LIST_SUCCESS',
          payload: {
            list: data.pushLogs,
            nextPageUrl: data.nextPageUrl,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAlarmList();
  }, []);

  return (
    <Container>
      <StackHeader title="알림" />
      <Content id="AlarmScrollWrap">
        {alarmList.loading && <LoadingContent />}

        {!alarmList.loading && alarmList.data?.list.length === 0 && (
          <NoList ment="아직 도착한 알람이 없어요" />
        )}

        {!alarmList.loading &&
          alarmList.data &&
          alarmList.data.list.length > 0 && (
            <InfiniteScroll
              dataLength={alarmList.data.list.length}
              next={fetchAlarmList}
              hasMore={!!alarmList.data.nextPageUrl}
              loader={
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={loading}
                    alt="로딩 이미지"
                    style={{ width: '80px' }}
                  />
                </div>
              }
              scrollableTarget="AlarmScrollWrap"
              endMessage={
                <p
                  style={{
                    textAlign: 'center',
                    marginBottom: '50px',
                    color: '#999',
                  }}
                >
                  더이상 리스트가 없습니다.
                </p>
              }
            >
              <div className="inner-wrap">
                <AlarmList>
                  {alarmList.data.list.map((item: IAlarm) => (
                    <li key={item.id} className="alarm">
                      <div className="top">
                        <h3 className="title">{item.category}</h3>
                        <span className="date">{item.sentAt}</span>
                      </div>
                      <a href={item.landingUrl}>
                        <div className="message">
                          <p className="desc">{Parser(item.content)}</p>
                          {item.imageUrl && (
                            <div
                              className="thumb"
                              style={{
                                backgroundImage: `url(${item.imageUrl})`,
                              }}
                            />
                          )}
                        </div>
                      </a>
                    </li>
                  ))}
                </AlarmList>
              </div>
            </InfiniteScroll>
          )}
      </Content>
    </Container>
  );
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'FETCH_ALARM_LIST':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_ALARM_LIST_SUCCESS':
      return produce(state, (draft: any) => {
        draft.loading = false;
        draft.data.list.push(...action.payload.list);
        draft.data.nextPageUrl = action.payload.nextPageUrl;
      });
    default:
      return state;
  }
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding: 10px 0 30px;
  box-sizing: border-box;
`;

const AlarmList = styled.ul`
  .alarm {
    padding: 15px 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .title {
        font-size: 1.2rem;
      }

      .date {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.color.gray1};
        font-weight: 300;
      }
    }

    .message {
      display: flex;

      .desc {
        flex: 1;
        color: #696969;
        font-family: 'NanumSquare';

        strong {
          font-family: 'NanumSquare';
        }

        a {
          display: block;
          font-size: 1.2rem;
          text-decoration: underline;
          color: ${({ theme }) => theme.color.main1};
        }
      }

      .thumb {
        width: 44px;
        height: 44px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
`;

export default StackAlarm;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import {
  iconClock,
  iconMoreMenu,
  iconQuotesLeft,
  iconQuotesRight,
  iconScrap,
} from '../../../assets/images/icon';
import StackHeader from '../../../layouts/StackHeader';
import CommentList from '../CommentList';
import Button from '../../parts/Button';
import { actions as commonActions } from '../../../store/common';
import { actions as homeActions } from '../../../store/home';
import {
  btnClose,
  commentBubbleGray,
  sampleProfile1,
} from '../../../assets/images';
import UserThumbList from '../UserThumbList';
import ZoomView from '../ZoomView';
import BottomToastModal from '../BottomToastModal';
import ModalConfirm from '../ModalConfirm';
import WriteCommentInput from '../WriteCommentInput';
import useStack from '../../../hooks/useStack';
import { RootState } from '../../../store';
import useFetchData from '../../../hooks/useFetchData';
import LoadingContent from '../LoadingContent';

interface ICommentContext {
  showZoomView: (url: string) => void;
}

export const CommentContext = React.createContext<ICommentContext | null>(null);

function StackDiaryDetail(): JSX.Element {
  const {
    selectedDiaryId,
    selectedDiaryInfo,
    selectedDiaryDetail,
    selectedDiaryDate,
    selectedComment,
    scrollToComment,
    safeArea,
  } = useSelector(
    (state: RootState) => ({
      selectedDiaryId: state.common.selectedDiaryId,
      selectedDiaryInfo: state.common.selectedDiaryInfo,
      selectedDiaryDetail: state.common.selectedDiaryDetail,
      selectedDiaryDate: state.common.selectedDiaryDate,
      selectedComment: state.common.selectedComment,
      scrollToComment: state.common.scrollToComment,
      safeArea: state.common.safeArea,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        commonActions.setValue('scrollToComment', {
          commentId: null,
          commentOffSet: null,
        }),
      );
    };
  }, []);

  const scrollContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContentRef.current && scrollToComment?.commentOffset)
      scrollContentRef.current?.scrollTo({
        top: scrollToComment.commentOffset,
      });
  }, [scrollToComment]);

  const { addStackScreen } = useStack();

  const onClickListView = useCallback(() => {
    addStackScreen('StackDiaryQnaList');
    setBottomToastDiaryMenu(false);
  }, [addStackScreen]);

  const onClickDiarySetting = useCallback(() => {
    addStackScreen('StackDiarySetting');
    setBottomToastDiaryMenu(false);
  }, [addStackScreen]);

  const onClickMoreMenu = useCallback(() => {
    setBottomToastDiaryMenu((v) => !v);
  }, []);

  const [zoomViewUrl, setZoomViewUrl] = useState('');

  const showZoomView = (url: string) => {
    setZoomViewUrl(url);
  };

  const hideZoomView = useCallback(() => {
    setZoomViewUrl('');
  }, []);

  const {
    fetchMainDiaries,
    fetchDiaryInfo,
    fetchDiaryDetail,
    initDiaryInfo,
    initDiaryDetail,
  } = useFetchData();

  const [commentInputView, setCommentInputView] = useState(false);
  const [bottomToastCommentMenu, setBottomToastCommentMenu] = useState(false);
  const [bottomToastDiaryMenu, setBottomToastDiaryMenu] = useState(false);
  const [modalConfirmView, setModalConfirmView] = useState(false);
  const [commentWriteStatus, setCommentWriteStatus] = useState<'post' | 'edit'>(
    'post',
  );

  const onClickCommentEdit = useCallback(() => {
    setBottomToastCommentMenu(false);

    if (selectedComment?.type === 'answer')
      addStackScreen('StackEditDiaryAnswer');
    if (selectedComment?.type === 'comment') {
      setCommentWriteStatus('edit');
      setCommentInputView(true);
    }
  }, [selectedComment, addStackScreen]);

  const onClickCommentDelete = useCallback(() => {
    setBottomToastCommentMenu(false);
    setModalConfirmView(true);
  }, []);

  const onDeleteComment = useCallback(async () => {
    try {
      const { data } = await axios.delete(`/comments/${selectedComment?.id}`);
      if (data.success) {
        if (selectedComment && selectedComment.id !== null)
          dispatch(commonActions.deleteCommentOnDiary(selectedComment?.id));
        setModalConfirmView(false);
        fetchMainDiaries();
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedComment]);

  const [currentScroll, setCurrentScroll] = useState(0);
  const onChangeScrollTop = (e: any) => {
    setCurrentScroll(e.target.scrollTop + window.innerHeight);
  };

  const initQuestionListDate = () => {
    dispatch(
      homeActions.setValue('questionListDate', {
        year: null,
        month: null,
      }),
    );
  };

  const initSelectedDiaryDate = () => {
    dispatch(commonActions.setValue('selectedDiaryDate', null));
  };

  useEffect(() => {
    setCurrentScroll(window.innerHeight);

    return () => {
      initQuestionListDate();
      initSelectedDiaryDate();
      dispatch(commonActions.setValue('selectedComment', null));
    };
  }, []);

  useEffect(() => {
    fetchDiaryInfo();
    fetchDiaryDetail();

    return () => {
      initDiaryInfo();
      initDiaryDetail();
    };
  }, [selectedDiaryId, selectedDiaryDate]);

  const onClickHurry = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `/diaries/${selectedDiaryId}/questions/${selectedDiaryDetail.data?.question.id}/hurry`,
      );
      if (data.success) {
        dispatch(
          commonActions.setValue('alertMessage', '재촉 메시지를 보냈어요!'),
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedDiaryId, selectedDiaryDetail]);

  const onToggleScrap = async (questionId: number | null) => {
    const method =
      selectedDiaryDetail.data?.question.scrapYn === 'Y' ? 'put' : 'post';
    const parameter =
      selectedDiaryDetail.data?.question.scrapYn === 'Y'
        ? { scrapYn: 'N' }
        : null;

    try {
      const { data } = await axios({
        method,
        url: `/diaries/${selectedDiaryId}/questions/${questionId}/scraps`,
        data: parameter,
      });
      if (data.success) {
        dispatch(commonActions.questionScrapToggle());
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      {zoomViewUrl && (
        <ZoomView url={zoomViewUrl} hideZoomView={hideZoomView} />
      )}
      <StackHeader
        title={
          selectedDiaryInfo.data
            ? `${selectedDiaryInfo.data.name}(${selectedDiaryInfo.data.members.length})`
            : ''
        }
        btnRight={
          <BtnMoreMenu>
            <button type="button" onClick={onClickMoreMenu}>
              <img src={iconMoreMenu} alt="더보기 메뉴 버튼" />
            </button>
          </BtnMoreMenu>
        }
      />
      {selectedDiaryDetail?.loading && (
        <Content>
          <LoadingContent />
        </Content>
      )}
      {selectedDiaryDetail?.data && (
        <Content onScroll={onChangeScrollTop} ref={scrollContentRef}>
          <div className="content-wrap inner-wrap">
            <section className="top-section">
              <p className="question-num">
                Q.{selectedDiaryDetail.data.question?.number}
              </p>
              <div className="question-wrap">
                <p className="question">
                  {selectedDiaryDetail.data.question?.content}
                  <img
                    className="icon-quotes-left"
                    src={iconQuotesLeft}
                    alt="따옴표"
                  />
                  <img
                    className="icon-quotes-right"
                    src={iconQuotesRight}
                    alt="따옴표"
                  />
                </p>
              </div>
              <BtnScrap
                active={selectedDiaryDetail.data.question?.scrapYn === 'Y'}
                className="btn-scrap"
                type="button"
                onClick={() =>
                  onToggleScrap(
                    selectedDiaryDetail.data
                      ? selectedDiaryDetail.data.question.id
                      : null,
                  )
                }
              >
                <span className="icon-scrap" />
              </BtnScrap>
            </section>
            <section className="diary-content">
              {selectedDiaryDetail.data.answers.length === 0 && (
                <NoCommentList>
                  <img src={commentBubbleGray} alt="말풍선 이미지" />
                  {selectedDiaryInfo?.data?.group === '나' && (
                    <p>아직 답변을 작성하지 않았어요!</p>
                  )}
                  {selectedDiaryInfo?.data?.group !== '나' && (
                    <p>아직 답변을 작성한 멤버가 없어요!</p>
                  )}
                </NoCommentList>
              )}
              {selectedDiaryDetail.data.answers.length > 0 && (
                <CommentContext.Provider value={{ showZoomView }}>
                  <CommentList
                    listType="diary"
                    isOpen={selectedDiaryDetail.data.isOpen}
                    isAnswered={selectedDiaryDetail.data.isAnswered}
                    commentList={selectedDiaryDetail.data.answers}
                    currentScroll={currentScroll}
                    setCommentInputView={setCommentInputView}
                    setBottomToastModalView={setBottomToastCommentMenu}
                  />
                </CommentContext.Provider>
              )}
            </section>
          </div>
          {selectedDiaryInfo?.data?.group !== '나' &&
            selectedDiaryDetail.data.isAnswered &&
            // selectedDiaryDetail.data.isOpen &&
            selectedDiaryDetail.data?.noAnsweredMembers?.length > 0 &&
            !commentInputView && (
              <NoAnswerMembers safeAreaBottom={safeArea.bottom}>
                <p className="title">
                  미답변 구성원
                  <span className="count">
                    ({selectedDiaryDetail.data?.noAnsweredMembers?.length})
                  </span>
                </p>
                <div className="inner">
                  <UserThumbList
                    userList={selectedDiaryDetail.data?.noAnsweredMembers}
                  />
                  <BtnHurry onClick={onClickHurry}>
                    <img src={iconClock} alt="시계 아이콘" />
                    재촉하기
                  </BtnHurry>
                </div>
              </NoAnswerMembers>
            )}
          {!selectedDiaryDetail.data.isAnswered &&
            selectedDiaryInfo?.data?.status === 'P' && (
              <BtnWriteComment>
                <Button
                  className="btn-write-comment"
                  style={{ width: '120px' }}
                  size="large"
                  round
                  onClick={() => addStackScreen('StackWriteDiaryAnswer')}
                >
                  답변 쓰기
                </Button>
              </BtnWriteComment>
            )}

          {commentInputView && (
            <WriteCommentInput
              isDiaryComment
              // content={selectedComment?.content || ''}
              commentWriteStatus={commentWriteStatus}
              setCommentWriteStatus={setCommentWriteStatus}
              setCommentInputView={setCommentInputView}
            />
          )}
        </Content>
      )}

      {bottomToastCommentMenu && (
        <BottomToastModal
          visible={bottomToastCommentMenu}
          hideToastModal={() => setBottomToastCommentMenu(false)}
        >
          <CommentBtnMenu>
            <button type="button" onClick={onClickCommentEdit}>
              수정하기
            </button>
            <button type="button" onClick={onClickCommentDelete}>
              삭제하기
            </button>
          </CommentBtnMenu>
        </BottomToastModal>
      )}

      {bottomToastDiaryMenu && (
        <BottomToastModal
          visible={bottomToastDiaryMenu}
          hideToastModal={() => setBottomToastDiaryMenu(false)}
        >
          <CommentBtnMenu>
            <button type="button" onClick={onClickListView}>
              리스트 보기
            </button>
            <button type="button" onClick={onClickDiarySetting}>
              다이어리 설정
            </button>
          </CommentBtnMenu>
        </BottomToastModal>
      )}

      <ModalConfirm
        visible={modalConfirmView}
        title="확인"
        description="정말 삭제 하시겠어요?"
        btnCancel="취소"
        btnCancelClick={() => setModalConfirmView(false)}
        btnConfirm="삭제"
        btnConfirmClick={onDeleteComment}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 40px;
  display: flex;
  flex-direction: column;

  .content-wrap {
    flex: 1;
  }

  .top-section {
    padding: 40px 0 80px;
    position: relative;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray2};

    .question-num {
      display: inline-block;
      font-weight: 700;
      color: ${({ theme }) => theme.color.main1};
      position: relative;
      font-family: 'NanumSquare';
      margin-bottom: 30px;

      &:after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: ${({ theme }) => theme.color.main2};
        position: absolute;
        bottom: 4px;
        left: 0;
        z-index: -1;
      }
    }

    .question-wrap {
      .question {
        display: inline-block;
        max-width: 80%;
        font-size: 1.8rem;
        font-weight: 300;
        position: relative;

        .icon-quotes-left {
          width: 10px;
          position: absolute;
          top: 0;
          left: -18px;
        }
        .icon-quotes-right {
          width: 10px;
          position: absolute;
          top: 0;
          right: -18px;
        }
      }
    }

    .btn-scrap {
      position: absolute;
      bottom: 14px;
      right: 0;
    }
  }

  .diary-content {
    padding: 30px 0;
  }
`;

const BtnScrap = styled.button<{ active: boolean }>`
  width: 24px;
  height: 23px;
  background-image: url(${iconScrap});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 24px;

  ${({ active }) =>
    active &&
    css`
      background-position-y: -24px;
    `}
`;

const BtnMoreMenu = styled.div`
  position: relative;

  .menu {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 2;
    width: 104px;
    padding: 4px 0;

    li {
      padding: 6px 14px;
      font-size: 1.2rem;
    }
  }
`;

const NoCommentList = styled.div`
  text-align: center;
  img {
    width: 90px;
    margin-bottom: 10px;
  }
  p {
    color: 1.3rem;
    color: ${({ theme }) => theme.color.gray2};
  }
`;

const NoAnswerMembers = styled.div<{ safeAreaBottom: number }>`
  text-align: left;
  margin-top: 40px;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding: ${({ safeAreaBottom }) => `15px 15px ${safeAreaBottom + 10}px`};
  z-index: 2;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;

    .count {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
      margin-left: 4px;
    }
  }
`;

const BtnHurry = styled(Button).attrs({
  size: 'small',
  outline: 'main',
  round: true,
})`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    margin-right: 8px;
  }
`;

const BtnWriteComment = styled.div`
  position: sticky;
  bottom: 40px;
  left: 0;
  z-index: 10;
  width: 100%;
  text-align: center;

  .btn-write-comment {
    box-shadow: 0 0 8px rgba(185, 0, 0, 0.3);
  }
`;

const CommentBtnMenu = styled.div`
  button {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 1.6rem;
  }
`;

export default StackDiaryDetail;

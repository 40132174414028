import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';
import useAxiosConfiguration from '../../hooks/useAxiosConfiguration';
import useGlobalFunctions from '../../hooks/useGlobalFunctions';
import { RootState } from '../../store';
import useNavigator from '../../hooks/useNavigator';
import useMyProfile from '../../hooks/useMyProfile';
import { isAmorApp } from '../../utils/webUtil';
import useTimeoffset from '../../hooks/useTimeoffset';

function AxiosSettings(): JSX.Element {
  // const { myProfile } = useSelector((state: RootState) => state.common);
  const { stackScreens, isTutorialShow } = useSelector(
    (state: RootState) => state.common,
  );
  const { fetchMyProfile } = useMyProfile();
  const { putMyTimezone } = useTimeoffset();
  const [showNavigator, hideNavigator] = useNavigator();
  const history = useHistory();

  const currentUrl = window?.location.href;

  // const getSafeArea = () => {
  //   try {
  //     window?.flutter_inappwebview
  //       ?.callHandler('getSafeArea')
  //       .then((res: any) => {
  //         alert(`get area top : ${res.top}`);
  //         alert(`get area bottom : ${res.bottom}`);
  //       });
  //   } catch (e: any) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   if (window?.flutter_inappwebview) getSafeArea();
  // }, [window?.flutter_inappwebview]);

  useEffect(() => {
    if (
      window?.flutter_inappwebview &&
      history.location.pathname.indexOf('login') >= 0
    ) {
      hideNavigator();
    }
  }, [window?.flutter_inappwebview]);

  useEffect(() => {
    if (
      (stackScreens && stackScreens.length > 0) ||
      history.location.pathname.indexOf('login') >= 0
    ) {
      hideNavigator();
    } else {
      const isJoined = localStorage.getItem('isJoined');
      if (isJoined) showNavigator();
    }
  }, [stackScreens, history.location.pathname]);

  const query = queryString.parse(history.location.search);

  // console.log("query", query)
  // alert(history.location.search);
  let isApp = true;
  if (query && query.app && query.app === 'amor') {
    localStorage.setItem('app', 'amor');
    isApp = true;
  } else if (isAmorApp()) {
    isApp = true;
  } else {
    localStorage.setItem('app', '');
    isApp = false;
  }

  // axios common header setting
  useGlobalFunctions();

  if (history.location.pathname.indexOf('login') >= 0) {
    return <></>;
  }

  if (history.location.pathname.indexOf('privacy-policy') >= 0) {
    return <></>;
  }

  if (history.location.pathname.indexOf('gateway') >= 0) {
    return <></>;
  }

  const queryToken = query && query.token;
  let token = '';
  if (queryToken) {
    if (typeof queryToken === 'string') {
      localStorage.setItem('accessToken', decodeURIComponent(queryToken));
      token = queryToken;
    }
  } else {
    token = localStorage.getItem('accessToken') || '';
    token = token.toString();
  }

  if (!token || token === '') {
    window.location.href = '/login';
    return <></>;
  }

  useAxiosConfiguration(history, token);

  useEffect(() => {
    fetchMyProfile();
    putMyTimezone();
  }, []);

  return <></>;
}

export default AxiosSettings;

import React from 'react';
import styled from 'styled-components';
import { btnClose, checkBig } from '../../../assets/images';

interface IProps {
  onClickClose: () => void;
  message: JSX.Element;
  caution?: JSX.Element;
}

function CompleteMessageLayer({
  onClickClose,
  message,
  caution,
}: IProps): JSX.Element {
  return (
    <Container>
      <button className="btn-close" type="button" onClick={onClickClose}>
        <img src={btnClose} alt="닫기 버튼" />
      </button>
      <div className="content">
        <img src={checkBig} alt="확인 아이콘" />
        <div>{message}</div>

        <div className="caution">{caution}</div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    position: relative;

    img {
      width: 60px;
      margin-bottom: 25px;
    }

    .caution {
      position: absolute;
      left: 50%;
      bottom: -150px;
      width: 100%;
      transform: translate(-50%, 0);

      p {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.color.main1};
        font-family: 'NanumSquare';
      }
    }
  }

  .btn-close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      width: 24px;
    }
  }
`;

export default CompleteMessageLayer;

import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  sampleImage1,
  sampleProfile1,
  sampleProfile2,
  sampleProfile3,
} from '../../../assets/images';
import {
  iconCameraWhiteCircle,
  iconExit,
  iconManager,
  iconRemove,
} from '../../../assets/images/icon';
import useFetchData from '../../../hooks/useFetchData';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { makeFormData } from '../../../utils/makeFormData';
import Button from '../../parts/Button';
import Checkbox from '../../parts/Checkbox';
import Input from '../../parts/Input';
import BottomToastModal from '../BottomToastModal';
import CompleteMessageLayer from '../CompleteMessageLayer';
import { actions as scrapActions } from '../../../store/scrap';
import { receiveTimeToLocal } from '../../../utils/AmorDateUtil';

interface IQuitDiaryDate {
  period: string | null;
  days: number | null;
}
interface FormDataTypes {
  cover: {
    id: number | null;
    file: File | null;
    isChanged: boolean;
    isDeleted: '0';
    type: 'image' | 'video' | 'audio';
    url: string;
  };
  name: string;
}

function StackDiarySetting(): JSX.Element {
  const { myProfile, selectedDiaryInfo, selectedDiaryId } = useSelector(
    (state: RootState) => ({
      myProfile: state.common.myProfile,
      selectedDiaryInfo: state.common.selectedDiaryInfo,
      selectedDiaryId: state.common.selectedDiaryId,
    }),
    shallowEqual,
  );
  const [isDiaryOwner, setIsDiaryOwner] = useState(
    myProfile?.id === selectedDiaryInfo.data?.ownerId,
  );

  const { fetchMainDiaries, fetchDiaryInfo } = useFetchData();
  const { replaceStackScreen, removeStackScreen, clearStackScreen } =
    useStack();
  const [formData, setFormData] = useState<FormDataTypes>({
    cover: {
      id: selectedDiaryInfo?.data?.cover.id || null,
      file: null,
      isChanged: false,
      isDeleted: '0',
      type: 'image',
      url: selectedDiaryInfo?.data?.cover.url || '',
    },
    name: selectedDiaryInfo?.data?.name || '',
  });

  const onChangeInput = useCallback(
    (e) => {
      setFormData({
        ...formData,
        name: e.target.value,
      });
    },
    [formData],
  );

  const [quitDiaryToastView, setQuitDiaryToastView] = useState(false);
  const [deleteDiaryToastView, setDeleteDiaryToastView] = useState(false);
  const [checkQuitDiary, setCheckQuitDiary] = useState(false);
  const [completeMessageView, setCompleteMessageView] = useState(false);
  const [quitDiaryDate, setQuitDiaryDate] = useState<IQuitDiaryDate>({
    period: null,
    days: null,
  });

  const onChangeCheckbox = useCallback(() => {
    setCheckQuitDiary((v) => !v);
  }, []);

  const dispatch = useDispatch();

  const fetchScrapMyDiary = async () => {
    dispatch(scrapActions.setScrapMyDiary());

    const url = `/scraps/questions`;

    try {
      const { data } = await axios.get(url, {
        params: {
          category: null,
          month: 3,
          order: 'desc',
          length: 5,
        },
      });
      if (data.success) {
        console.log('my diary list : ', data.list);
        dispatch(
          scrapActions.setScrapMyDiarySuccess(data.list, data.nextPageUrl),
        );
      }
    } catch (e) {
      dispatch(scrapActions.setScrapMyDiaryError(e));
    }
  };

  const onSubmitQuitDiary = useCallback(async () => {
    if (!checkQuitDiary) return;

    let url = `/diaries/${selectedDiaryId}/left`;
    if (isDiaryOwner) url = `/diaries/${selectedDiaryId}`;

    try {
      const { data } = await axios.delete(url);
      console.log('quit diary : ', data);
      if (data.success) {
        setQuitDiaryDate({
          period: data.period,
          days: data.days,
        });
        setQuitDiaryToastView(false);
        setCompleteMessageView(true);
        fetchMainDiaries('init');
      }
    } catch (e) {
      console.log(e);
    }
  }, [checkQuitDiary, selectedDiaryId, isDiaryOwner]);

  const onSubmitDeleteDiary = useCallback(async () => {
    if (!checkQuitDiary) return;

    const url = `/diaries/${selectedDiaryId}/delete`;

    try {
      const { data } = await axios.delete(url);
      if (data.success) {
        setDeleteDiaryToastView(false);
        setCompleteMessageView(true);
        fetchScrapMyDiary();
      }
    } catch (e) {
      console.log(e);
    }
  }, [checkQuitDiary, selectedDiaryId, isDiaryOwner]);

  const onCloseToastModal = useCallback(() => {
    setCheckQuitDiary(false);
    setQuitDiaryToastView(false);
    setDeleteDiaryToastView(false);
  }, []);

  const onChangeCoverImage = useCallback(
    (e) => {
      const file = e.target.files[0];
      let url = null;
      if (file && file.type.match(/^image\/(png|jpeg)$/)) {
        url = URL.createObjectURL(file);
        setFormData({
          ...formData,
          cover: {
            ...formData.cover,
            file,
            isChanged: true,
            url,
          },
        });
      } else {
        // setErrorMessage('이미지 파일만 등록할 수 있습니다.');
        // return;
      }
    },
    [formData],
  );

  const onSaveDiarySetting = useCallback(async () => {
    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append(
      'cover[id]',
      formData.cover.id ? formData.cover.id.toString() : '',
    );
    fd.append('cover[file]', formData.cover.file || '');
    fd.append('cover[isChanged]', formData.cover.isChanged ? '1' : '0');
    fd.append('cover[isDeleted]', formData.cover.isDeleted);
    fd.append('cover[type]', formData.cover.type);
    fd.append('name', formData.name);
    try {
      const { data } = await axios.post(`/diaries/${selectedDiaryId}`, fd);
      if (data.success) {
        console.log('success');
        fetchDiaryInfo();
        fetchMainDiaries();
        removeStackScreen();
      }
    } catch (e) {
      console.log(e);
    }
  }, [formData, selectedDiaryId, fetchDiaryInfo]);

  return (
    <Container>
      <StackHeader
        title="다이어리 설정"
        btnRight={
          isDiaryOwner ? (
            <BtnSave
              type="button"
              active={formData.name.length > 0}
              onClick={onSaveDiarySetting}
            >
              SAVE
            </BtnSave>
          ) : (
            <></>
          )
        }
      />
      <Content>
        <div className="inner-wrap">
          <ImageUpload
            preview={formData.cover.url}
            style={{ marginBottom: '15px' }}
          >
            <input
              type="file"
              accept="image/*"
              disabled={!isDiaryOwner}
              onChange={onChangeCoverImage}
            />
            {isDiaryOwner && (
              <img
                className="icon-camera"
                src={iconCameraWhiteCircle}
                alt="카메라 아이콘"
              />
            )}
          </ImageUpload>
          <div className="category">
            <span>{selectedDiaryInfo.data?.group}</span>
          </div>
          <div className="input-wrap">
            <Input
              type="text"
              style={{ textAlign: 'center', margin: '15px 0' }}
              value={formData.name}
              onChange={onChangeInput}
              disabled={!isDiaryOwner}
              error={formData.name.length === 0}
            />
            {formData.name.length === 0 && (
              <p className="error-message">다이어리 이름을 입력해주세요.</p>
            )}
          </div>
          <div className="info">
            <div className="row">
              <span className="label">
                {selectedDiaryInfo.data?.status === 'C' ? '사용기간' : '시작일'}
              </span>
              <span className="value">{selectedDiaryInfo.data?.date}</span>
            </div>
            <div className="row">
              <span className="label">질문 받는 시간</span>
              <span className="value">
                {receiveTimeToLocal(
                  selectedDiaryInfo.data?.receiveTime || null,
                )}
              </span>
            </div>
            <div className="members">
              <div className="label">
                <p className="title">참여 멤버</p>
                <p className="divider" />
              </div>
              <UserList>
                {selectedDiaryInfo.data?.members.map((member) => (
                  <div className="user" key={member.id}>
                    <div
                      className="thumb"
                      style={{ backgroundImage: `url(${member.profileUrl})` }}
                    >
                      {member.isOwner && (
                        <img src={iconManager} alt="방장 아이콘" />
                      )}
                    </div>
                    <p className="name">{member.nickname}</p>
                  </div>
                ))}
              </UserList>
            </div>
          </div>
        </div>
        <div className="bottom">
          {selectedDiaryInfo.data?.status === 'C' && (
            <button type="button" onClick={() => setDeleteDiaryToastView(true)}>
              <img src={iconRemove} alt="삭제하기 아이콘" />
              <span>삭제하기</span>
            </button>
          )}
          {selectedDiaryInfo.data?.status !== 'C' && (
            <button type="button" onClick={() => setQuitDiaryToastView(true)}>
              <img src={iconExit} alt="그만하기 아이콘" />
              <span>그만하기</span>
            </button>
          )}
        </div>
      </Content>

      {completeMessageView && (
        <CompleteMessageLayer
          onClickClose={clearStackScreen}
          message={
            selectedDiaryInfo.data?.status !== 'C' ? (
              <p>
                다이어리가 종료되었어요
                <br /> 새로운 추억이 생겼네요!
              </p>
            ) : (
              <p>다이어리가 삭제되었어요</p>
            )
          }
          caution={
            selectedDiaryInfo.data?.status !== 'C' ? (
              <p>
                {quitDiaryDate.period}
                <br /> {quitDiaryDate.days}일간 기록되었습니다.
              </p>
            ) : (
              <></>
            )
          }
        />
      )}

      {quitDiaryToastView && (
        <BottomToastModal
          visible={quitDiaryToastView}
          hideToastModal={onCloseToastModal}
        >
          <QuitDiaryForm>
            <p className="title">정말 그만하시려고요?</p>
            <p className="caution">
              {isDiaryOwner &&
                '* 모든 멤버가 더 이상 다이어리를 쓰지 못하고 종료됩니다.'}
              {!isDiaryOwner && '* 더 이상 다이어리를 쓰지 못하고 종료됩니다.'}
            </p>
            <div className="checkbox-wrap">
              <Checkbox
                checked={checkQuitDiary}
                label="진심이예요"
                onChange={onChangeCheckbox}
              />
            </div>
            <Button
              fullWidth
              color={checkQuitDiary ? 'main' : 'gray'}
              onClick={onSubmitQuitDiary}
            >
              그만하기
            </Button>
            <div className="btn-close">
              <button type="button" onClick={onCloseToastModal}>
                닫기
              </button>
            </div>
          </QuitDiaryForm>
        </BottomToastModal>
      )}

      {deleteDiaryToastView && (
        <BottomToastModal
          visible={deleteDiaryToastView}
          hideToastModal={onCloseToastModal}
        >
          <QuitDiaryForm>
            <p className="title">정말 삭제할까요?</p>
            <p className="caution">
              * 더 이상 다이어리를 쓰지 못하고 삭제됩니다.
            </p>
            <div className="checkbox-wrap">
              <Checkbox
                checked={checkQuitDiary}
                label="진심이예요"
                onChange={onChangeCheckbox}
              />
            </div>
            <Button
              fullWidth
              color={checkQuitDiary ? 'main' : 'gray'}
              onClick={onSubmitDeleteDiary}
            >
              삭제하기
            </Button>
            <div className="btn-close">
              <button type="button" onClick={onCloseToastModal}>
                닫기
              </button>
            </div>
          </QuitDiaryForm>
        </BottomToastModal>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BtnSave = styled.button<{ active: boolean }>`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.color.main1 : theme.color.gray1};
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;

  .category {
    text-align: center;

    span {
      display: inline-block;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
      padding: 4px 14px;
      border-radius: 15px;
      background-color: ${({ theme }) => theme.color.gray3};
    }
  }

  .input-wrap {
    position: relative;
    margin-bottom: 20px;

    .error-message {
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 100%;
      color: ${({ theme }) => theme.color.main1};
      font-size: 1.2rem;
    }
  }

  .info {
    margin-bottom: 30px;

    .row {
      display: flex;
      justify-content: space-between;
      padding: 14px 0;

      .label {
        font-weight: 700;
      }
      .value {
        color: ${({ theme }) => theme.color.gray1};
        font-family: 'NanumSquare';
        font-weight: 400;
      }
    }

    .members {
      margin-top: 12px;

      .label {
        height: 16px;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .title {
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          padding-right: 15px;
          font-weight: 700;
        }

        .divider {
          width: 100%;
          height: 1px;
          border-top: 1px dashed ${({ theme }) => theme.color.gray2};
        }
      }
    }
  }

  .bottom {
    border-top: 1px solid ${({ theme }) => theme.color.gray3};
    padding: 15px;

    button {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        margin-right: 8px;
      }
      span {
        color: ${({ theme }) => theme.color.gray1};
      }
    }
  }
`;

const UserList = styled.div`
  .user {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .thumb {
      position: relative;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-right: 8px;

      .icon-manager {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
      }

      img {
        width: 14px;
      }
    }

    .name {
      flex: 1;
      color: ${({ theme }) => theme.color.gray2};
    }
  }
`;

const ImageUpload = styled.label<{ preview: string }>`
  display: block;
  width: 194px;
  height: 284px;
  margin: 0 auto;
  border-radius: 16px;
  background-image: url(${({ preview }) => preview || ''});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;

  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .icon-camera {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 36px;
  }
`;

const QuitDiaryForm = styled.div`
  text-align: center;

  .title {
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .caution {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.main1};
  }

  .checkbox-wrap {
    margin: 40px 0 30px;
  }

  .btn-close {
    button {
      padding: 15px 0;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
    }
  }
`;

export default StackDiarySetting;

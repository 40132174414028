import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from '../../../store';
import NoList from '../../common/NoList';
import Button from '../../parts/Button';
import ScrapItem from '../ScrapItem';
import { loading } from '../../../assets/images';
import ScrapListLoading from '../ScrapListLoading';

interface IProps {
  fetchScrapList: () => void;
}

function MyDiary({ fetchScrapList }: IProps): JSX.Element {
  const { scrapMyDiary } = useSelector((state: RootState) => state.scrap);

  return (
    <Container>
      {scrapMyDiary.loading && <ScrapListLoading />}
      {!scrapMyDiary.loading && scrapMyDiary.data?.list.length === 0 && (
        <NoList ment="스크랩한 질문이 없어요" />
      )}
      {!scrapMyDiary.loading &&
        scrapMyDiary.data &&
        scrapMyDiary.data.list.length > 0 && (
          <div className="list-content">
            <InfiniteScroll
              dataLength={scrapMyDiary.data.list.length}
              next={fetchScrapList}
              hasMore={!!scrapMyDiary.data.nextPageUrl}
              loader={
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={loading}
                    alt="로딩 이미지"
                    style={{ width: '80px' }}
                  />
                </div>
              }
              scrollableTarget="ScrapScrollWrap"
            >
              {scrapMyDiary.data.list.map((item, index) => (
                <ScrapItem
                  key={item.questionId}
                  index={index}
                  type="myDiary"
                  name={item.diaryName}
                  createdAt={item.createdAt}
                  category={item.groupName}
                  question={item.content}
                  cover={item.coverUrl}
                  diaryId={item.diaryId}
                  questionId={item.questionId}
                  scrapYn={item.scrapYn}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default MyDiary;

import React from 'react';
import styled from 'styled-components';
import Checkbox from '../../parts/Checkbox';

interface IProps {
  id: number;
  status: 'N' | 'A' | 'R' | 'C';
  categoryName: '나' | '가족' | '연인' | '친구';
  name?: string;
  cover: string;
  selected?: boolean;
  className?: string;
  onToggleDiaryCheck: (id: number) => void;
}

function SelectableDiary({
  id,
  status,
  categoryName,
  name,
  cover,
  selected,
  className,
  onToggleDiaryCheck,
}: IProps): JSX.Element {
  return (
    <Container cover={cover} className={className}>
      <div className="item">
        {(status === 'N' || status === 'R') && (
          <label className="check-label" htmlFor={`diary-check-${id}`}>
            <div className="dim bottom">
              <p className="category">{categoryName}</p>
              <p className="name">{name}</p>
            </div>
            <Checkbox
              id={`diary-check-${id}`}
              className={`check-box ${selected ? 'active' : ''}`}
              name="diary-select"
              checked={selected || false}
              onChange={() => onToggleDiaryCheck(id)}
            />
          </label>
        )}
        {(status === 'A' || status === 'C') && (
          <div className="dim">
            <p className="ment">
              {status === 'A' && '적용 대기 중인 다이어리입니다.'}
              {status === 'C' && '이 질문이 이미 발송된 다이어리입니다.'}
            </p>
          </div>
        )}
      </div>
    </Container>
  );
}

const Container = styled.div<{ cover: string }>`
  display: inline-block;
  width: 50%;
  margin-bottom: 20px;

  .item {
    height: 200px;
    background-image: url(${({ cover }) => cover});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .check-label {
      display: block;
      width: 100%;
      height: 100%;
    }

    .dim {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7539390756302521) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;

      &.bottom {
        top: auto;
        right: auto;
        height: 50%;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.7539390756302521) 0%,
          rgba(255, 255, 255, 0) 50%
        );
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
      }

      .category {
        display: inline-block;
        padding: 1px 6px;
        border-radius: 20px;
        border: 1px solid #eee;
        color: #eee;
        font-size: 1rem;
        margin-bottom: 4px;
      }

      .name {
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 12px;
      }

      .ment {
        width: 70%;
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
      }
    }

    .check-box {
      position: absolute;
      top: 10px;
      right: 10px;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }
`;

export default SelectableDiary;

import { useEffect, useState } from 'react';

type recorderTypes = [
  audioURL: string,
  isRecording: boolean,
  startRecording: () => void,
  stopRecording: () => void,
  initRecorder: () => void,
  audioFile: any,
];

const useRecorder = (): recorderTypes => {
  const [audioURL, setAudioURL] = useState<string>('');
  const [audioFile, setAudioFile] = useState<any>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = (e: BlobEvent) => {
      setAudioURL(URL.createObjectURL(e.data));
      setAudioFile(e.data);
    };

    recorder.addEventListener('dataavailable', handleData);
    return () => recorder.removeEventListener('dataavailable', handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const initRecorder = () => {
    setAudioURL('');
    setAudioFile(null);
    setIsRecording(false);
    setRecorder(null);
  };

  return [
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    initRecorder,
    audioFile,
  ];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  console.log(stream);
  return new MediaRecorder(stream);
}
export default useRecorder;

import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Draggable from 'react-draggable';
import { shallowEqual, useSelector } from 'react-redux';
import useNavigator from '../../../hooks/useNavigator';
import { RootState } from '../../../store';

type DraggableData = {
  node: HTMLElement;
  x: number;
  y: number;
  deltaX: number;
  deltaY: number;
  lastX: number;
  lastY: number;
};
interface IProps {
  visible: boolean;
  hideToastModal: () => void;
  children?: JSX.Element;
}

function BottomToastModal({
  visible,
  hideToastModal,
  children,
}: IProps): JSX.Element {
  const [localVisible, setLocalVisible] = useState(false);
  const [showNavigator, hideNavigator] = useNavigator();
  const { stackScreens, safeArea, visibleSideMenu, isTutorialShow } =
    useSelector(
      (state: RootState) => ({
        stackScreens: state.common.stackScreens,
        safeArea: state.common.safeArea,
        visibleSideMenu: state.common.visibleSideMenu,
        isTutorialShow: state.common.isTutorialShow,
      }),
      shallowEqual,
    );

  useEffect(() => {
    hideNavigator();
    return () => {
      if (!stackScreens || (stackScreens.length === 0 && !visibleSideMenu)) {
        showNavigator();
      }
    };
  }, []);

  useEffect(() => {
    if (visible) {
      setLocalVisible(true);
    } else {
      setTimeout(() => {
        setLocalVisible(false);
      }, 200);
    }
  }, [visible]);

  const nodeRef = useRef(null);
  const [toastPosition, setToastPosition] = useState({ x: 0, y: 0 });

  const dragToast = (data: DraggableData) => {
    if (data.y > 50) {
      setToastPosition({ x: 0, y: 300 });
      hideToastModal();
    } else {
      setToastPosition({ x: 0, y: 0 });
    }
  };

  return (
    <Container visible={visible} localVisible={localVisible}>
      <Draggable
        nodeRef={nodeRef}
        handle=".control-wrap"
        onDrag={() => console.log('drag')}
        onStop={(e, data) => dragToast(data)}
        position={toastPosition}
        axis="y"
        bounds={{ top: 0 }}
      >
        <Layer
          visible={visible}
          localVisible={localVisible}
          safeAreaBottom={safeArea.bottom}
          ref={nodeRef}
        >
          <div className="inner-wrap">
            <div className="control-wrap">
              <div className="control" />
            </div>
            {children}
          </div>
        </Layer>
      </Draggable>
      <Dim onClick={hideToastModal} />
    </Container>
  );
}

const slideUp = keyframes`
  from {
    bottom: -100%;
  } to {
    bottom: 0;
  }
`;
const slideDown = keyframes`
  from {
    bottom: 0
  } to {
    bottom: -100%;
  }
`;

const Container = styled.div<{ visible: boolean; localVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  text-align: center;

  ${({ localVisible }) =>
    !localVisible &&
    css`
      display: none;
    `}

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}
`;

const Layer = styled.div<{
  visible: boolean;
  localVisible: boolean;
  safeAreaBottom: number;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-bottom: ${({ safeAreaBottom }) => `${safeAreaBottom + 20}px`};
  background-color: #fff;
  border-radius: 16px 16px 0 0;

  ${({ visible }) =>
    visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideUp};
      animation-fill-mode: forwards;
    `}

  ${({ visible, localVisible }) =>
    !visible &&
    localVisible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideDown};
      animation-fill-mode: forwards;
    `}

  .control-wrap {
    padding: 5px 0 15px;
    text-align: center;

    .control {
      display: inline-block;
      width: 40px;
      height: 4px;
      background-color: ${({ theme }) => theme.color.gray2};
      border-radius: 2px;
    }
  }
`;

const Dim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default BottomToastModal;

import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { OpenSapceState, OpenSpaceActions } from './types';
import {
  setValue,
  deleteCommentOnFeed,
  commentLikeToggleOnFeed,
  replyLikeToggleOnFeed, setOpenSpaceBestSuccess, setOpenSpaceNewSuccess,
} from './actions';

import {
  asyncState,
} from '../../utils/reducerUtils';

const initialState: OpenSapceState = {
  openSpaceBest: asyncState.initial(null),
  openSpaceNew: asyncState.initial(null),
  openSpaceDetail: null,
};


const reducer = createReducer<OpenSapceState, OpenSpaceActions>(initialState)
  .handleAction(setValue, (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }))
  .handleAction(commentLikeToggleOnFeed, (state, action) => {
    return produce(state, (draft) => {
      const targetComment =
        draft.openSpaceDetail?.comments[action.payload.index];
      if (targetComment) {
        if (targetComment.isLiked) {
          targetComment.likesCount -= 1;
          targetComment.isLiked = !targetComment.isLiked;
        } else {
          targetComment.likesCount += 1;
          targetComment.isLiked = !targetComment.isLiked;
        }
      }
    });
  })
  .handleAction(replyLikeToggleOnFeed, (state, action) => {
    return produce(state, (draft) => {
      const targetComment =
        draft.openSpaceDetail?.comments[action.payload.commentIndex].replies[
          action.payload.replyIndex
        ];
      if (targetComment) {
        if (targetComment.isLiked) {
          targetComment.likesCount -= 1;
          targetComment.isLiked = !targetComment.isLiked;
        } else {
          targetComment.likesCount += 1;
          targetComment.isLiked = !targetComment.isLiked;
        }
      }
    });
  })
  .handleAction(deleteCommentOnFeed, (state, action) => {
    return produce(state, (draft) => {
      const comments = draft.openSpaceDetail?.comments;
      const commentIndex = comments?.findIndex(
        (item) => item.id === action.payload.commentId,
      );
      if (commentIndex !== undefined) {
        if (commentIndex === -1) {
          comments?.forEach((item) => {
            const replyIndex = item.replies.findIndex(
              (reply) => reply.id === action.payload.commentId,
            );
            if (replyIndex !== -1) item.replies.splice(replyIndex, 1);
          });
        } else {
          comments?.splice(commentIndex, 1);
        }
      }
    });
  })
  // .handleAction(
  //   transformToArray(asyncSetOpenSpace),
  //   createAsyncReducer(asyncSetOpenSpace, 'openSpaces'),
  // )
  .handleAction(setOpenSpaceBestSuccess, (state, action) => {
    return produce(state, (draft) => {
      if (!draft.openSpaceBest.data)
        draft.openSpaceBest.data = {
          list: [],
          nextPageUrl: '',
        };
      draft.openSpaceBest.loading = false;
      if (action.payload.isAppend) {
        draft.openSpaceBest.data.list.push(...action.payload.list);
      } else {
        draft.openSpaceBest.data.list = action.payload.list;
      }
      draft.openSpaceBest.data.nextPageUrl = action.payload.nextPageUrl;
    });
  }).handleAction(setOpenSpaceNewSuccess, (state, action) => {
    return produce(state, (draft) => {
      if (!draft.openSpaceNew.data)
        draft.openSpaceNew.data = {
          list: [],
          nextPageUrl: '',
        };
      draft.openSpaceNew.loading = false;
      if (action.payload.isAppend) {
        draft.openSpaceNew.data.list.push(...action.payload.list);
      } else {
        draft.openSpaceNew.data.list = action.payload.list;
      }
      draft.openSpaceNew.data.nextPageUrl = action.payload.nextPageUrl;
    });
  })

export default reducer;

import axios, { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useInput from '../../../hooks/useInput';
import { asyncSetDiaries } from '../../../store/home';
import { actions as commonActions } from '../../../store/common';
import Button from '../../parts/Button';
import Input from '../../parts/Input';

interface IProps {
  hideInvitationCodeForm: () => void;
  hideSidemenu: () => void;
}

function InvitationCodeForm({
  hideInvitationCodeForm,
  hideSidemenu,
}: IProps): JSX.Element {
  const { value, onChangeText } = useInput();
  const dispatch = useDispatch();

  const onSubmitInvitationCode = useCallback(async () => {
    try {
      const { data } = await axios.post('/diaries/join', {
        code: value,
      });

      if (data.success) {
        hideSidemenu();
        hideInvitationCodeForm();
        dispatch(
          commonActions.setValue(
            'alertMessage',
            '새다이어리가 생성되었습니다.',
          ),
        );
        dispatch(asyncSetDiaries.request([]));
      }
    } catch (e: any) {
      const message = e.response.data.errors.message[0];
      dispatch(commonActions.setValue('alertMessage', message));
    }
  }, [value]);

  return (
    <Container>
      <p className="title">초대 코드 입력하기</p>
      <div className="input-wrap">
        <Input
          type="text"
          value={value}
          onChange={onChangeText}
          style={{ textAlign: 'center' }}
          placeholder="코드를 입력해주세요"
        />
        {/* <p className="error-message">* 이미 참여중인 다이어리 입니다.</p> */}
      </div>
      <Button
        color={value.length > 0 ? 'main' : 'gray'}
        fullWidth
        onClick={onSubmitInvitationCode}
      >
        확인
      </Button>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;

  .title {
    font-size: 1.6rem;
    margin-bottom: 30px;
  }

  .input-wrap {
    position: relative;
    margin-bottom: 50px;

    .error-message {
      position: absolute;
      left: 0;
      bottom: -22px;
      width: 100%;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.main1};
    }
  }
`;

export default InvitationCodeForm;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import useNavigator from '../../../hooks/useNavigator';
import { RootState } from '../../../store';
import StackContent from '../StackContent';

function StackLayer1(): JSX.Element | null {
  const { stackScreens } = useSelector((state: RootState) => state.common);
  const [LocalVisible, setLocalVisible] = useState(false);

  useEffect(() => {
    const visible: boolean = stackScreens.length > 0;

    if (visible && !LocalVisible) {
      setLocalVisible(true);
    }
    let body: HTMLBodyElement | null = null;
    body = document.querySelector('body');

    if (body) {
      if (visible) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'visible';
      }
    }
  }, [LocalVisible, stackScreens]);

  return LocalVisible ? (
    <StackContainer visible={stackScreens.length > 0}>
      <StackContent screenName={stackScreens[0]} />
    </StackContainer>
  ) : null;
}

const slideLeft = keyframes`
  from {
    transform:translateX(100%);
  } to {
    transform:translateX(0);
  }
`;
const slideRight = keyframes`
  from {
    transform:translateX(0);
  } to {
    transform:translateX(100%);
  }
`;

const StackContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;

  transform: translateX(100%);

  ${({ visible }: { visible: boolean }) =>
    visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideLeft};
      animation-fill-mode: forwards;
    `}

  ${({ visible }: { visible: boolean }) =>
    !visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideRight};
      animation-fill-mode: forwards;
    `}
`;

export default StackLayer1;

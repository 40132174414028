import { createAsyncAction, deprecated } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { IDiary } from './types';

const { createAction } = deprecated;

export const Types = {
  SET_VALUE: 'home/SET_VALUE' as const,
  SET_DIARIES: 'home/SET_DIARIES' as const,
  SET_DIARIES_SUCCESS: 'home/SET_DIARIES_SUCCESS' as const,
  SET_DIARIES_ERROR: 'home/SET_DIARIES_ERROR' as const,
};

export const setValue = createAction(
  Types.SET_VALUE,
  (action) => (key: string, value: any) =>
    action({
      key,
      value,
    }),
);

export const asyncSetDiaries = createAsyncAction(
  Types.SET_DIARIES,
  Types.SET_DIARIES_SUCCESS,
  Types.SET_DIARIES_ERROR,
)<any, IDiary[], AxiosError>();

export const actions = {
  setValue,
  asyncSetDiaries,
};

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { iconCopy, iconExit } from '../../../assets/images/icon';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import ModalConfirm from '../../common/ModalConfirm';
import Button from '../../parts/Button';
import InviteList from '../InviteList';
import useFetchData from '../../../hooks/useFetchData';
import LoadingContent from '../../common/LoadingContent';
import useCopyClipBoard from '../../../hooks/useCopyClipBoard';
import { actions as commonActions } from '../../../store/common';

function StackInvitationWaiting(): JSX.Element {
  const dispatch = useDispatch();
  const { removeStackScreen, replaceStackScreen } = useStack();

  const { myProfile } = useSelector((state: RootState) => state.common);
  const { selectedDiaryId, selectedDiaryInfo } = useSelector(
    (state: RootState) => ({
      selectedDiaryId: state.common.selectedDiaryId,
      selectedDiaryInfo: state.common.selectedDiaryInfo,
    }),
    shallowEqual,
  );

  const [viewModalDeleteMember, setViewModalDeleteMember] = useState(false);
  const [targetMember, setTargetMember] = useState({
    id: null,
    nickname: '',
  });

  const onClickDeleteMember = useCallback((id, nickname) => {
    setTargetMember({
      id,
      nickname,
    });
    setViewModalDeleteMember(true);
  }, []);

  const { fetchDiaryInfo, initDiaryInfo } = useFetchData();

  const onSubmitDeleteMember = useCallback(async () => {
    console.log('target id : ', targetMember);
    try {
      const { data } = await axios.delete(
        `/diaries/${selectedDiaryId}/members/${targetMember.id}`,
      );
      if (data.success) {
        // 내보내기 성공
        setViewModalDeleteMember(false);
        setTargetMember({
          id: null,
          nickname: '',
        });
        fetchDiaryInfo();
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedDiaryId, targetMember]);

  const hideModalDeleteMember = useCallback(() => {
    setViewModalDeleteMember(false);
  }, []);

  useEffect(() => {
    fetchDiaryInfo();

    return () => {
      initDiaryInfo();
    };
  }, [selectedDiaryId]);

  const onClickInvitationBtn = () => {
    window.flutter_inappwebview.callHandler(
      'share',
      `
        같이 쓰는 질문 다이어리
        💌 ‘아모르’ 초대장💌이 도착했어요!
        당신을 궁금해하는 [${myProfile?.nickname}]님과 [${selectedDiaryInfo?.data?.name}] 다이어리를 함께 쓰시겠어요??? 코드: ${selectedDiaryInfo?.data?.code}
        https://markerbiz.page.link/zLL2
            `,
    );

    replaceStackScreen('StackInvitationWaiting');
  };

  const onClickStartDiary = useCallback(() => {
    if (
      selectedDiaryInfo?.data?.members &&
      selectedDiaryInfo.data.members.length > 1
    ) {
      replaceStackScreen('StackSetQuestionTime');
    }
  }, [selectedDiaryInfo, replaceStackScreen]);

  const { fetchMainDiaries } = useFetchData();
  const onClickLeaveDiary = useCallback(async () => {
    try {
      const { data } = await axios.delete(`/diaries/${selectedDiaryId}/left`);
      if (data.success) {
        fetchMainDiaries();
        removeStackScreen();
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedDiaryId, removeStackScreen]);

  const [viewModalDeleteDiary, setViewModalDeleteDiary] = useState(false);

  const onDeleteDiary = useCallback(async () => {
    try {
      const { data } = await axios.delete(`/diaries/${selectedDiaryId}`);
      if (data.success) {
        setViewModalDeleteDiary(false);
        fetchMainDiaries();
        removeStackScreen();
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedDiaryId, removeStackScreen]);

  const onClickDeleteDiary = useCallback(() => {
    setViewModalDeleteDiary(true);
  }, []);

  const hideModalDeleteDiary = useCallback(() => {
    setViewModalDeleteDiary(false);
  }, []);

  const [isCopy, onCopy] = useCopyClipBoard();
  const handleCopyClipBoard = (text: string) => {
    onCopy(text).then(() => {});
    dispatch(
      commonActions.setValue('alertMessage', '클립보드에 복사되었습니다.'),
    );
  };

  return (
    <Container>
      <ModalConfirm
        visible={viewModalDeleteMember}
        title="확인"
        description={`${targetMember.nickname} 님을 다이어리에서 내보내시겠어요?`}
        btnCancel="취소"
        btnCancelClick={hideModalDeleteMember}
        btnConfirm="내보내기"
        btnConfirmClick={onSubmitDeleteMember}
      />
      <ModalConfirm
        visible={viewModalDeleteDiary}
        title="확인"
        description="정말 다이어리를 삭제 하시겠어요?"
        btnCancel="취소"
        btnCancelClick={hideModalDeleteDiary}
        btnConfirm="삭제"
        btnConfirmClick={onDeleteDiary}
      />
      <StackHeader
        title={selectedDiaryInfo?.data?.name}
        btnRight={
          myProfile?.id === selectedDiaryInfo?.data?.members[0].id ? (
            <button
              className="btn-delete"
              type="button"
              onClick={onClickDeleteDiary}
            >
              삭제
            </button>
          ) : (
            <></>
          )
        }
      />
      <Content>
        {selectedDiaryInfo.loading && !selectedDiaryInfo.data && (
          <LoadingContent />
        )}
        {!selectedDiaryInfo.loading && selectedDiaryInfo.data && (
          <div className="inner-wrap">
            <section className="top">
              <p className="ment">
                초대한 사람을
                <br /> 기다리고 있어요
              </p>
              <div className="code">
                <span>그룹 코드</span>
                <span>{selectedDiaryInfo?.data?.code}</span>
                <button
                  type="button"
                  onClick={() =>
                    handleCopyClipBoard(selectedDiaryInfo?.data?.code ?? '')
                  }
                >
                  <img src={iconCopy} alt="복사 아이콘" />
                </button>
              </div>
              <div className="btn-wrap">
                {myProfile?.id === selectedDiaryInfo?.data?.members[0].id && (
                  <Button
                    style={{ width: '120px' }}
                    size="small"
                    outline="main"
                    round
                    onClick={onClickInvitationBtn}
                  >
                    초대장 보내기
                  </Button>
                )}
              </div>
              <div className="member">
                <strong>Member</strong>
                <span>{selectedDiaryInfo?.data?.members.length}</span>
              </div>
            </section>
            <section className="list-section">
              <InviteList
                members={selectedDiaryInfo?.data?.members}
                onClickDeleteMember={onClickDeleteMember}
              />
            </section>
            {myProfile?.id !== selectedDiaryInfo?.data?.members[0].id && (
              <div className="btn-exit">
                <button type="button" onClick={onClickLeaveDiary}>
                  <img src={iconExit} alt="나가기 아이콘" />
                  <span>그만하기</span>
                </button>
              </div>
            )}
          </div>
        )}

        {myProfile?.id === selectedDiaryInfo?.data?.members[0].id && (
          <BtnBottom>
            <Button
              fullWidth
              color={
                selectedDiaryInfo?.data?.members &&
                selectedDiaryInfo.data?.members.length > 1
                  ? 'main'
                  : 'gray'
              }
              onClick={onClickStartDiary}
            >
              이 멤버로 시작하기
            </Button>
          </BtnBottom>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .btn-delete {
    color: ${({ theme }) => theme.color.gray1};
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;

  .top {
    text-align: center;
    margin: 70px 0 100px;

    .ment {
      font-size: 2.8rem;
      margin-bottom: 25px;
    }
    .code {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px;

      span {
        margin: 0 5px;
      }

      button img {
        width: 24px;
      }
    }
    .btn-wrap {
      margin-bottom: 40px;
    }
    .member {
      span {
        color: ${({ theme }) => theme.color.gray1};
        margin-left: 8px;
      }
    }
  }

  .list-section {
    margin-bottom: 140px;
  }

  .btn-exit {
    margin-bottom: 50px;
    text-align: right;

    button {
      display: inline-flex;
      align-items: center;

      img {
        width: 28px;
        margin-right: 4px;
      }

      span {
        color: ${({ theme }) => theme.color.gray1};
      }
    }
  }
`;
const BtnBottom = styled.div`
  position: fixed;
  bottom: 40px;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 0 15px;
`;

export default StackInvitationWaiting;

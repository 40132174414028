import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { tutorial01, tutorial02, tutorial03 } from '../../../assets/images';
import Button from '../../parts/Button';
import { actions as commonActions } from '../../../store/common';
import useNavigator from '../../../hooks/useNavigator';

function Tutorial(): JSX.Element {
  const [page, setPage] = useState(1);

  const onClickSkip = useCallback(() => {
    setPage((v) => v + 1);
  }, []);

  const dispatch = useDispatch();

  const onClickStart = useCallback(() => {
    dispatch(commonActions.setValue('isTutorialShow', false));
    localStorage.setItem('isJoined', 'true');
  }, []);

  const [showNavigator, hideNavigator] = useNavigator();

  useEffect(() => {
    hideNavigator();
    return () => {
      showNavigator();
    };
  }, []);

  return (
    <Container>
      {page < 3 && (
        <button className="btn-skip" type="button" onClick={onClickSkip}>
          Skip
        </button>
      )}
      {page === 1 && (
        <Guide>
          <div className="text-wrap">
            <p className="tag">NEW</p>
            <h2 className="title">새롭게 추가된 기능!</h2>
            <p className="description">
              이제 마음에 드는 질문을
              <br /> 내 다이어리에 적용 할 수 있어요!
            </p>
          </div>
          <Paging page={page}>
            <span />
            <span />
            <span />
          </Paging>
          <div className="image">
            <img src={tutorial01} alt="튜토리얼 이미지" />
          </div>
        </Guide>
      )}
      {page === 2 && (
        <Guide>
          <div className="text-wrap">
            <p className="tag">NEW</p>
            <h2 className="title">어떻게 하나요?</h2>
            <p className="description">
              오픈스페이스에서
              <br /> 마음에 드는 질문을 스크랩 한 후
            </p>
          </div>
          <Paging page={page}>
            <span />
            <span />
            <span />
          </Paging>
          <div className="image">
            <img src={tutorial02} alt="튜토리얼 이미지" />
          </div>
        </Guide>
      )}
      {page === 3 && (
        <Guide>
          <div className="text-wrap">
            <p className="tag">NEW</p>
            <h2 className="title">어떻게 하나요?</h2>
            <p className="description">
              ‘적용하기’를 눌러 질문을 받아보세요
              <br /> 설정한 시간에 이 질문이 도착할거에요!
            </p>
          </div>
          <Paging page={page}>
            <span />
            <span />
            <span />
          </Paging>
          <div className="image">
            <img src={tutorial03} alt="튜토리얼 이미지" />
          </div>
        </Guide>
      )}
      {page === 3 && (
        <div className="btn-start">
          <Button fullWidth onClick={onClickStart}>
            시작하기
          </Button>
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow: hidden;
  background-color: #fff0eb;

  .btn-skip {
    font-size: 1.5rem;
    font-family: 'NanumSquare';
    font-weight: 700;
    position: absolute;
    top: 46px;
    right: 18px;
    padding: 10px;
  }

  .btn-start {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 0 15px;
  }
`;

const Guide = styled.div`
  .text-wrap {
    text-align: center;
    padding-top: 88px;

    .tag {
      font-size: 1.6rem;
      font-weight: 700;
      color: ${({ theme }) => theme.color.main1};
      margin-bottom: 15px;
    }

    .title {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    .description {
      font-size: 1.3rem;
      line-height: 1.8;
    }
  }

  .image {
    img {
      width: 100%;
    }
  }
`;

const Paging = styled.div<{ page: number }>`
  margin: 15px 0;
  text-align: center;

  span {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: #8a8a8a;
    opacity: 0.3;
    margin: 0 3px;

    ${({ page }) => {
      if (page === 1) return `&:nth-child(1) { opacity: 1 }`;
      if (page === 2) return `&:nth-child(2) { opacity: 1 }`;
      if (page === 3) return `&:nth-child(3) { opacity: 1 }`;
    }}
  }
`;

export default Tutorial;

import { createAsyncAction, deprecated } from 'typesafe-actions';
import { AxiosError } from 'axios';

const { createAction } = deprecated;

export const Types = {
  SET_VALUE: 'openSpace/SET_VALUE' as const,
  SET_OPENSAPCE: 'openSpace/SET_OPENSPACE' as const,
  SET_OPENSAPCE_BEST_SUCCESS: 'openSpace/SET_OPENSAPCE_BEST_SUCCESS' as const,
  SET_OPENSAPCE_NEW_SUCCESS: 'openSpace/SET_OPENSAPCE_NEW_SUCCESS' as const,
  SET_OPENSAPCE_ERROR: 'openSpace/SET_OPENSAPCE_ERROR' as const,
  COMMENT_LIKE_TOGGLE_ON_FEED: 'openSpace/COMMENT_LIKE_TOGGLE_ON_FEED' as const,
  REPLY_LIKE_TOGGLE_ON_FEED: 'openSpace/REPLY_LIKE_TOGGLE_ON_FEED' as const,
  DELETE_COMMENT_ON_FEED: 'openSpace/DELETE_COMMENT_ON_FEED' as const,
};

export const setValue = createAction(
  Types.SET_VALUE,
  (action) => (key: string, value: any) =>
    action({
      key,
      value,
    }),
);


export const setOpenSpaceBestSuccess = createAction(
  Types.SET_OPENSAPCE_BEST_SUCCESS,
  (action) => (list: any, nextPageUrl: string, isAppend:boolean) =>
    action({
      list,
      nextPageUrl,
      isAppend
    }),
);

export const setOpenSpaceNewSuccess = createAction(
  Types.SET_OPENSAPCE_NEW_SUCCESS,
  (action) => (list: any, nextPageUrl: string, isAppend:boolean) =>
    action({
      list,
      nextPageUrl,
      isAppend
    }),
);



export const commentLikeToggleOnFeed = createAction(
  Types.COMMENT_LIKE_TOGGLE_ON_FEED,
  (action) => (index: number) =>
    action({
      index,
    }),
);
export const replyLikeToggleOnFeed = createAction(
  Types.REPLY_LIKE_TOGGLE_ON_FEED,
  (action) => (commentIndex: number, replyIndex: number) =>
    action({
      commentIndex,
      replyIndex,
    }),
);

export const deleteCommentOnFeed = createAction(
  Types.DELETE_COMMENT_ON_FEED,
  (action) => (commentId: number) =>
    action({
      commentId,
    }),
);


// export const asyncSetOpenSpace = createAsyncAction(
//   Types.SET_OPENSAPCE,
//   Types.SET_OPENSAPCE_SUCCESS,
//   Types.SET_OPENSAPCE_ERROR,
// )<any,IResponseData, AxiosError>();

export const actions = {
  setValue,
  setOpenSpaceBestSuccess,
  setOpenSpaceNewSuccess,
  // asyncSetOpenSpace,
  commentLikeToggleOnFeed,
  replyLikeToggleOnFeed,
  deleteCommentOnFeed,
};

import React, { ReactElement, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import OpenSpace from './pages/OpenSpace';
import Gateway from './pages/Gateway';
import Scrap from './pages/Scrap';
import Test from './pages/Test';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/test" component={Test} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/open-space" component={OpenSpace} />
      <Route exact path="/scrap" component={Scrap} />
      <Route exact path="/gateway" component={Gateway} />
      <Route exact path="/" component={Home} />
    </Switch>
  );
}

export default hot(module)(App);

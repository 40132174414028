import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import StackHeader from '../../../layouts/StackHeader';
import BottomToastModal from '../../common/BottomToastModal';
import NoList from '../../common/NoList';
import QuestionFeed from '../../common/QuestionFeed';
import { IOpenSpace } from '../../../store/openSpace';
import { RootState } from '../../../store';
import { loading } from '../../../assets/images';

function StackMyQuestionManagement(): JSX.Element {
  const { myProfile, stackScreens } = useSelector(
    (state: RootState) => state.common,
  );
  const [selectedTabMenu, setSelectedTabMenu] = useState('공개');
  const [openSpaces, setOpenSpaces] = useState<IOpenSpace[] | []>([]);
  const [bottomToastModalView, setBottomToastModalView] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);

  useEffect(() => {
    fetchOpenspaces();
  }, []);

  useEffect(() => {
    if (
      stackScreens.length > 0 &&
      stackScreens[stackScreens.length - 1] === 'StackMyQuestionManagement'
    ) {
      fetchOpenspaces();
    }
  }, [stackScreens, selectedTabMenu]);

  const fetchOpenspaces = (nextPageUrl?: string) => {
    // 현재 서버api 에 paging 기능 없음.
    let url = null;
    if (nextPageUrl) {
      url = nextPageUrl;
    } else if (selectedTabMenu === '공개') {
      url = '/me/openspaces?length=30';
    } else {
      url = '/me/openspaces/private?length=30';
    }

    axios.get(url).then((res) => {
      if (nextPageUrl) {
        const list = [...openSpaces];
        list.push(...res.data.openSpaces);
        setOpenSpaces(list);
      } else {
        setOpenSpaces(res.data.openSpaces || []);
      }
      setNextPageUrl(res.data.nextPageUrl);
    });
  };

  const fetchOpenSpaceFn = () => {
    fetchOpenspaces(nextPageUrl || undefined);
  };

  const onTabMenuClick = useCallback((tabName: string) => {
    setSelectedTabMenu(tabName);
  }, []);

  return (
    <Container>
      <StackHeader
        title="내 질문 관리"
        selectedTabMenu={selectedTabMenu}
        tabMenuLeft="공개"
        tabMenuRight="비공개"
        tabMenuClick={onTabMenuClick}
      />
      <Content id="ScrollWrap2">
        {openSpaces.length === 0 && (
          <NoList ment="아직 내가 작성한 질문이 없어요" />
        )}
        <div className="feed-list">
          <InfiniteScroll
            dataLength={openSpaces.length || 0}
            next={fetchOpenSpaceFn}
            hasMore={!!nextPageUrl}
            loader={
              <div style={{ textAlign: 'center' }}>
                <img
                  src={loading}
                  alt="로딩 이미지"
                  style={{ width: '80px' }}
                />
              </div>
            }
            scrollableTarget="ScrollWrap2"
            // endMessage={
            //   <p
            //     style={{
            //       textAlign: 'center',
            //       marginBottom: '50px',
            //       color: '#999',
            //     }}
            //   >
            //     더이상 리스트가 없습니다.
            //   </p>
            // }
          >
            {openSpaces &&
              openSpaces.map((data) => {
                return (
                  <QuestionFeed
                    key={data.id}
                    openSpaceId={data.id}
                    className="feed-item"
                    thumb={data.writer.profileUrl}
                    nickname={data.writer.nickname}
                    isMyFeed={data.writer.nickname === myProfile?.nickname}
                    createdAt={data.createdAt}
                    hashtags={data.tags && `#${data.tags.join(' #')}`}
                    question={data.content}
                    coverImage={data.attachments}
                    isScrap={data.scrapYn === 'Y'}
                    countScrap={data.scrapsCount}
                    countComment={data.commentsCount}
                    isMyQuestion
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Content>

      {bottomToastModalView && (
        <BottomToastModal
          visible={bottomToastModalView}
          hideToastModal={() => setBottomToastModalView(false)}
        >
          <CommentBtnMenu>
            <button type="button">나만보기</button>
            <button type="button">전체공개</button>
          </CommentBtnMenu>
        </BottomToastModal>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.gray3};

  .feed-list {
    .feed-item {
      margin-top: 15px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
  }
`;

const CommentBtnMenu = styled.div`
  button {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 1.6rem;
  }
`;

export default StackMyQuestionManagement;

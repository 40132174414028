import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getTimezoneOffset } from '../utils/AmorDateUtil';

interface IReturn {
  putMyTimezone: () => void;
}

function useTimeoffset(): IReturn {
  const dispatch = useDispatch();

  const putMyTimezone = async () => {
    try {

      axios.put('/me/timezone', { timezone: getTimezoneOffset() });
    } catch (e) {
      console.log(e);
    }
  };

  return { putMyTimezone };
}

export default useTimeoffset;

import axios from 'axios';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { iconCautionCircle } from '../../../assets/images/icon';
import useNavigator from '../../../hooks/useNavigator';
import Button from '../../parts/Button';

function BlockApp(): JSX.Element {
  const logout = async () => {
    try {
      const { data } = await axios.post('/logout');
      if (data.success) {
        window.flutter_inappwebview.callHandler('logout');
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [_, hideNavigator] = useNavigator();

  useEffect(() => {
    hideNavigator();
  }, []);

  return (
    <Container>
      <div className="content">
        <img src={iconCautionCircle} alt="주의 아이콘" />
        <p>
          3회 이상 게시물 신고로 인해
          <br /> 사용이 제한되었습니다.
        </p>
      </div>
      <div className="btn-wrap">
        <p>
          문의 <span className="email">commercelab1@gmail.com</span>
        </p>
        <Button fullWidth onClick={logout}>
          로그아웃
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;

  .content {
    text-align: center;
    margin-top: 140px;

    img {
      width: 60px;
      margin-bottom: 48px;
    }

    p {
      font-size: 2rem;
    }
  }

  .btn-wrap {
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 50px;
    width: 100%;
    padding: 0 15px;

    p {
      margin-bottom: 15px;
      .email {
        color: ${({ theme }) => theme.color.main1};
      }
    }
  }
`;

export default BlockApp;

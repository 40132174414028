import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { css, keyframes } from 'styled-components';
import { btnClose } from '../../assets/images';
import { iconEdit, iconSetting } from '../../assets/images/icon';
import useNavigator from '../../hooks/useNavigator';
import useStack from '../../hooks/useStack';
import { RootState } from '../../store';
import BottomToastModal from '../common/BottomToastModal';
import InvitationCodeForm from './InvitationCodeForm';

interface IProps {
  visible: boolean;
  hideSideMenu: () => void;
}

function SideMenu({ visible, hideSideMenu }: IProps): JSX.Element {
  const { stackScreens, myProfile, isTutorialShow } = useSelector(
    (state: RootState) => ({
      stackScreens: state.common.stackScreens,
      myProfile: state.common.myProfile,
      isTutorialShow: state.common.isTutorialShow,
    }),
    shallowEqual,
  );
  const [localVisible, setLocalVisible] = useState(false);

  const { addStackScreen } = useStack();
  const history = useHistory();

  useEffect(() => {
    if (visible) {
      setLocalVisible(true);
    } else {
      setTimeout(() => {
        setLocalVisible(false);
      }, 200);
    }
  }, [visible]);

  const [visibleInvitationCodeForm, setVisibleInvitationCodeForm] =
    useState(false);

  const onToggleInvitationCodeForm = useCallback(() => {
    setVisibleInvitationCodeForm((v) => !v);
  }, []);

  const onClickMenu = (stackName: string) => {
    hideSideMenu();
    addStackScreen(stackName);
  };

  const [showNavigator, hideNavigator] = useNavigator();

  useEffect(() => {
    if (visible) hideNavigator();
    else if (
      !visible &&
      stackScreens.length === 0 &&
      history.location.pathname.indexOf('login') === -1
    ) {
      const isJoined = localStorage.getItem('isJoined');
      if (isJoined) showNavigator();
    }
  }, [visible, stackScreens]);

  const onClickRecommendApp = () => {
    window.flutter_inappwebview.callHandler(
      'share',
      `
    나, 친구, 연인, 가족이 함께 쓰는 질문 다이어리. 
    서로가 몰랐던 부분을 알아가는 과정. 질문다이어리 아모르를 추천드립니다.
    https://markerbiz.page.link/zLL2
        `, // 문구 수정 필요
    );
  };

  return (
    <Container visible={visible} localVisible={localVisible}>
      <Layer visible={visible} localVisible={localVisible}>
        <button
          className="btn-close"
          type="button"
          onClick={() => hideSideMenu()}
        >
          <img src={btnClose} alt="닫기 버튼" />
        </button>
        <div
          role="button"
          className="profile"
          onClick={() => onClickMenu('StackProfileForm')}
        >
          <span
            className="thumb"
            style={{
              backgroundImage: `url(${
                myProfile?.profileUrl
                  ? myProfile?.profileUrl
                  : '/images/defaultProfile'
              })`,
            }}
          />
          <span className="nickname">{myProfile?.nickname}</span>
          <img className="icon-edit" src={iconEdit} alt="수정 아이콘" />
        </div>
        <ul className="menu-list">
          <li onClick={() => onClickMenu('StackMyDiary')}>내 다이어리</li>
          <li onClick={() => onClickMenu('StackEndedDiary')}>
            종료된 다이어리
          </li>
          <li onClick={onToggleInvitationCodeForm}>초대코드 입력하기</li>
          <li onClick={() => onClickMenu('StackMyQuestionManagement')}>
            내가 작성한 질문
          </li>
          <li onClick={() => onClickMenu('StackGuide')}>이용안내</li>
          <li onClick={onClickRecommendApp}>추천하기</li>
        </ul>
        <div className="bottom">
          <p className="version">Amor Version 2.0</p>
          <button
            type="button"
            className="setting"
            onClick={() => onClickMenu('StackSettings')}
          >
            <img className="icon-setting" src={iconSetting} alt="설정 아이콘" />
            <span>설정</span>
          </button>
        </div>
      </Layer>
      {visibleInvitationCodeForm && (
        <BottomToastModal
          visible={visibleInvitationCodeForm}
          hideToastModal={onToggleInvitationCodeForm}
        >
          <InvitationCodeForm
            hideInvitationCodeForm={onToggleInvitationCodeForm}
            hideSidemenu={hideSideMenu}
          />
        </BottomToastModal>
      )}
    </Container>
  );
}

const slideRight = keyframes`
  from {
    transform:translateX(-110%);
  } to {
    transform:translateX(0);
  }
`;
const slideLeft = keyframes`
  from {
    transform:translateX(0);
  } to {
    transform:translateX(-110%);
  }
`;

const Container = styled.div<{ visible: boolean; localVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  ${({ localVisible }) =>
    !localVisible &&
    css`
      display: none;
    `}

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `}
`;

const Layer = styled.div<{ visible: boolean; localVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  box-shadow: 10px 0 15px rgba(0, 0, 0, 0.1);
  padding-top: 70px;

  transform: translateX(-110%);

  ${({ visible }) =>
    visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideRight};
      animation-fill-mode: forwards;
    `}

  ${({ visible, localVisible }) =>
    !visible &&
    localVisible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideLeft};
      animation-fill-mode: forwards;
    `}

  .btn-close {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;

    img {
      width: 24px;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 36px;

    .thumb {
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 16px;
    }
    .nickname {
      font-size: 1.6rem;
      font-weight: 700;
    }
    .icon-edit {
      width: 24px;
    }
  }

  .menu-list {
    li {
      padding: 0 24px;
      line-height: 4rem;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .version {
      font-size: 1.1rem;
      font-weight: 300;
      color: ${({ theme }) => theme.color.gray1};
      border-bottom: 1px solid ${({ theme }) => theme.color.gray2};
      padding: 16px;
    }

    .setting {
      display: flex;
      align-items: center;
      padding: 24px 16px;

      .icon-setting {
        width: 28px;
        margin-right: 6px;
      }
    }
  }
`;

export default SideMenu;

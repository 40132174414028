import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Router } from 'react-router-dom';
import { isIOS, isAndroid } from 'react-device-detect';
import { theme } from './assets/styles/theme';
import reportWebVitals from './reportWebVitals';
import App from './App';
import rootReducer, { rootSaga } from './store';
import Stack from './components/stack';
import AxiosSettings from './components/axiosSettings';
import AlertMessage from './components/common/AlertMessage';

import './static/fonts/nanumsquare.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './static/css/common.css';
import history from './history';
import WebNavigator from './components/common/WebNavigator';
import { isAmorApp } from './utils/webUtil';
import ErrorBoundary from './components/common/ErrorBoundary';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);
const url = window?.location.href;
sagaMiddleware.run(rootSaga);
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary>
          <AxiosSettings />
          <Stack />
          <AlertMessage />
          <App />
          {url.indexOf('/login') === -1 && !isAmorApp() && <WebNavigator />}
        </ErrorBoundary>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from 'react';
import styled from 'styled-components';
import { logoGray } from '../../../assets/images';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: any, info: any) {
    console.log(`error name : ${error.name}`);
    console.log(`error message : ${error.message}`);
    this.setState({ error: true });
  }

  render() {
    const { error }: any = this.state;
    if (error) {
      return (
        <Container>
          <div>
            <img src={logoGray} alt="로고" />
            <p>
              에러가 발생했습니다.
              <br />
              앱을 재실행해 주시기 바랍니다.
            </p>
          </div>
        </Container>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 60px;
    margin-bottom: 20px;
  }

  p {
    color: ${({ theme }) => theme.color.gray1};
  }
`;

export default ErrorBoundary;

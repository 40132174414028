import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { ScrapActions, ScrapState } from './types';
import {
  setValue,
  setScrapMyDiary,
  setScrapMyDiarySuccess,
  setScrapMyDiaryError,
  setScrapOpenSpace,
  setScrapOpenSpaceSuccess,
  setScrapOpenSpaceError,
  toggleScrapMyDiary,
  toggleScrapOpenSpace,
  setScrapOpenSpaceReadyCount,
  setSelectableDiaryList,
  setSelectableDiaryListSuccess,
  setSelectableDiaryListError,
} from './actions';
import {
  asyncState,
  createAsyncReducer,
  transformToArray,
} from '../../utils/reducerUtils';

const initialState: ScrapState = {
  scrapMyDiary: asyncState.initial(null),
  scrapOpenSpace: asyncState.initial(null),
  selectedOpenSpaceId: null,
  selectableDiaryList: asyncState.initial(null),
};

const reducer = createReducer<ScrapState, ScrapActions>(initialState)
  .handleAction(setValue, (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }))
  .handleAction(setScrapMyDiary, (state) => {
    return produce(state, (draft) => {
      draft.scrapMyDiary = {
        loading: true,
        data: null,
        error: null,
      };
    });
  })
  .handleAction(setScrapMyDiarySuccess, (state, action) => {
    return produce(state, (draft) => {
      if (!draft.scrapMyDiary.data)
        draft.scrapMyDiary.data = {
          list: [],
          nextPageUrl: '',
        };
      draft.scrapMyDiary.loading = false;
      draft.scrapMyDiary.data.list.push(...action.payload.list);
      draft.scrapMyDiary.data.nextPageUrl = action.payload.nextPageUrl;
    });
  })
  .handleAction(setScrapMyDiaryError, (state, action) => {
    return produce(state, (draft) => {
      draft.scrapMyDiary = {
        loading: false,
        data: null,
        error: action.payload.error,
      };
    });
  })
  .handleAction(setScrapOpenSpace, (state) => {
    return produce(state, (draft) => {
      draft.scrapOpenSpace = {
        loading: true,
        data: null,
        error: null,
      };
    });
  })
  .handleAction(setScrapOpenSpaceSuccess, (state, action) => {
    return produce(state, (draft) => {
      if (!draft.scrapOpenSpace.data)
        draft.scrapOpenSpace.data = {
          list: [],
          nextPageUrl: '',
        };
      draft.scrapOpenSpace.loading = false;
      draft.scrapOpenSpace.data.list.push(...action.payload.list);
      draft.scrapOpenSpace.data.nextPageUrl = action.payload.nextPageUrl;
    });
  })
  .handleAction(setScrapOpenSpaceError, (state, action) => {
    return produce(state, (draft) => {
      draft.scrapOpenSpace = {
        loading: false,
        data: null,
        error: action.payload.error,
      };
    });
  })
  .handleAction(toggleScrapMyDiary, (state, action) => {
    return produce(state, (draft) => {
      if (draft.scrapMyDiary.data) {
        const { scrapYn } = draft.scrapMyDiary.data.list[action.payload.index];
        if (scrapYn === 'Y')
          draft.scrapMyDiary.data.list[action.payload.index].scrapYn = 'N';
        if (scrapYn === 'N')
          draft.scrapMyDiary.data.list[action.payload.index].scrapYn = 'Y';
      }
    });
  })
  .handleAction(toggleScrapOpenSpace, (state, action) => {
    return produce(state, (draft) => {
      if (draft.scrapOpenSpace.data) {
        const { scrapYn } =
          draft.scrapOpenSpace.data.list[action.payload.index];
        if (scrapYn === 'Y')
          draft.scrapOpenSpace.data.list[action.payload.index].scrapYn = 'N';
        if (scrapYn === 'N')
          draft.scrapOpenSpace.data.list[action.payload.index].scrapYn = 'Y';
      }
    });
  })
  .handleAction(setScrapOpenSpaceReadyCount, (state, action) => {
    return produce(state, (draft) => {
      const targetItem = draft.scrapOpenSpace.data?.list.find(
        (item) => item.openSpaceId === action.payload.openSpaceId,
      );

      if (targetItem?.readyCount !== undefined)
        targetItem.readyCount = action.payload.readyCount;
    });
  })
  .handleAction(setSelectableDiaryList, (state) => {
    return produce(state, (draft) => {
      draft.selectableDiaryList = {
        loading: true,
        data: null,
        error: null,
      };
    });
  })
  .handleAction(setSelectableDiaryListSuccess, (state, action) => {
    return produce(state, (draft) => {
      draft.selectableDiaryList = {
        loading: false,
        data: action.payload.list,
        error: null,
      };
    });
  })
  .handleAction(setSelectableDiaryListError, (state, action) => {
    return produce(state, (draft) => {
      draft.selectableDiaryList = {
        loading: false,
        data: null,
        error: action.payload.error,
      };
    });
  });

export default reducer;

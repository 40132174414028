import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from '../../../store';
import LoadingContent from '../../common/LoadingContent';
import NoList from '../../common/NoList';
import Button from '../../parts/Button';
import ScrapItem from '../ScrapItem';
import { loading } from '../../../assets/images';

interface IProps {
  fetchScrapList: () => void;
}

function OpenSpace({ fetchScrapList }: IProps): JSX.Element {
  const { scrapOpenSpace } = useSelector((state: RootState) => state.scrap);

  return (
    <Container>
      {scrapOpenSpace.loading && <LoadingContent />}
      {!scrapOpenSpace.loading && scrapOpenSpace.data?.list.length === 0 && (
        <NoList ment="스크랩한 질문이 없어요" />
      )}
      {!scrapOpenSpace.loading &&
        scrapOpenSpace.data &&
        scrapOpenSpace.data.list.length > 0 && (
          <div className="list-content">
            <InfiniteScroll
              dataLength={scrapOpenSpace.data.list.length}
              next={fetchScrapList}
              hasMore={!!scrapOpenSpace.data.nextPageUrl}
              loader={
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={loading}
                    alt="로딩 이미지"
                    style={{ width: '80px' }}
                  />
                </div>
              }
              scrollableTarget="ScrapScrollWrap"
              // endMessage={
              //   <p
              //     style={{
              //       textAlign: 'center',
              //       marginBottom: '50px',
              //       color: '#999',
              //     }}
              //   >
              //     더이상 리스트가 없습니다.
              //   </p>
              // }
            >
              {scrapOpenSpace.data.list.map((item, index) => (
                <ScrapItem
                  key={item.openSpaceId}
                  index={index}
                  type="openSpace"
                  name={item.writerNickname}
                  createdAt={item.createdAt}
                  question={item.content}
                  cover={item.attachments}
                  readyCount={item.readyCount}
                  hashtags={item.tags}
                  openSpaceId={item.openSpaceId}
                  scrapYn={item.scrapYn}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default OpenSpace;

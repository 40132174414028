import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { sampleImage1 } from '../../../assets/images';
import useStack from '../../../hooks/useStack';
import { IDiary } from '../../../store/home';
import { actions as commonActions } from '../../../store/common';
import UserThumbList from '../../common/UserThumbList';

interface IProps {
  listType: 'myDiary' | 'endedDiary';
  listData: IDiary[];
}

function DiaryList({ listType, listData }: IProps): JSX.Element {
  const { addStackScreen } = useStack();
  const dispatch = useDispatch();
  const onClickCard = useCallback(
    (diaryId, diaryLeftAt) => {
      addStackScreen('StackDiaryDetail');
      dispatch(commonActions.setValue('selectedDiaryId', diaryId));
      if (listType === 'endedDiary')
        dispatch(commonActions.setValue('selectedDiaryDate', diaryLeftAt));
    },
    [dispatch, addStackScreen],
  );

  return (
    <Container>
      {listData.map((diary) => (
        <Diary listType={listType} key={diary.id}>
          <p>참여자</p>
          <div className="user-list-wrap">
            <UserThumbList userList={diary.members} />
          </div>
          {listType === 'endedDiary' && <p className="date">{diary.period}</p>}
          <div
            role="banner"
            className="card"
            style={{ backgroundImage: `url(${diary.coverUrl})` }}
            onClick={() => onClickCard(diary.id, diary?.leftAt)}
          >
            <div className="layer">
              <p className="category">{diary.group}</p>
              <p className="name">{diary.name}</p>
            </div>
          </div>
        </Diary>
      ))}
    </Container>
  );
}

const Container = styled.ul``;

const Diary = styled.li<Pick<IProps, 'listType'>>`
  padding: 30px 0;
  text-align: center;

  .user-list-wrap {
    margin: 10px 0 20px;
  }

  .date {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.gray1};
    font-weight: 300;
    margin: 10px 0;
  }

  .card {
    width: 160px;
    height: 200px;
    border-radius: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);

    .layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4962359943977591) 0%,
        rgba(255, 255, 255, 0) 50%
      );

      ${({ listType }) =>
        listType === 'endedDiary' &&
        css`
          opacity: 0.7;
          background-color: #fff;
        `}

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .category {
        font-size: 1rem;
        color: #fff;
        padding: 2px 12px;
        border-radius: 25px;
        border: 1px solid #fff;
        margin-bottom: 4px;
      }

      .name {
        font-size: 1.4rem;
        color: #fff;
        margin-bottom: 10px;
      }
    }
  }
`;

export default DiaryList;

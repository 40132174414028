import axios from 'axios';
import { env, getMaxListeners } from 'process';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  btnVideo,
  btnCloseCircleGray,
  btnMic,
  btnPicture,
  sampleImage1,
} from '../../../assets/images';
import { iconQuotesLeft, iconQuotesRight } from '../../../assets/images/icon';
import useRecorder from '../../../hooks/useRecorder';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { makeFormData } from '../../../utils/makeFormData';
import AudioRecorder from '../AudioRecorder';
import { actions as commonActions } from '../../../store/common';
import useFetchData from '../../../hooks/useFetchData';
import VideoPlayer from '../VideoPlayer';
import ProgressBar from '../../parts/ProgressBar';
import LoadingLayer from '../LoadingLayer';

interface IFormData {
  answer: {
    id: number | null;
    file: File | null;
    isChanged: boolean;
    isDeleted: boolean;
    type: 'image' | 'video' | 'audio' | null;
    url: string;
  };
  comment: string;
}

function StackEditDiaryAnswer(): JSX.Element {
  const { removeStackScreen } = useStack();
  const [formData, setFormData] = useState<IFormData>({
    answer: {
      id: null,
      file: null,
      isChanged: false,
      isDeleted: false,
      type: null,
      url: '',
    },
    comment: '',
  });

  const onChangeTextarea = useCallback(
    (e) => {
      setFormData({
        ...formData,
        comment: e.target.value,
      });
    },
    [formData],
  );

  const [audioRecordView, setAudioRecordView] = useState(false);
  const [
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    initRecorder,
    audioFile,
  ] = useRecorder();
  const audioRef = useRef<HTMLAudioElement>(null);

  const {
    selectedDiaryId,
    selectedDiaryInfo,
    selectedDiaryDetail,
    selectedComment,
  } = useSelector(
    (state: RootState) => ({
      selectedDiaryId: state.common.selectedDiaryId,
      selectedDiaryInfo: state.common.selectedDiaryInfo,
      selectedDiaryDetail: state.common.selectedDiaryDetail,
      selectedComment: state.common.selectedComment,
    }),
    shallowEqual,
  );

  const { fetchMainDiaries, fetchDiaryDetail } = useFetchData();
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);

  const onSubmitFormData = useCallback(async () => {
    if (formData.answer.file === null && formData.comment.length === 0) {
      return dispatch(
        commonActions.setValue(
          'alertMessage',
          '미디어 파일 또는 내용을 입력해주세요.',
        ),
      );
    }

    setIsUploading(true);

    const newFormData = makeFormData(formData);
    newFormData.append('_method', 'PUT');
    try {
      const { data } = await axios.post(
        `/diaries/${selectedDiaryId}/questions/${selectedDiaryDetail.data?.question.id}/answers/${selectedComment?.id}`,
        newFormData,
      );
      if (data.success) {
        setIsUploading(false);
        fetchMainDiaries();
        fetchDiaryDetail(selectedDiaryDetail.data?.date);
        removeStackScreen();
      }
    } catch (e) {
      console.log(e);
    }
  }, [formData, selectedDiaryId, selectedDiaryDetail]);

  const onChangeAttachmentImage = useCallback(
    (e) => {
      const file = e.target.files[0];
      let url = '';
      if (file && file.type.match(/^image\/(png|jpeg)$/)) {
        url = URL.createObjectURL(file);
        setFormData({
          ...formData,
          answer: {
            ...formData.answer,
            file,
            isChanged: true,
            isDeleted: false,
            type: 'image',
            url,
          },
        });
      } else {
        // setErrorMessage('이미지 파일만 등록할 수 있습니다.');
        // return;
      }
    },
    [formData],
  );

  const onChangeAttachmentVideo = useCallback(
    (e) => {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setFormData({
        ...formData,
        answer: {
          ...formData.answer,
          file,
          isChanged: true,
          isDeleted: false,
          type: 'video',
          url,
        },
      });
    },
    [formData],
  );

  const onChangeAttachmentAudio = () => {
    console.log('audio file : ', audioFile);

    setFormData({
      ...formData,
      answer: {
        ...formData.answer,
        file: audioFile,
        isChanged: true,
        isDeleted: false,
        type: 'audio',
        url: audioURL,
      },
    });
  };

  useEffect(() => {
    onChangeAttachmentAudio();
  }, [audioFile, audioURL]);

  const onDeleteAttachment = useCallback(() => {
    if (formData.answer.type === 'audio') {
      initRecorder();
    }

    setFormData({
      ...formData,
      answer: {
        ...formData.answer,
        file: null,
        isChanged: true,
        isDeleted: true,
        url: '',
      },
    });
  }, [initRecorder, formData]);

  const getAnswerData = async () => {
    const questionId = selectedDiaryDetail.data?.question.id;

    try {
      const { data } = await axios.get(
        `/diaries/${selectedDiaryId}/questions/${questionId}/answers/${selectedComment?.id}`,
      );
      if (data.success) {
        setFormData({
          answer: {
            id: data.answer.answer.id,
            file: null,
            isChanged: false,
            isDeleted: false,
            type: data.answer.answer.type,
            url:
              data.answer.answer.thumbnailUrl || data.answer.answer.url || '',
          },
          comment: data.answer.comment || '',
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAnswerData();
  }, []);

  return (
    <Container>
      <StackHeader
        title={
          selectedDiaryInfo.data
            ? `${selectedDiaryInfo.data.name}(${selectedDiaryInfo.data.members.length})`
            : ''
        }
        btnRight={
          <BtnSave
            type="button"
            active={
              formData.answer.file !== null || formData.comment.length > 0
            }
            onClick={onSubmitFormData}
          >
            SAVE
          </BtnSave>
        }
      />
      <Content>
        <div className="inner-wrap">
          <section className="top-section">
            <p className="question-num">
              Q.{selectedDiaryDetail.data?.question.number}
            </p>
            <div className="question-wrap">
              <p className="question">
                {selectedDiaryDetail.data?.question.content}
                <img
                  className="icon-quotes-left"
                  src={iconQuotesLeft}
                  alt="따옴표"
                />
                <img
                  className="icon-quotes-right"
                  src={iconQuotesRight}
                  alt="따옴표"
                />
              </p>
            </div>
          </section>
          <textarea
            className="text-area"
            placeholder="탭하여 작성"
            onChange={onChangeTextarea}
            value={formData.comment}
          />
          {formData.answer.url && formData.answer.type === 'audio' && (
            <div className="audio-player">
              <audio ref={audioRef} id="track" src={audioURL} controls />
              <button
                className="btn-close"
                type="button"
                onClick={onDeleteAttachment}
              >
                <img src={btnCloseCircleGray} alt="닫기 버튼" />
              </button>
            </div>
          )}
          {formData.answer.url && formData.answer.type === 'image' && (
            <div className="upload-image-wrap">
              <img
                className="upload-image"
                src={formData.answer.url}
                alt="업로드된 이미지"
              />
              <button type="button" onClick={onDeleteAttachment}>
                <img src={btnCloseCircleGray} alt="닫기 버튼" />
              </button>
            </div>
          )}
          {formData.answer.url.length > 0 && formData.answer.type === 'video' && (
            <div className="upload-video-wrap">
              {formData.answer.file && (
                <VideoPlayer
                  controls={false}
                  src={formData.answer.url}
                  isVideoPlaying={false}
                />
              )}
              {!formData.answer.file && (
                <img
                  className="upload-image"
                  src={formData.answer.url}
                  alt="업로드된 동영상"
                />
              )}

              {/* <video
                width="100%"
                height="auto"
                // autoPlay
                // loop
                muted
                playsInline
                // controls
              >
                <source src={formData.answer.url} type="video/*" />
                <track default kind="captions" />
              </video> */}
              <button type="button" onClick={onDeleteAttachment}>
                <img src={btnCloseCircleGray} alt="닫기 버튼" />
              </button>
            </div>
          )}
        </div>
        {formData.answer.file === null && (
          <div className="media-btns">
            <label htmlFor="btn-upload-image">
              <input
                type="file"
                accept="image/*"
                id="btn-upload-image"
                onChange={onChangeAttachmentImage}
              />
              <img src={btnPicture} alt="이미지 버튼" />
            </label>
            <label htmlFor="btn-upload-video">
              <input
                type="file"
                accept="video/mp4,video/mkv, video/x-m4v,video/*"
                id="btn-upload-video"
                onChange={onChangeAttachmentVideo}
              />
              <img src={btnVideo} alt="카메라 버튼" />
            </label>
            <button type="button" onClick={() => setAudioRecordView(true)}>
              <img src={btnMic} alt="녹음 버튼" />
            </button>
          </div>
        )}
      </Content>

      {isUploading && <LoadingLayer />}

      {/* <BottomToastModal
        visible={}
        hideToastModal={() => setBottomToastModalView(false)}
      >
      </BottomToastModal> */}
      {audioRecordView && (
        <AudioRecorder
          hideAudioRecorder={() => setAudioRecordView(false)}
          audioURL={audioURL}
          isRecording={isRecording}
          startRecording={startRecording}
          stopRecording={stopRecording}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BtnSave = styled.button<{ active: boolean }>`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.color.main1 : theme.color.gray1};
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 40px;
  position: relative;

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    .ment {
      text-align: center;
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .top-section {
    padding: 40px 0 80px;
    position: relative;
    text-align: center;

    .question-num {
      display: inline-block;
      font-weight: 700;
      color: ${({ theme }) => theme.color.main1};
      position: relative;
      font-family: 'NanumSquare';
      margin-bottom: 30px;

      &:after {
        display: block;
        content: '';
        width: 100%;
        height: 4px;
        background-color: ${({ theme }) => theme.color.main2};
        position: absolute;
        bottom: 4px;
        left: 0;
        z-index: -1;
      }
    }

    .question-wrap {
      .question {
        display: inline-block;
        max-width: 80%;
        font-size: 1.8rem;
        font-weight: 300;
        position: relative;

        .icon-quotes-left {
          width: 10px;
          position: absolute;
          top: 0;
          left: -18px;
        }
        .icon-quotes-right {
          width: 10px;
          position: absolute;
          top: 0;
          right: -18px;
        }
      }
    }
  }

  .text-area {
    width: 100%;
    height: 200px;
    font-family: 'NanumSquare';
    outline: 0;
    border: 0;
    margin-bottom: 30px;

    &::placeholder {
      color: ${({ theme }) => theme.color.gray2};
    }
  }

  .audio-player {
    display: inline-block;
    position: relative;

    .btn-close {
      padding: 5px;
      position: absolute;
      top: -8px;
      right: -8px;

      img {
        width: 18px;
      }
    }
  }
  .upload-image-wrap {
    display: inline-block;
    position: relative;

    .upload-image {
      width: 200px;
      border-radius: 8px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;

      img {
        width: 18px;
      }
    }
  }

  .upload-video-wrap {
    display: inline-block;
    position: relative;
    width: 200px;

    .upload-image {
      width: 200px;
      border-radius: 8px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;

      img {
        width: 18px;
      }
    }
  }

  .media-btns {
    position: fixed;
    right: 15px;
    bottom: 30px;
    z-index: 10;
    width: 100%;
    text-align: right;
    padding-right: 15px;

    label {
      margin-left: 10px;

      input {
        width: 0;
        height: 0;
        visibility: hidden;
      }

      img {
        width: 36px;
      }
    }

    button {
      margin-left: 10px;

      img {
        width: 36px;
      }
    }
  }
`;

export default StackEditDiaryAnswer;

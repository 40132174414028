import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../store';
import { actions as commonActions } from '../store/common';
import useStack from './useStack';

declare global {
  interface Window {
    setSafeAreaSize: (size: string) => void;
    routeOpenSpace: () => void;
    routeHome: () => void;
    routeScrap: () => void;
    getTokenFromApp: (token: string) => void;
    watchedRewardAd: () => void;
    globalBackBtn: () => void;
    setScreenLockEnabled: (status: string) => void;
  }
}

function globalFunctions(): void {
  const { stackScreens, currentPage, visibleSideMenu } = useSelector(
    (state: RootState) => ({
      stackScreens: state.common.stackScreens,
      currentPage: state.common.currentPage,
      visibleSideMenu: state.common.visibleSideMenu,
    }),
  );

  const { addStackScreen, removeStackScreen } = useStack();

  const dispatch = useDispatch();
  const history = useHistory();

  window.setSafeAreaSize = (size: string) => {
    // alert(`device safeArea size : ${size}`);
    if (size.length > 0) {
      const safeArea = size.split(',');
      dispatch(
        commonActions.setValue('safeArea', {
          top: Number(safeArea[0]),
          bottom: Number(safeArea[1]),
        }),
      );
    }
  };

  window.setScreenLockEnabled = (status) => {
    // alert(status);
  };

  window.routeHome = () => {
    window.location.href = '/';
    // history.replace('/');
  };

  window.routeOpenSpace = () => {
    window.location.href = '/open-space';
    // history.push('/open-space');
  };

  window.routeScrap = () => {
    // window.location.href = '/scrap';
    history.push('/scrap');
  };

  // window.getTokenFromApp = (params) => {
  //   // alert(params)
  //   const token = params.split(',')[0];
  //   const isJoined = params.split(',')[1];
  //   localStorage.setItem('accessToken', token);
  //   localStorage.setItem('isJoined', isJoined);
  //   // history.replace('/');
  //   window.location.href="/";
  // };

  window.watchedRewardAd = () => {
    dispatch(commonActions.setValue('isWatchedAd', true));
    addStackScreen('StackCreateDiary');
  };

  window.globalBackBtn = () => {
    if (visibleSideMenu && stackScreens.length === 0) {
      dispatch(commonActions.setValue('visibleSideMenu', false));
      return;
    }

    if (stackScreens.length > 0) {
      removeStackScreen();
      return 2;
    }
    if (currentPage === '/scrap' || currentPage === '/open-space') {
      history.push('/');
      return 1;
    }
    if (currentPage === '/' || currentPage === '/login') return 0;
  };
}

export default globalFunctions;

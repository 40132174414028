import React from 'react';
import styled from 'styled-components';

interface IProps {
  image: string;
  size?: string;
}

function Thumb({ image, size }: IProps): JSX.Element {
  return (
    <Container>
      <div
        className="image"
        style={{ backgroundImage: `url(${image})`, width: size, height: size }}
      />
    </Container>
  );
}

Thumb.defaultProps = {
  size: '40px',
};

const Container = styled.div`
  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }
`;

export default Thumb;

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  sampleImage1,
  sampleImage2,
  sampleImage3,
  sampleImage4,
} from '../../../assets/images';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { actions as scrapActions } from '../../../store/scrap';
import CompleteMessageLayer from '../../common/CompleteMessageLayer';
import LoadingContent from '../../common/LoadingContent';
import ModalConfirm from '../../common/ModalConfirm';
import SelectableDiary from '../SelectableDiary';

function StackDiarySelect(): JSX.Element {
  const [viewModalNoDiary, setViewModalNoDiary] = useState(false);
  const [viewModalAllWaiting, setViewModalAllWaiting] = useState(false);
  const { removeStackScreen, clearStackScreen } = useStack();
  const [completeMessageView, setCompleteMessageView] = useState(false);

  const { selectedOpenSpaceId, selectableDiaryList } = useSelector(
    (state: RootState) => state.scrap,
  );

  const dispatch = useDispatch();

  const fetchDiaryList = async () => {
    dispatch(scrapActions.setSelectableDiaryList());

    try {
      const { data } = await axios.get(
        `/openspaces/${selectedOpenSpaceId}/diaries`,
      );
      if (data.success) {
        dispatch(scrapActions.setSelectableDiaryListSuccess(data.diaries));
      }
    } catch (e) {
      dispatch(scrapActions.setSelectableDiaryListError(e));
    }
  };

  const [selectedDiaryArray, setSelectedDiaryArray] = useState<number[]>([]);
  const makeSelectedDiaryArray = () => {
    const selectedDiariesId: number[] = [];
    const selectedDiaries = selectableDiaryList.data?.filter(
      (item) => item.status === 'R',
    );
    selectedDiaries?.forEach((item) => {
      selectedDiariesId.push(item.id);
    });

    setSelectedDiaryArray(selectedDiariesId);
  };

  const onToggleDiaryCheck = useCallback(
    (id: number) => {
      const newDiaryArray = [...selectedDiaryArray];
      if (newDiaryArray.includes(id)) {
        const index = newDiaryArray.findIndex((item) => item === id);
        newDiaryArray.splice(index, 1);
      } else {
        newDiaryArray.push(id);
      }

      setSelectedDiaryArray(newDiaryArray);
    },
    [selectedDiaryArray],
  );

  const checkSelectableDiaries = () => {
    let selectableDiaryCount = 0;
    if (selectableDiaryList.data) {
      selectableDiaryCount += selectableDiaryList.data.filter(
        (item) => item.status === 'N' || item.status === 'R',
      ).length;

      if (selectableDiaryCount === 0) {
        setViewModalAllWaiting(true);
      }
    }
  };

  useEffect(() => {
    fetchDiaryList();
  }, [selectedOpenSpaceId]);

  useEffect(() => {
    makeSelectedDiaryArray();
    checkSelectableDiaries();
  }, [selectableDiaryList]);

  useEffect(() => {
    return () => {
      dispatch(
        scrapActions.setValue('selectableDiaryList', {
          loading: false,
          data: null,
          error: null,
        }),
      );
    };
  }, []);

  const setStoreReadyCount = () => {
    if (selectedOpenSpaceId) {
      dispatch(
        scrapActions.setScrapOpenSpaceReadyCount(
          selectedOpenSpaceId,
          selectedDiaryArray.length,
        ),
      );
    }
  };

  const saveSelectedDiaries = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `/openspaces/${selectedOpenSpaceId}/diaries`,
        {
          diaryIds: selectedDiaryArray,
        },
      );
      if (data.success) {
        setStoreReadyCount();
        if (selectedDiaryArray.length === 0) removeStackScreen();
        if (selectedDiaryArray.length > 0) setCompleteMessageView(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedOpenSpaceId, selectedDiaryArray]);

  const onClickSave = useCallback(() => {
    if (selectedDiaryArray.length === 0) return setViewModalNoDiary(true);

    saveSelectedDiaries();
  }, [selectedDiaryArray, saveSelectedDiaries]);

  const onClickCloseCompleteLayer = useCallback(() => {
    setCompleteMessageView(false);
    removeStackScreen();
  }, [removeStackScreen]);

  const onClickAllWaitingConfirm = useCallback(() => {
    setViewModalAllWaiting(false);
    removeStackScreen();
  }, [removeStackScreen]);

  return (
    <Container>
      <StackHeader
        title="다이어리 선택"
        btnRight={
          <BtnSave type="button" active onClick={onClickSave}>
            SAVE
          </BtnSave>
        }
      />

      <Content>
        <div className="diary-list">
          {selectableDiaryList.loading && !selectableDiaryList.data && (
            <LoadingContent />
          )}
          {!selectableDiaryList.loading && selectableDiaryList.data && (
            <div className="inner-wrap">
              {selectableDiaryList.data.map((item) => (
                <SelectableDiary
                  key={item.id}
                  id={item.id}
                  className="diary-item"
                  status={item.status}
                  categoryName={item.categoryName}
                  name={item.name}
                  cover={item.coverUrl}
                  selected={selectedDiaryArray.includes(item.id)}
                  onToggleDiaryCheck={onToggleDiaryCheck}
                />
              ))}
            </div>
          )}
        </div>
      </Content>

      <ModalConfirm
        visible={viewModalNoDiary}
        title="선택된 다이어리가 없어요 :-("
        description="질문 적용을 취소하시겠어요?"
        btnConfirm="확인"
        btnConfirmClick={saveSelectedDiaries}
      />

      <ModalConfirm
        visible={viewModalAllWaiting}
        title="자리가 없어요 :-("
        description="모든 다이어리가 적용 대기 중입니다."
        btnConfirm="확인"
        btnConfirmClick={onClickAllWaitingConfirm}
      />

      {completeMessageView && (
        <CompleteMessageLayer
          onClickClose={onClickCloseCompleteLayer}
          message={
            <p>
              질문받기가 정상적으로
              <br />
              적용되었습니다.
            </p>
          }
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BtnSave = styled.button<{ active: boolean }>`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.color.main1 : theme.color.gray1};
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  .diary-list {
    flex: 1;

    .diary-item {
      &:nth-child(odd) {
        padding-right: 5px;
      }

      &:nth-child(even) {
        padding-left: 5px;
      }
    }
  }
`;

export default StackDiarySelect;

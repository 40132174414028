import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  active: boolean;
  onClick: () => void;
}

function Switch({ active, onClick }: IProps): JSX.Element {
  return (
    <Container active={active} onClick={onClick}>
      <span className="control" />
    </Container>
  );
}

const Container = styled.div<IProps>`
  width: 34px;
  height: 14px;
  border-radius: 8px;
  position: relative;
  transition: all 0.2s ease;
  background-color: ${({ theme }) => theme.color.gray1};

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.color.main2};
    `}

  .control {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -3px;
    left: 0;
    z-index: 2;
    background-color: #fff;
    transition: all 0.2s ease;

    ${({ active }) =>
      active &&
      css`
        left: auto;
        right: 0;
        background-color: ${({ theme }) => theme.color.main1};
      `}
  }
`;

export default Switch;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { RootState } from '../../../store';
import StackContent from '../StackContent';

function StackLayer2(): JSX.Element | null {
  const { stackScreens } = useSelector((state: RootState) => state.common);
  const [LocalVisible, setLocalVisible] = useState(false);

  useEffect(() => {
    const visible: boolean = stackScreens.length > 1;

    if (visible && !LocalVisible) {
      setLocalVisible(true);
    }
  }, [LocalVisible, stackScreens]);

  return LocalVisible ? (
    <StackContainer visible={stackScreens.length > 1}>
      <StackContent screenName={stackScreens[1]} />
    </StackContainer>
  ) : null;
}

const slideLeft = keyframes`
  from {
    transform:translateX(100%);
  } to {
    transform:translateX(0);
  }
`;
const slideRight = keyframes`
  from {
    transform:translateX(0);
  } to {
    transform:translateX(100%);
  }
`;

const StackContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  overflow-y: auto;

  transform: translateX(100%);

  ${({ visible }: { visible: boolean }) =>
    visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideLeft};
      animation-fill-mode: forwards;
    `}

  ${({ visible }: { visible: boolean }) =>
    !visible &&
    css`
      animation-duration: 0.25s;
      animation-timing-function: ease-out;
      animation-name: ${slideRight};
      animation-fill-mode: forwards;
    `}
`;

export default StackLayer2;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import NoList from '../../common/NoList';
import DiaryList from '../DiaryList';
import LoadingContent from '../../common/LoadingContent';

function StackMyDiary(): JSX.Element {
  const dispatch = useDispatch();
  const { endedDiaries } = useSelector((state: RootState) => state.common);
  const [hasMore, setHasMore] = useState(false);

  const fetchEndedDiaries = async () => {
    dispatch(
      commonActions.setValue('endedDiaries', {
        ...endedDiaries,
        loading: true,
      }),
    );

    try {
      const { data } = await axios.get('/diaries/inactive');
      if (data.success) {
        dispatch(
          commonActions.setValue('endedDiaries', {
            loading: false,
            data: data.diaries,
            error: null,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchEndedDiaries();
  }, []);

  return (
    <Container>
      <StackHeader title="종료된 다이어리" />

      <Content id="scrollWrap">
        {endedDiaries.loading && !endedDiaries.data && <LoadingContent />}
        {endedDiaries.data && endedDiaries.data.length > 0 && (
          <InfiniteScroll
            dataLength={endedDiaries.data.length}
            next={fetchEndedDiaries}
            hasMore={hasMore}
            loader={<h4>로딩중입니다...</h4>}
            scrollableTarget="scrollWrap"
            endMessage={
              <p style={{ textAlign: 'center' }}>더이상 리스트가 없습니다.</p>
            }
          >
            <DiaryList listType="endedDiary" listData={endedDiaries.data} />
          </InfiniteScroll>
        )}
        {endedDiaries.data && endedDiaries.data.length === 0 && (
          <NoList ment="종료된 다이어리가 없어요" />
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding: 10px 0 30px;
  box-sizing: border-box;
`;

export default StackMyDiary;

import React from 'react';
import styled from 'styled-components';
import StackHeader from '../../../layouts/StackHeader';

function StackUseTerm(): JSX.Element {
  return (
    <Container>
      <StackHeader title="이용약관" />
      <div className="inner-wrap">
        <br />
        아모르 이용약관
        <br />
        <br />
        1. 목적
        <br />
        본 약관은 MARKERBIZ GLOBAL INC.(이하 “회사”)에서 제공하는 아모르(이하
        “서비스”)의 이용과 관련하여 회사와 귀하의 권리, 의무, 책임사항, 이용조건
        및 절차 등 기타 필요한 사항을 규정함을 목적으로 합니다.
        <br />
        <br />
        2. 적용
        <br />
        본 약관은 귀하가 Google Play 또는 Apple App Store(이하 통칭하여 “앱
        스토어”라 합니다)를 통해 본 서비스의 실행을 위한 어플리케이션을 다운로드
        받아 사용하시는 경우에 적용됩니다. 페이스북 등 외부 서비스 연동을 통해
        이용계약을 신청할 경우, 본 약관과 개인정보 처리방침, 서비스 제공을 위해
        귀하의 외부 서비스 계정 정보 접근 및 활용에 “동의” 또는 “확인” 버튼을
        누름으로써 이용계약이 성립됩니다. 본 약관은 귀하와 회사 간의 법적 사항을
        규정하며, 귀하와 앱 스토어 간의 관계는 각 앱 스토어의 약관에 따른다는
        점을 주지하시기 바랍니다.
        <br />
        <br />
        3. 약관의 명시, 효력 및 개정
        <br />
        회사는 서비스 내에 본 약관의 개정사항을 게시하거나 개별적으로 고지하는
        방법을 통해 언제든지 본 약관을 개정할 수 있습니다. 본 약관의 변경사항은
        서비스 초기화면에 게시하여 고지할 수 있으며, 최초 게시 후 10일 기간 내
        회원의 명시적인 거부의사나 별도의 의견이 없을 경우 회원이 개정된
        이용약관에 동의한 것으로 간주합니다. 회원이 개정약관의 적용에 동의하지
        않는 경우 회사는 개정약관을 적용한 서비스를 회원에게 제공할 수 없으며,
        이 경우 회원은 이용계약을 해지할 수 있습니다. 회사는 언제든지 귀하에
        대한 고지방법을 변경할 수 있는 권리를 보유합니다.
        <br />
        <br />
        4. 게시물의 저작권 및 관리
        <br />
        귀하는 시스템이 허용하는 한 언제든지 서비스 내에 사진, 글 등 콘텐츠(이하
        ‘게시물’)를 게시할 수 있으며, 이러한 게시물의 저작권은 귀하에게
        귀속됩니다. 귀하가 이용계약을 해지하거나 회사에 의해 이용계약이 해지되는
        경우 본인 계정에 기록된 게시물은 임의로 삭제될 수도 있습니다. 다만, 다른
        회원과 함께 이용한 공유 다이어리 내에 게시된 게시물은 다른 회원의
        정상적인 서비스 이용을 위해 자동으로 삭제되지 않습니다. 모든 게시물은
        귀하에게 관리의 책임이 있으며, 회사는 이에 대한 백업이나 권리를 보장하지
        않습니다.
        <br />
        <br />
        5. 게시물의 책임
        <br />
        본 서비스를 통해 공유되는 게시물에 대해서는 전적으로 해당 게시물을
        생성하였거나 공유한 사람이 책임을 부담합니다. 귀하는 본 서비스를 귀하
        스스로의 책임 하에 사용하시는 것입니다. 회사는 귀하가 본 서비스를
        사용하는 과정에 접하게 되는 게시물과 관련하여 이를 제어하는 것이
        물리적으로 불가능하며, 따라서 귀하는 귀하에게 모욕적이거나 불쾌감을
        유발할 수 있는 게시물을 접할 수 있다는 위험에 대해서 충분히 인지를 하고
        본 서비스를 사용해야 합니다. 회사는 회사의 개입 없이 본 서비스를 통해
        생성, 공유되는 게시물에 대해 어떠한 책임도 부담하지 않습니다.
        <br />
        <br />
        6. 귀하의 권리
        <br />
        귀하는 귀하가 본 서비스상에 또는 본 서비스를 통해 등록, 게시 또는 게재한
        게시물 일체에 대한 권리를 보유합니다. 귀하가 게시한 게시물은 회사가
        운영하는 인터넷 사이트 및 모바일 어플리케이션을 통해 노출될 수 있으며,
        관련 프로모션 등에도 노출될 수 있습니다. 해당 노출을 위해 필요한 범위
        내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는
        저작권법 규정을 준수하며, 귀하는 언제든지 회사에 연락을 통해 해당 게시물
        등에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.
        <br />
        <br />
        7. 계정 관리의 의무
        <br />
        귀하의 계정에 관한 관리책임은 귀하에게 있으며, 이를 타인이 이용하도록
        하여서는 안됩니다. 귀하의 계정을 타인이 사용하고 있음을 인지한 경우에는
        이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다. 귀하가 회사에 그
        사실을 통지하지 않거나, 통지한 경우에도 회사의 안내를 따르지 않아 발생한
        불이익에 대하여 회사는 책임지지 않습니다.
        <br />
        <br />
        8. 귀하의 의무
        <br />
        귀하는 다음과 같은 행위를 하여서는 안됩니다. <br />
        ① 타인의 정보도용 및 사칭
        <br />
        ② 회사 및 제3자에 대한 비방, 명예훼손, 개인정보 침해, 허위사실 유포 등
        기타 피해를 주는 행위
        <br />
        ③ 회사 및 제3자의 저작권 등 지적재산권에 대한 침해하는 행위
        <br />
        ④ 본인이 아닌 제3자에게 접속권한을 부여하는 등, 계정보안에 위험을
        초래하는 행위
        <br />
        ⑤ 서비스 가입과 탈퇴를 의도적으로 반복하며 서비스를 악의적으로
        이용하거나 운영에 지장을 주는 행위
        <br />
        ⑥ 기타 본 항에 준하는 경우나 회사가 판단하기에 운영수칙에 위배되거나
        서비스에 위협이 되는 행위
        <br />
        귀하는 관계법, 이 약관의 규정을 준수하여야 하며 이를 위반한 회원의 경우
        회사는 임의로 귀하와의 이용계약을 해지하거나 서비스 이용을 제한할 수
        있습니다. 또한 귀하는 본 약관을 위배한 다른 회원을 회사 측에 신고할 수
        있으며 민형사상급의 처벌 문제를 제외한 본 약관 위배에 대한 서비스 이용
        제한 여부와 방법은 회사가 판단합니다. 회사는 신고 내용에 대한 증거자료를
        귀하에게 요청할 수 있으며 만약 허위 신고로 판명될 경우, 귀하의 서비스
        이용에 제한이 있을 수 있습니다.
        <br />
        <br />
        9. 회사의 의무
        <br />
        ① 회사는 회사의 서비스 제공 및 보안과 관련된 설비를 지속적이고 안정적인
        서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를 성실히
        이행하여야 합니다. 단, 회사의 컴퓨터 등 정보통신설비의 보수점검, 교체 및
        고장, 통신두절, 정기점검 또는 운영상 필요에 의한 목적으로 서비스 제공이
        일시중단 될 수 있습니다. <br />
        ② 회사는 &#39;정보통신망법&#39; 등 관계 법령이 정하는 바에 따라 귀하의
        개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는
        관련법 및 회사의 개인정보 처리방침이 적용됩니다. 귀하의 개인정보보호에
        관한 기타의 사항은 관계법과 서비스 개인정보 처리방침에 따릅니다. <br />
        ③ 회사는 서비스이용과 관련하여 귀하로부터 제기된 의견이나 불만이
        정당하다고 인정할 경우에는 이를 처리하여야 합니다. 귀하가 제기한
        의견이나 불만사항에 대해서는 전자우편 등을 통하여 전달받습니다.
        <br />
        <br />
        10. 서비스의 최신 버전
        <br />
        회사는 회사의 판단 하에 서비스에 새로운 기능을 추가, 수정, 업데이트 또는
        업그레이드를 할 수 있는 권한을 가지고 있습니다. 그러나 회사는 귀하에게
        이러한 행위를 반드시 해야 할 의무를 부담하지는 않습니다. 서비스가 새로운
        기능 추가, 수정, 업데이트 또는 업그레이드가 이루어진 버전이 출시되는
        경우 귀하는 최신 버전 앱을 다운로드 받거나 사용하기 위하여 본 약관의
        개정 내용에 동의해야 할 수 있습니다. 회사는 귀하의 단말로 하여금 최신
        버전 앱으로 자동 업데이트하도록 설정한 상태로 최신 버전 앱을 출시할 수
        있으며, 따라서 귀하는 귀하의 단말에 설치되어 있는 서비스 앱이 자동으로
        업데이트 될 수 있다는 점에 동의합니다.
        <br />
        <br />
        11. 서비스 이용의 제한 및 중지
        <br />
        회사는 다음과 같은 사유가 발생한 경우에는 서비스 이용에 제한을 두거나
        중지시킬 수 있으며, 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및
        제한 등을 귀하에게 알립니다. <br />
        ① 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우 <br />
        ② 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을
        경우 <br />
        ③ 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스
        이용에 지장이 있는 때 <br />
        ④ 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이
        부적당하다고 인정하는 경우 회사는 이로 인하여 귀하에게 발생한 손해에
        대해서는 책임을 부담하지 않습니다. <br />
        ⑤ 부적절한(선정성,폭력성,유해성 등) 내용으로 인해 신고하기 5회이상
        접수시 게시물 노출은 중단되며, 3회 이상 이력발생시 접속에 제한됩니다.
        이용 제한에 따른 문의: commercelab1@gmail.com
        <br />
        기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이
        부적당하다고 인정하는 경우 회사는 이로 인하여 귀하에게 발생한 손해에
        대해서는 책임을 부담하지 않습니다.
        <br />
        <br />
        12. 통신망 및 단말 사용에 대한 권한
        <br />
        만약 본 서비스의 사용을 위해 이동통신사업자, 유무선인터넷사업자 등
        제3자의 통신망을 이용해야 하는 경우, 귀하는 그러한 제3자로부터 통신망
        사용에 대한 적법한 권한을 받아야 하며, 회사가 이에 대해 관여하거나
        어떠한 의무를 부담하지는 않습니다. 또한 귀하는 서비스 앱을 다운로드 받는
        단말이나 본 서비스를 제공하는 웹사이트에 접근할 수 있는 단말에 대한
        적법한 사용권한을 보유해야 합니다. 만약 귀하가 그러한 단말을 판매 또는
        양도하거나 단말에 대한 권리를 상실하게 될 예정이라면 미리 해당
        단말로부터 서비스 앱이나 관련 개인정보를 삭제해야 하며, 이를 삭제하지
        않아 발생하는 문제에 대해서 귀하가 전적인 책임을 부담합니다.
        <br />
        <br />
        13. 광고
        <br />
        회사는 서비스의 운영과 관련하여 서비스 화면에 광고를 게재할 수 있습니다.
        귀하가 서비스에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의
        판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로
        귀하와 광고주간의 문제입니다. 만약, 귀하와 광고주간에 문제가 발생할
        경우에도 귀하와 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는
        책임지지 않습니다.
        <br />
        <br />
        14. 인앱 구매 <br />
        회사는 귀하에게 더 나은 서비스를 제공하기 위해 서비스 내 유료 상품을
        판매합니다. 귀하는 유료 상품 구매와 관련하여 회사 및 귀하가 이용하신 앱
        스토어의 정책을 근거로 세금 및 수수료를 포함한 모든 요금을 지불하실 것에
        동의합니다. 귀하는 귀하가 이용하시는 특정 지불 처리 서비스의 약관을
        준수해야 하며 계약상의 책임을 이행해야 합니다. 회사는 본 서비스 내
        공지를 통해 제공된 인앱 구매 서비스를 중단하거나 그 내용을 변경할 수
        있습니다. 귀하는 이에 동의하고 구매를 진행합니다. 귀하는 귀하의 계정과
        관련하여 발생하는 모든 청구에 대한 책임을 부담합니다. 귀하가 앱 스토어를
        통해 유료 상품을 구매하는 경우, 모든 지급 관련 질문, 문제, 불만 등은
        귀하가 사용하는 지불 처리 서비스의 약관에 따라 처리될 것입니다. 회사는
        이에 대해 관여할 수도 없으며 어떠한 법적 책임도 부담하지 않습니다.
        귀하의 계정이나 단말을 귀하가 아닌 타인이 사용하여 발생하는 유료 상품
        구매 비용 문제에 대해 회사는 물리적으로나 법적으로 관여할 수 없으므로
        책임을 부담하지 않습니다.
        <br />
        <br />
        15. 가격 책정
        <br />
        회사는 회사의 재량으로 본 서비스 내의 상품 가격을 책정하거나 변경할 수
        있습니다.
        <br />
        <br />
        16. 환불
        <br />
        본 약관에 명시되거나 법률에 따라 달리 규정되지 않은 한, 유료 상품은 구매
        후 환불이 불가합니다. 어떠한 경우에도 아래와 같은 경우에는 환불이
        불가능합니다.
        <br />
        ① 본 약관 위반 또는 위법행위로 서비스 이용이 정지된 사용자의 보유 유료
        상품
        <br />
        ② 부분적으로 사용한 유료 상품
        <br />
        ③ 본 서비스로부터 탈퇴하거나 서비스 앱을 삭제한 사용자의 유료 상품
        <br />
        <br />
        <br />
        17. 서비스 탈퇴
        <br />
        귀하는 귀하의 단말기에서 서비스 앱을 삭제하여 언제든지 본 서비스 사용을
        종료할 수 있습니다. 계정 삭제를 원할 경우 &#39;메뉴 &gt; 설정 &gt; 계정
        관리 &gt; 회원탈퇴&#39; 메뉴를 통해 가능합니다.
        <br />
        <br />
        18. 면책
        <br />
        회사는 회사의 과실 없이 다음 각 호의 경우로 서비스를 제공할 수 없는 경우
        이로 인하여 귀하에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
        <br />
        ① 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
        <br />
        ② 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인
        서비스 방해가 있는 경우
        <br />
        ③ 귀하의 귀책사유로 서비스 이용에 장애가 있는 경우
        <br />
        ④ 제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 인한
        경우
        <br />
        <br />
        19. 양도
        <br />
        귀하는 본 약관상의 권리 및 의무를 양도할 수 없습니다. 회사는 회사의 단독
        재량에 따라 통지 없이 본 약관상의 권리 및 의무, 그리고 회사 자산에 대한
        권리 및 의무를 제3자에게 양도할 수 있습니다.
        <br />
        <br />
        20. 서비스의 변경 또는 중단
        <br />
        ① 회사는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 기술
        진보에 따른 서비스의 전환 필요성, 서비스 제공과 관련한 회사 정책의 변경
        등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고
        있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있습니다.
        <br />
        ② 회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상
        수정, 중단, 변경할 수 있습니다. 사용 기간이 ‘영구’로 표시된 유료
        상품이나 사용 기간이 표시되지 않은 유료 상품은 서비스가 계속되는 동안
        사용할 수 있으며, 본 조항에 따라 서비스가 중단되는 경우, 서비스 중단
        공지 시 공지된 서비스의 중단일자까지를 사용 기간으로 합니다. 보증 기간이
        경과되거나 서비스가 종료되는 경우 그 사용권은 상실될 수 있으며, 귀하는
        서비스 중단에 대한 배상을 청구할 수 없습니다.
        <br />
        ③ 서비스의 변경 또는 중단이 있을 때 회사 측이 판단하여 필요한 경우 변경
        또는 중단될 서비스의 내용에 대하여 서비스 내에 회원이 충분이 인지할 수
        있는 방법으로 사전에 공지합니다.
        <br />
        <br />
        <br />
        21. 준거법 및 재판관할
        <br />
        본 약관은 대한민국법령에 의하여 규정되고 이행되며, 서비스 이용과
        관련하여 회사와 회원 간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를
        관할하는 법원을 합의관할로 합니다.
        <br />
        <br />
        22. 규정의 준용
        <br />
        본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지
        않은 부분에 대하여는 관습에 의합니다.
        <br />
        <br />
        부칙
        <br />이 약관은 2021년 1월 15일부터 적용됩니다. 종전 약관은 본 약관으로
        대체됩니다. 개정된 이용
      </div>
    </Container>
  );
}

const Container = styled.div`
  .inner-wrap {
    padding-bottom: 40px;
  }
`;

export default StackUseTerm;

import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';

function Gateway({ history }: RouteComponentProps): JSX.Element {

  const query = queryString.parse(history.location.search);
  const redirectUrl = encodeURIComponent(query && String(query.redirect));
  // alert(redirectUrl)
  window?.location.replace(`qnamor://${redirectUrl}`);
  return (<></>
  );
}

export default Gateway;

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { iconScrap } from '../../../assets/images/icon';

function OpenSpaceListLoading(): JSX.Element {
  return (
    <div>
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
    </div>
  );
}

function LoadingItem(): JSX.Element {
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => {
      setLoadingAnimation((v) => !v);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container animation={loadingAnimation}>
      <div className="inner-wrap">
        <div className="user-info">
          <div className="thumb" />
          <div className="info-wrap">
            <div className="nickname-wrap" />
            <p className="tags" />
          </div>
        </div>
        <p className="question" />
      </div>
      <div className="image" />
      <div className="inner-wrap">
        <div className="counter" />
      </div>
      <button className="btn-scrap" type="button">
        <span />
      </button>
    </Container>
  );
}

const Container = styled.div<{ animation: boolean }>`
  background-color: #fff;
  position: relative;
  padding: 15px 0;
  margin-top: 15px;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);

  .user-info {
    display: flex;
    margin-bottom: 22px;

    .thumb {
      width: 40px;
      height: 40px;
      background-color: #ccc;
      border-radius: 50%;
      transition: all 0.5s ease;
      opacity: ${({ animation }) => (animation ? '1' : '0.5')};
    }

    .info-wrap {
      flex: 1;
      margin-left: 10px;

      .nickname-wrap {
        width: 120px;
        height: 16px;
        border-radius: 4px;
        background-color: #ccc;
        margin-bottom: 4px;
        transition: all 0.5s ease;
        opacity: ${({ animation }) => (animation ? '1' : '0.5')};
      }

      .tags {
        width: 200px;
        height: 16px;
        border-radius: 4px;
        background-color: #eee;
        transition: all 0.5s ease;
        opacity: ${({ animation }) => (animation ? '1' : '0.5')};
      }
    }
  }

  .question {
    width: 180px;
    height: 18px;
    border-radius: 4px;
    background-color: #ddd;
    margin-bottom: 15px;
    transition: all 0.5s ease;
    opacity: ${({ animation }) => (animation ? '1' : '0.5')};
  }

  .image {
    height: 280px;
    background-color: #eee;
    position: relative;
    transition: all 0.5s ease;
    opacity: ${({ animation }) => (animation ? '1' : '0.5')};
  }

  .counter {
    height: 20px;
    margin-top: 15px;
    display: flex;
  }

  .btn-scrap {
    position: absolute;
    top: 0;
    right: 15px;

    span {
      display: inline-block;
      width: 28px;
      height: 27px;
      background-image: url(${iconScrap});
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
    }
  }
`;

export default OpenSpaceListLoading;

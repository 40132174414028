import React from 'react';
import axios from 'axios';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';
import { UserData } from './types';
import { asyncSetUser, Types } from './actions';

const getUserInfo = async () => {
  const { data } = await axios.get<UserData>(
    'https://api.github.com/users/potato',
  );
  return data;
};

function* setUserSaga() {
  const { success, failure } = asyncSetUser;
  try {
    const userData: UserData = yield call(getUserInfo);
    yield put(success(userData));
  } catch (e: any) {
    yield put(failure(e));
  }
}

export function* userSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(Types.SET_USER, setUserSaga);
}

import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { actions as commonActions } from '../store/common';

interface IReturn {
  fetchMyProfile: () => void;
}

function useMyProfile(): IReturn {
  const dispatch = useDispatch();

  const fetchMyProfile = async () => {
    try {
      const { data } = await axios.get('/me');
      if (data.success) {
        dispatch(commonActions.setValue('myProfile', data.data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return { fetchMyProfile };
}

export default useMyProfile;

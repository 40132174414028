import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { HomeActions, HomeState } from './types';
import { asyncSetDiaries, setValue } from './actions';
import {
  asyncState,
  createAsyncReducer,
  transformToArray,
} from '../../utils/reducerUtils';

const initialState: HomeState = {
  diaries: asyncState.initial([]),
  currentSlideIndex: 0,
  slideToIndex: 0,
  diaryTempData: null,
  questionListDate: {
    year: null,
    month: null,
  },
  questionList: asyncState.initial(),
  questionCalendar: asyncState.initial(),
};

const reducer = createReducer<HomeState, HomeActions>(initialState)
  .handleAction(setValue, (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }))
  .handleAction(
    transformToArray(asyncSetDiaries),
    createAsyncReducer(asyncSetDiaries, 'diaries'),
  );

export default reducer;

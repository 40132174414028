import React from 'react';
import styled from 'styled-components';
import { iconCheckbox } from '../../../assets/images/icon';

interface IProps {
  id?: string;
  className?: string;
  name?: string;
  checked: boolean;
  label?: string;
  onChange: () => void;
}

function Checkbox({
  id,
  className,
  name,
  checked,
  label,
  onChange,
}: IProps): JSX.Element {
  return (
    <Container
      as={label ? 'label' : 'div'}
      checked={checked}
      className={className}
    >
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      {label && <span>{label}</span>}
    </Container>
  );
}

const Container = styled.label<{ checked: boolean }>`
  position: relative;
  width: 20px;
  height: 20px;
  background-image: url(${iconCheckbox});
  background-repeat: no-repeat;
  background-position: ${({ checked }) => (checked ? '0 -20px' : '0 0')};
  background-size: 20px;

  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  span {
    padding-left: 28px;
  }
`;

export default Checkbox;

import axios from 'axios';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { iconDownload, iconScrap } from '../../../assets/images/icon';
import useStack from '../../../hooks/useStack';
import { actions as scrapActions } from '../../../store/scrap';
import { actions as commonActions } from '../../../store/common';

interface IProps {
  type: 'myDiary' | 'openSpace';
  index: number;
  name: string;
  createdAt: string;
  category?: string;
  hashtags?: string[];
  question: string;
  cover: string;
  readyCount?: number;
  diaryId?: number;
  questionId?: number;
  openSpaceId?: number;
  scrapYn: string;
}

function ScrapItem({
  type,
  index,
  name,
  createdAt,
  category,
  hashtags,
  question,
  cover,
  readyCount = 0,
  diaryId,
  questionId,
  openSpaceId,
  scrapYn,
}: IProps): JSX.Element {
  const { addStackScreen } = useStack();
  const onClickDiaryApply = (id: number | null) => {
    dispatch(scrapActions.setValue('selectedOpenSpaceId', id));
    addStackScreen('StackDiarySelect');
  };

  const dispatch = useDispatch();

  const alertWaitingDiary = () => {
    dispatch(
      commonActions.setValue('alertMessage', '대기중인 다이어리가 있어요!'),
    );
  };

  const alertScrapCancel = () => {
    dispatch(
      commonActions.setValue('alertMessage', '스크랩을 해제하였습니다.'),
    );
  };

  const onToggleScrap = useCallback(
    async (e) => {
      e.stopPropagation();

      if (readyCount > 0 && scrapYn === 'Y') {
        return alertWaitingDiary();
      }

      let url = '';
      if (type === 'myDiary') {
        url = `/diaries/${diaryId}/questions/${questionId}/scraps`;
      }
      if (type === 'openSpace') {
        url = `/openspaces/${openSpaceId}/scraps`;
      }

      try {
        const { data } = await axios.put(url, {
          scrapYn: scrapYn === 'Y' ? 'N' : 'Y',
        });
        if (data.success) {
          if (scrapYn === 'Y') alertScrapCancel();

          if (type === 'myDiary') {
            dispatch(scrapActions.toggleScrapMyDiary(index));
          }
          if (type === 'openSpace') {
            dispatch(scrapActions.toggleScrapOpenSpace(index));
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      readyCount,
      type,
      diaryId,
      questionId,
      openSpaceId,
      scrapYn,
      alertWaitingDiary,
      alertScrapCancel,
    ],
  );

  const onClickScrapItem = useCallback(() => {
    if (type === 'openSpace') return;

    dispatch(commonActions.setValue('selectedDiaryId', diaryId));
    dispatch(
      commonActions.setValue(
        'selectedDiaryDate',
        createdAt.replace(/\//g, '-'),
      ),
    );
    addStackScreen('StackDiaryDetail');
  }, [type, diaryId, addStackScreen]);

  return (
    <Container role="button" className="scrap-item" onClick={onClickScrapItem}>
      <div className="padding-wrap">
        <h3 className="name">{name}</h3>
        <div className="info">
          {category && (
            <>
              <span className="category">{category}</span>
              <span className="dot" />
            </>
          )}
          <span>{createdAt}</span>
        </div>
        {hashtags && (
          <p className="hashtags">{hashtags.map((tag) => `#${tag} `)}</p>
        )}
        <p className="question">{question}</p>
        <CoverImage image={cover}>
          {readyCount > 0 && (
            <div className="dim">
              <span className="status">{readyCount}개 대기중</span>
            </div>
          )}
        </CoverImage>
        <BtnScrap
          type="button"
          active={scrapYn === 'Y'}
          onClick={(e) => onToggleScrap(e)}
        >
          <span />
        </BtnScrap>
      </div>
      {type === 'openSpace' && (
        <div className="bottom">
          {readyCount === 0 && (
            <button
              type="button"
              onClick={() => onClickDiaryApply(openSpaceId || null)}
            >
              <span>적용하기</span>
            </button>
          )}
          {readyCount > 0 && (
            <button
              type="button"
              onClick={() => onClickDiaryApply(openSpaceId || null)}
            >
              <span>변경하기</span>
            </button>
          )}
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 120px;
  }

  .padding-wrap {
    min-height: 140px;
    padding: 15px;

    .name {
      font-size: 1.2rem;
      font-family: 'NanumSquare';
      font-weight: 700;
      margin-bottom: 3px;
    }

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.gray1};
        font-family: 'NanumSquare';

        &.dot {
          display: inline-block;
          width: 2px;
          height: 2px;
          background-color: ${({ theme }) => theme.color.gray1};
          border-radius: 50%;
          margin: 0 5px;
        }
      }
    }

    .hashtags {
      width: 65%;
      font-size: 1.1rem;
      font-family: 'NanumSquare';
      color: ${({ theme }) => theme.color.gray1};
      margin-bottom: 5px;
      word-break: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .question {
      width: 65%;
    }
  }

  .bottom {
    padding: 10px 15px;
    border-top: 1px solid ${({ theme }) => theme.color.gray3};
    display: flex;
    justify-content: space-between;

    button {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        margin-right: 6px;
      }

      span {
        font-size: 1.2rem;
      }
    }
  }
`;

const BtnScrap = styled.button<{ active: boolean }>`
  position: absolute;
  top: 0;
  right: 15px;

  span {
    display: inline-block;
    width: 28px;
    height: 27px;
    background-image: url(${iconScrap});
    background-repeat: no-repeat;
    background-position: ${({ active }) => (active ? '0 -28px' : '0 0')};
    background-size: 28px;
  }
`;

const CoverImage = styled.div<{ image: string }>`
  width: 100px;
  height: 70px;
  border-radius: 6px;
  background-image: url(${({ image }) => image && image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 55px;
  right: 15px;
  overflow: hidden;

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      font-size: 1.2rem;
      color: #fff;
      font-weight: 700;
    }
  }
`;

export default ScrapItem;

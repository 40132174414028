// declare global {
//   interface Window {
//     // eslint-disable-next-line camelcase
//     flutter_inappwebview: any;
//   }
// }

type NavigatorTypes = [showNavigator: () => void, hideNavigator: () => void];

function useNavigator(): NavigatorTypes {
  const showNavigator = () => {
    console.log('show');
    try {
      const url = window?.location.href;
      if (url.indexOf('login') === -1) {
        window?.flutter_inappwebview?.callHandler('showBottomNavigation', []);
      }
      // window?.flutterHandler('showBottomNavigation', []);
    } catch (e: any) {
      // alert(e.message)
    }
  };

  const hideNavigator = () => {
    try {
      console.log('hide');
      window?.flutter_inappwebview?.callHandler('hideBottomNavigation', []);
    } catch (e: any) {
      // alert(e.message);
    }
  };

  return [showNavigator, hideNavigator];
}

export default useNavigator;

import React from 'react';
import styled from 'styled-components';
import StackHeader from '../../../layouts/StackHeader';

function StackPrivacyPolicy(): JSX.Element {
  return (
    <Container>
      <StackHeader title="개인정보 처리방침" />
      <div className="inner-wrap">
        <br />
        아모르 개인정보처리방침
        <br />
        <br />
        MARKERBIZ GLOBAL INC.(이하 “회사”라 합니다)는 개인정보보호법, 정보통신망
        이용촉진 및 정보보호 등에 관한 법률 등 관련법령에서
        정보통신서비스제공자가 준수하여야 할 개인정보보호 관련사항을 준수하며,
        개인정보처리방침을 정하여 이용자의 권익보호에 최선을 다하고 있습니다.
        <br />
        개인정보처리방침은 회사가 제공하는 아모르 서비스(이하 “서비스”라 합니다)
        이용에 적용되며 다음과 같은 내용을 담고 있습니다. 또한, 회사는
        개인정보처리방침을 통해 이용자의 개인정보가 어떻게 수집, 이용되고
        있으며, 회사가 개인정보보호를 위해 어떠한 조치를 취하고 있는지
        알려드립니다.
        <br />
        이용자는 개인정보의 수집, 이용, 제공, 위탁 등과 관련된 사항에 대해
        원하지 않을 경우 동의를 거부할 수 있습니다. 다만, 이용자가 동의를
        거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을 알려드립니다.
        <br />
        <br />
        1. 수집하는 개인정보의 항목 <br />
        가. 수집하는 개인정보의 항목
        <br />
        회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 서비스 최초
        이용 시 다음과 같은 개인정보 수집하고 있습니다.
        <br />
        1) 필수항목: 닉네임
        <br />
        2) 선택항목: 프로필 사진
        <br />
        3) 서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은
        정보들이 자동으로 생성되어 수집될 수 있습니다. <br />
        서비스 이용기록, 접속 로그(방문 일시), 쿠키, 기기 고유 식별번호(기기
        아이디 혹은 IMEI), 접속 IP주소 정보, 불량 이용 기록, 이메일 주소 등
        <br />
        <br />
        나. 개인정보 수집방법
        <br />
        회사는 다음과 같은 방법으로 개인정보를 수집합니다.
        <br />
        - 서비스의 실행 또는 사용 과정에서 수집
        <br />
        - 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집
        <br />
        - 생성정보 수집 툴을 통한 수집
        <br />
        <br />
        <br />
        2. 개인정보의 수집 및 이용목적
        <br />
        회사는 수집한 개인정보를 다음과 같은 목적으로 이용합니다.
        <br />
        <br />
        가. 서비스 제공
        <br />
        이용자가 가입한 서비스 내 상호 식별
        <br />
        <br />
        나. 회원관리
        <br />
        회원제 서비스 제공, 회원식별, 불량회원(아모르 이용약관 제8조에 따라 이용
        제한된 회원)의 부정 이용방지와 비인가 사용방지, 가입의사 확인, 분쟁
        조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
        <br />
        <br />
        다. 신규 서비스 개발 및 마케팅·광고 활용
        <br />
        신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공
        및 광고 게재, 서비스의 유효성 확인, 이벤트 참여기회 제공 또는 광고성
        정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계
        <br />
        <br />
        3. 개인정보의 제공 및 공유
        <br />
        - 회사는 이용자가 별도로 동의하지 않는 경우를 제외하고는 이용자의
        개인정보를 위에서 언급한 목적 범위를 초과하여 이용하거나 제3자에게 제공
        또는 공유하지 않습니다. 다만, 아래의 경우는 예외로 합니다.
        <br />
        <br />
        가. 서비스에 제3자의 서비스가 연결되어 제공될 경우 회사는 서비스 이용을
        위해 필요한 범위 내의 제공에 대하여 이용자의 동의를 얻은 경우
        <br />
        <br />
        나. 법령의 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
        <br />
        <br />
        4. 개인정보의 취급위탁
        <br />
        회사는 서비스의 원활한 제공을 위해 필요한 때에는 개인정보의 취급을 일부
        위탁 처리할 수 있습니다. 위탁처리 기관 및 위탁업무의 내용은 다음과
        같습니다.
        <br />
        <br />
        - 수탁업체 : Amazon Web Services Inc.
        <br />
        - 위탁업무 : 안정적인 서버 및 서비스 운영, 데이터베이스 및 각종 정보
        등의 스토리지 저장 등<br />
        - 보유 및 이용기간 : 회원 탈퇴 시 또는 위탁계약 종료 시까지
        <br />
        <br />
        5. 개인정보의 보유 및 이용기간
        <br />
        이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
        지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한
        기간 동안 보존합니다.
        <br />
        <br />
        가. 계약 또는 청약철회 등에 관한 기록
        <br />
        - 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간: 5년
        <br />
        <br />
        나. 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        - 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간: 5년
        <br />
        <br />
        다. 소비자의 불만 또는 분쟁처리에 관한 기록
        <br />
        - 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 3년
        <br />
        <br />
        라. 서비스 이용기록, 접속 로그, 접속 IP 정보
        <br />
        - 보존 이유: 통신비밀보호법
        <br />
        - 보존 기간: 3개월
        <br />
        <br />
        6. 개인정보 파기절차 및 방법
        <br />
        회사는 개인정보 보유기간의 경과 혹은 개인정보의 수집 및 이용목적의 달성
        등 개인정보가 불필요하게 되었을 때에는 해당 개인정보를 지체 없이
        파기합니다. 파기절차 및 방법은 다음과 같습니다.
        <br />
        <br />
        가. 파기절차
        <br />
        1) 이용자가 서비스 신청 등을 위해 입력한 정보는 목적이 달성된 후 별도의
        DB로 옮겨져(종이의 경우 별도의 잠금장치가 있는 서류보관함) 내부 방침 및
        기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정
        기간 저장된 후 파기됩니다.
        <br />
        2) 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른
        목적으로 이용되지 않습니다.
        <br />
        <br />
        나. 파기방법
        <br />
        1) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
        파기합니다.
        <br />
        2) 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
        방법을 사용하여 삭제합니다.
        <br />
        <br />
        다. SNS연동(Facebook) 개인정보 데이터 삭제
        <br />
        Facebook 메뉴 &gt; 설정 &gt; 보안 &gt; 앱과 웹사이트 &gt; Facebook
        계정으로 로그인한 앱 및 웹사이트 &gt; 연동을 해제할 앱 선택 &gt; 앱 삭제
        <br />
        <br />
        7. 이용자 및 법정대리인의 권리와 그 행사방법
        <br />
        - 이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수
        있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나
        가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의
        일부 또는 전부 이용이 어려울 수 있습니다.
        <br />
        - 이용자가 자신의 개인정보를 조회하거나, 수정하기 위해서는 애플리케이션
        내 &#39;메뉴&#39;를, 가입해지(동의철회)를 위해서는 &#34;메뉴 &gt; 설정
        &gt; 계정 관리 &gt; 회원탈퇴&#34;를 클릭하여 직접 탈퇴가 가능합니다.
        혹은 개인정보관리책임자에게 이메일로 연락하시면 지체 없이
        조치하겠습니다.
        <br />
        - 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
        완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지하여 정정이 이루어지도록 하겠습니다.
        <br />
        - 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보는 &#34;5.
        개인정보의 보유 및 이용기간&#34;에 명시된 바에 따라 처리하고 그 외의
        용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        <br />
        <br />
        8. 개인정보의 기술적, 관리적 보호 대책
        <br />
        회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
        변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적,
        관리적 대책을 강구하고 있습니다.
        <br />
        <br />
        가. 개인정보 암호화
        <br />
        회사는 관련 법률규정 또는 내부정책에 따라 개인정보를 암호화하여 안전하게
        저장 및 관리하고 있습니다.
        <br />
        <br />
        나. 해킹 등에 대비한 기술적 대책
        <br />
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을
        설치하고, 침입 차단장치 이용 및 침입 탐지시스템을 설치하여 24시간
        감시하고 있습니다.
        <br />
        <br />
        다. 개인정보처리시스템 접근 제한
        <br />
        회사는 개인정보를 처리할 수 있도록 체계적으로 구성한
        데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을
        수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타
        개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
        <br />
        <br />
        라. 개인정보 취급 직원의 교육
        <br />
        개인정보관련 취급 직원은 담당자에 한정시켜 최소화하고 새로운 보안기술의
        습득 및 개인정보 보호 의무에 관해 정기적인 교육을 실시하며 별도의
        비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고
        있습니다.
        <br />
        <br />
        마. 개인 계정 관리
        <br />
        이용자가 사용하는 계정은 원칙적으로 이용자만이 사용하도록 되어 있습니다.
        회사는 이용자의 개인적인 부주의로 개인정보가 유출되어 발생한 문제와
        기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지
        않습니다.
        <br />
        <br />
        9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항 <br />
        회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해서 이용자들의
        정보를 저장하고 수시로 불러오는 &#34;쿠키(cookie)&#34;를 사용합니다.
        쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터
        브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의
        하드디스크, 단말기의 저장소에 저장되기도 합니다.
        <br />
        <br />
        가. 쿠키의 사용 목적
        <br />
        이용자들의 편리한 기능을 제공하기 위하여 활용되며 유해한 목적으로는
        활용되지 않습니다.
        <br />
        <br />
        나. 쿠키의 설치/운영 및 거부
        <br />
        1) 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
        웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
        저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
        있습니다. 다만, 쿠키의 저장을 거부할 경우에는 이용에 어려움이 있을 수
        있습니다.
        <br />
        <br />
        2) 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 설정방법 예(인터넷
        익스플로어의 경우): 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt;
        개인정보
        <br />
        <br />
        10. 개인정보관리책임자 및 담당자의 연락처
        <br />
        귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련
        민원을 개인정보 관리담당자 혹은 담당부서로 신고하실 수 있습니다. 회사는
        이용자들의 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다.
        <br />
        <br />
        개인정보 관리책임자
        <br />
        이름: 이창희
        <br />
        직위: 이사
        <br />
        전화: 070-5129-4962
        <br />
        메일: changsea@markerbiz.com
        <br />
        <br />
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <br />
        - 개인정보침해신고센터(privacy.kisa.or.kr / 국번없이 118)
        <br />
        - 대검찰청 사이버수사과(www.spo.go.kr / 국번없이 1301)
        <br />
        - 경찰청 사이버안전국 (www.cyber.go.kr/ 국번없이 182)
        <br />
        <br />
        <br />
        11. 고지의 의무
        <br />
        이 개인정보처리방침은 2020년 10월 01일부터 적용되며, 법령. 정책 또는
        보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는
        변경사항의 시행일의 7일 전부터 서비스 내의 공지사항을 통하여 고지할
        것입니다. 이용자가 해당 일 이후에도 서비스를 계속 이용함으로써 변경된
        방침에 동의하는 것으로 간주됩니다.
        <br />
        <br />
        부칙 이 약관은 2021년 1월 15일부터 적용됩니다. 종전 약관은 본 약관으로
        대체됩니다. 개정된 이용약관의 적용일자 이전 가입자 또한 개정된
        이용약관의 적용을 받습니다.
      </div>
    </Container>
  );
}

const Container = styled.div`
  .inner-wrap {
    padding-bottom: 40px;
  }
`;

export default StackPrivacyPolicy;

import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../store/common';

interface UtilTypes {
  addStackScreen: (screenName: string) => void;
  replaceStackScreen: (screenName: string) => void;
  removeStackScreen: () => void;
  clearStackScreen: () => void;
}

function useStack(): UtilTypes {
  const dispatch = useDispatch();

  const addStackScreen = (screenName: string) => {
    dispatch(actions.addStackScreen(screenName));
  };

  const replaceStackScreen = (screenName: string) => {
    dispatch(actions.replaceStackScreen(screenName));
  };

  const removeStackScreen = () => {
    dispatch(actions.removeStackScreen());
  };

  const clearStackScreen = () => {
    dispatch(actions.clearStackScreen());
  };

  return {
    addStackScreen,
    replaceStackScreen,
    removeStackScreen,
    clearStackScreen,
  };
}

export default useStack;

import React from 'react';
import styled from 'styled-components';
import StackProfileForm from '../../common/StackProfileForm';
import StackAlarm from '../../common/StackAlarm';
import StackMyDiary from '../../sidemenu/StackMyDiary';
import StackEndedDiary from '../../sidemenu/StackEndedDiary';
import StackSettings from '../../sidemenu/StackSettings';
import StackSettingAlarm from '../../sidemenu/StackSettingAlarm';
import StackSettingScreenLock from '../../sidemenu/StackSettingScreenLock';
import StackSettingLanguage from '../../sidemenu/StackSettingLanguage';
import StackCreateDiary from '../../home/StackCreateDiary';
import StackSendInvitation from '../../home/StackSendInvitation';
import StackInvitationWaiting from '../../home/StackInvitationWaiting';
import StackSetQuestionTime from '../../home/StackSetQuestionTime';
import StackDiaryDetail from '../../common/StackDiaryDetail';
import StackDiaryQnaList from '../../common/StackDiaryQnaList';
import StackDiarySetting from '../../common/StackDiarySetting';
import StackWriteDiaryAnswer from '../../common/StackWriteDiaryAnswer';
import StackEditDiaryAnswer from '../../common/StackEditDiaryAnswer';
import StackDiaryQnaCalendar from '../../common/StackDiaryQnaCalendar';
import StackQuestionFeedDetail from '../../openSpace/StackQuestionFeedDetail';
import StackCreateQuestion from '../../openSpace/StackCreateQuestion';
import StackMyQuestionManagement from '../../openSpace/StackMyQuestionManagement';
import StackDiarySelect from '../../scrap/StackDiarySelect';
import StackUseTerm from '../../sidemenu/StackUseTerm';
import StackPrivacyPolicy from '../../sidemenu/StackPrivacyPolicy';
import StackAccountManagement from '../../sidemenu/StackAccountManagement';
import StackWithdrawal from '../../sidemenu/StackWithdrawal';
import StackGuide from '../../sidemenu/StackGuide';
import StackFaq from '../../sidemenu/StackFaq';
import StackNotice from '../../sidemenu/StackNotice';
import StackNoticeDetail from '../../sidemenu/StackNoticeDetail';

interface StackContentProps {
  screenName: string;
}

function StackContent({ screenName }: StackContentProps): JSX.Element {
  return (
    <Container>
      {screenName === 'StackProfileForm' && <StackProfileForm />}
      {screenName === 'StackAlarm' && <StackAlarm />}
      {screenName === 'StackMyDiary' && <StackMyDiary />}
      {screenName === 'StackEndedDiary' && <StackEndedDiary />}
      {screenName === 'StackSettings' && <StackSettings />}
      {screenName === 'StackSettingAlarm' && <StackSettingAlarm />}
      {screenName === 'StackSettingScreenLock' && <StackSettingScreenLock />}
      {screenName === 'StackSettingLanguage' && <StackSettingLanguage />}
      {screenName === 'StackCreateDiary' && <StackCreateDiary />}
      {screenName === 'StackSendInvitation' && <StackSendInvitation />}
      {screenName === 'StackInvitationWaiting' && <StackInvitationWaiting />}
      {screenName === 'StackSetQuestionTime' && <StackSetQuestionTime />}
      {screenName === 'StackDiaryDetail' && <StackDiaryDetail />}
      {screenName === 'StackDiaryQnaList' && <StackDiaryQnaList />}
      {screenName === 'StackDiarySetting' && <StackDiarySetting />}
      {screenName === 'StackWriteDiaryAnswer' && <StackWriteDiaryAnswer />}
      {screenName === 'StackEditDiaryAnswer' && <StackEditDiaryAnswer />}
      {screenName === 'StackDiaryQnaCalendar' && <StackDiaryQnaCalendar />}
      {screenName === 'StackQuestionFeedDetail' && <StackQuestionFeedDetail />}
      {screenName === 'StackCreateQuestion' && <StackCreateQuestion />}
      {screenName === 'StackMyQuestionManagement' && (
        <StackMyQuestionManagement />
      )}
      {screenName === 'StackDiarySelect' && <StackDiarySelect />}
      {screenName === 'StackUseTerm' && <StackUseTerm />}
      {screenName === 'StackPrivacyPolicy' && <StackPrivacyPolicy />}
      {screenName === 'StackAccountManagement' && <StackAccountManagement />}
      {screenName === 'StackWithdrawal' && <StackWithdrawal />}
      {screenName === 'StackGuide' && <StackGuide />}
      {screenName === 'StackFaq' && <StackFaq />}
      {screenName === 'StackNotice' && <StackNotice />}
      {screenName === 'StackNoticeDetail' && <StackNoticeDetail />}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;

export default StackContent;

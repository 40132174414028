import React from 'react';
import styled from 'styled-components';

interface IProps {
  url: string;
  hideZoomView: () => void;
}

function ZoomView({ url, hideZoomView }: IProps): JSX.Element {
  return (
    <Container>
      <img src={url} alt="이미지" />
      <div role="banner" className="dim" onClick={hideZoomView} />
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .dim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export default ZoomView;

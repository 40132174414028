import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { ISortingOption } from '../../../pages/Scrap';
import Button from '../../parts/Button';

interface IProps {
  selectedTabMenu: string;
  categoryId: number | null;
  monthValue: number | null;
  setSortingOption: (category: number | null, month: number | null) => void;
}

function FilterSetting({
  categoryId,
  monthValue,
  selectedTabMenu,
  setSortingOption,
}: IProps): JSX.Element {
  const [category, setCategory] = useState(categoryId);
  const [month, setMonth] = useState(monthValue);

  const initFilter = useCallback(() => {
    setCategory(null);
    setMonth(3);
  }, []);

  const submitFilterOption = useCallback(() => {
    setSortingOption(category, month);
  }, [category, month]);

  return (
    <Container>
      {selectedTabMenu === 'My Diary' && (
        <section className="category">
          <h3 className="title">카테고리</h3>
          <div>
            <FilterBtn
              active={category === null}
              onClick={() => setCategory(null)}
            >
              전체보기
            </FilterBtn>
          </div>
          <div>
            <FilterBtn active={category === 1} onClick={() => setCategory(1)}>
              나
            </FilterBtn>
            <FilterBtn active={category === 2} onClick={() => setCategory(2)}>
              가족
            </FilterBtn>
            <FilterBtn active={category === 3} onClick={() => setCategory(3)}>
              연인
            </FilterBtn>
            <FilterBtn active={category === 4} onClick={() => setCategory(4)}>
              친구
            </FilterBtn>
          </div>
        </section>
      )}
      <section>
        <h3 className="title">기간설정</h3>
        <div>
          <FilterBtn active={month === null} onClick={() => setMonth(null)}>
            전체보기
          </FilterBtn>
          <FilterBtn active={month === 1} onClick={() => setMonth(1)}>
            1개월
          </FilterBtn>
          <FilterBtn active={month === 3} onClick={() => setMonth(3)}>
            3개월
          </FilterBtn>
          <FilterBtn active={month === 6} onClick={() => setMonth(6)}>
            6개월
          </FilterBtn>
        </div>
      </section>
      <div className="bottom-btns">
        <Button style={{ width: '80px' }} outline="gray" onClick={initFilter}>
          초기화
        </Button>
        <Button className="btn-submit" onClick={submitFilterOption}>
          결과보기
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  padding: 20px 0;

  section {
    margin-bottom: 25px;

    &.category {
      border-bottom: 1px solid ${({ theme }) => theme.color.gray3};
    }

    h3.title {
      font-size: 1.8rem;
      margin-bottom: 25px;
    }
  }

  .bottom-btns {
    display: flex;
    margin-top: 80px;

    .btn-submit {
      flex: 1;
      margin-left: 12px;
    }
  }
`;

const FilterBtn = styled.button<{ active: boolean }>`
  display: inline-block;
  margin-right: 8px;
  padding: 4px 15px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.gray1};
  border: 1px solid ${({ theme }) => theme.color.gray2};
  border-radius: 25px;
  margin-bottom: 16px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.color.main1};
      border: 1px solid ${({ theme }) => theme.color.main1};
      color: #fff;
    `}
`;

export default FilterSetting;

import React from 'react';
import StackLayer1 from './StackLayer1';
import StackLayer2 from './StackLayer2';
import StackLayer3 from './StackLayer3';

function Stack(): JSX.Element {
  return (
    <div>
      <StackLayer1 />
      <StackLayer2 />
      <StackLayer3 />
    </div>
  );
}

export default Stack;

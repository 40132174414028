import { createAsyncAction, deprecated } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { UserData } from './types';

const { createAction, createStandardAction } = deprecated;

export const Types = {
  SET_VALUE: 'common/SET_VALUE' as const,
  ADD_STACK_SCREEN: 'common/ADD_STACK_SCREEN' as const,
  REMOVE_STACK_SCREEN: 'common/REMOVE_STACK_SCREEN' as const,
  REPLACE_STACK_SCREEN: 'common/REPLACE_STACK_SCREEN' as const,
  CLEAR_STACK_SCREEN: 'common/CLEAR_STACK_SCREEN' as const,
  SET_USER: 'common/SET_USER' as const,
  SET_USER_SUCCESS: 'common/SET_USER_SUCCESS' as const,
  SET_USER_ERROR: 'common/SET_USER_ERROR' as const,
  QUESTION_SCRAP_TOGGLE: 'common/QUESTION_SCRAP_TOGGLE' as const,
  COMMENT_LIKE_TOGGLE_ON_DIARY: 'common/COMMENT_LIKE_TOGGLE_ON_DIARY' as const,
  REPLY_LIKE_TOGGLE_ON_DIARY: 'common/REPLY_LIKE_TOGGLE_ON_DIARY' as const,
  DELETE_COMMENT_ON_DAIRY: 'common/DELETE_COMMENT_ON_DAIRY' as const,
};

export const setValue = createAction(
  Types.SET_VALUE,
  (action) => (key: string, value: any) =>
    action({
      key,
      value,
    }),
);
export const addStackScreen = createAction(
  Types.ADD_STACK_SCREEN,
  (action) => (screenName: string) =>
    action({
      screenName,
    }),
);
export const removeStackScreen = createStandardAction(
  Types.REMOVE_STACK_SCREEN,
)();
export const replaceStackScreen = createAction(
  Types.REPLACE_STACK_SCREEN,
  (action) => (screenName: string) =>
    action({
      screenName,
    }),
);
export const clearStackScreen = createStandardAction(
  Types.CLEAR_STACK_SCREEN,
)();

export const questionScrapToggle = createStandardAction(
  Types.QUESTION_SCRAP_TOGGLE,
)();
export const commentLikeToggleOnDiary = createAction(
  Types.COMMENT_LIKE_TOGGLE_ON_DIARY,
  (action) => (index: number) =>
    action({
      index,
    }),
);
export const replyLikeToggleOnDiary = createAction(
  Types.REPLY_LIKE_TOGGLE_ON_DIARY,
  (action) => (commentIndex: number, replyIndex: number) =>
    action({
      commentIndex,
      replyIndex,
    }),
);
export const deleteCommentOnDiary = createAction(
  Types.DELETE_COMMENT_ON_DAIRY,
  (action) => (commentId: number) =>
    action({
      commentId,
    }),
);

export const asyncSetUser = createAsyncAction(
  Types.SET_USER,
  Types.SET_USER_SUCCESS,
  Types.SET_USER_ERROR,
)<any, UserData, AxiosError>();

export const actions = {
  setValue,
  addStackScreen,
  removeStackScreen,
  replaceStackScreen,
  clearStackScreen,
  asyncSetUser,
  questionScrapToggle,
  commentLikeToggleOnDiary,
  replyLikeToggleOnDiary,
  deleteCommentOnDiary,
};

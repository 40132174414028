import axios, { AxiosRequestConfig } from 'axios';

function useAxiosConfiguration(history: any, token: string): void {
  try {
    axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

    axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const requestUrl = config.url || '/';
        const apiHost = process.env.REACT_APP_API_HOST;
        const temp = requestUrl.replace(apiHost || '', '');
        if (temp.indexOf('http') >= 0) {
          config.headers.Authorization = null;
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      async (error) => {
        const {
          config,
          response: { status },
        } = error;
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      // status 200 || 201
      (res) => res,
      // status 400 || 500
      async (error) => {
        const {
          config,
          response: { status },
        } = error;

        if (status === 401) {
          console.log(error.response);
          // 401 인증 실패시
          // if (error.response.statusText === 'Unauthorized') {
          localStorage.removeItem('accessToken');

          // setRedirectUrl();
          window.location.href = '/login';
          // }
          return Promise.reject(error);
        }

        return Promise.reject(error);
      },
    );
  } catch (e: any) {
    alert(e.message);
  }
}

function setRedirectUrl() {
  const locationHref = window.location.href;
  const query = locationHref.split('?')[1];
  if (query) {
    alert('redirectUrl yes');
    window.location.href = `/login?redirectUrl=${window.location.href}`;
  } else {
    alert('redirectUrl no');
    window.location.href = `/login`;
  }
}

export default useAxiosConfiguration;

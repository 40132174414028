import React, {
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import axios from 'axios';
import {
  btnCamera,
  btnClose,
  btnCloseCircleGray,
  sampleImage1,
} from '../../../assets/images';
import { actions as commonActions } from '../../../store/common';
import { iconArrowSmall, iconLock } from '../../../assets/images/icon';
import StackHeader from '../../../layouts/StackHeader';
import BottomToastModal from '../../common/BottomToastModal';
import Input from '../../parts/Input';
import ModalConfirm from '../../common/ModalConfirm';
import useStack from '../../../hooks/useStack';
import { RootState } from '../../../store';
import LoadingLayer from '../../common/LoadingLayer';
import Checkbox from '../../parts/Checkbox';

interface Attachment {
  id?: number | null;
  file?: Blob | string;
  isChanged: boolean;
  isDeleted: boolean;
  type: string;
  url: string;
  default: boolean;
}

interface FormDataTypes {
  attachments: Attachment;
  content: string;
  isOpened: boolean;
  scrapsCount: number;
  defaultImage: string;
  tags: string[];
}

function StackCreateQuestion(): JSX.Element {
  const dispatch = useDispatch();
  const { addStackScreen, removeStackScreen } = useStack();
  const { selectedOpenSpaceId, fetchOpenSpaceFn } = useSelector(
    (state: RootState) => state.common,
  );

  const [defaultHashtags, setDefaultHashtags] = useState([
    { id: 0, name: '일상', isSelected: false },
    { id: 1, name: '연애', isSelected: false },
    { id: 2, name: '결혼', isSelected: false },
    { id: 3, name: '육아', isSelected: false },
    { id: 4, name: '아이돌', isSelected: false },
    { id: 5, name: '운동', isSelected: false },
    { id: 6, name: '직장', isSelected: false },
  ]);

  const [formData, setFormData] = useState<FormDataTypes>({
    attachments: {
      file: '',
      isChanged: false,
      isDeleted: false,
      type: 'image',
      url: '',
      default: true,
    },
    content: '',
    isOpened: true,
    scrapsCount: 0,
    defaultImage: '',
    tags: [],
  });

  const [selectedHashtags, setSelectedHashtags] = useState<string[] | []>([]);

  const [inputTag, setInputTag] = useState('');

  const onClickBtnHashtag2 = (
    id: number,
    name: string,
    isSelected: boolean,
  ) => {
    let isChage = false;
    if (!isSelected && selectedHashtags.length < 5) {
      setSelectedHashtags((selectedHashtags) => [...selectedHashtags, name]);
      isChage = true;
    } else if (isSelected) {
      setSelectedHashtags(selectedHashtags.filter((tag) => tag !== name));
      isChage = true;
    }

    if (isChage) {
      setDefaultHashtags(
        defaultHashtags.map((tag) => {
          if (tag.name === name) {
            return { ...tag, isSelected: !tag.isSelected };
          }
          return tag;
        }),
      );
    }
  };

  useEffect(() => {
    // console.log("selectedHashtags.length", selectedHashtags.length)
    if (selectedHashtags && selectedHashtags.length > 0) {
      setEmptyTagMsg(false);
    }
    // else {
    //   setEmptyTagMsg(true);
    // }
  }, [selectedHashtags]);

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, content: e.target.value });
  };

  useEffect(() => {
    // console.log("useEffect")
    if (selectedOpenSpaceId) {
      axios.get(`/openspaces/${selectedOpenSpaceId}/detail`).then((res) => {
        if (res.data && res.data.success) {
          const resData = res.data.openSpace;
          // console.log('resData',resData);

          const data = {
            attachments: resData.attachments,
            content: resData.content,
            isOpened: resData.isOpened,
            scrapsCount: resData.scrapsCount,
            defaultImage: '',
            tags: resData.tags,
          };

          setFormData(data);
          setSelectedHashtags([...resData.tags]);
          const joinTags = `#${resData.tags.join('#')}`;
          setDefaultHashtags(
            defaultHashtags.map((tagObj) =>
              joinTags.indexOf(tagObj.name) >= 0
                ? {
                    ...tagObj,
                    isSelected: true,
                  }
                : tagObj,
            ),
          );
        }
      });
    }
  }, []);

  const onClickTagDel = (delTag: string) => {
    setSelectedHashtags(selectedHashtags.filter((tag) => tag !== delTag));

    const newDefaultHashtags = [...defaultHashtags];
    const targetHashtag = newDefaultHashtags.find(
      (item) => item.name === delTag,
    );
    if (targetHashtag) {
      targetHashtag.isSelected = false;
    }
    setDefaultHashtags(newDefaultHashtags);
  };

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTag(e.target.value);
  };

  const onClickHashTagBtn = () => {
    if (!inputTag) return;

    if (selectedHashtags.length >= 5) {
      return;
    }
    const newDefaultHashtags = [...defaultHashtags];
    const targetHashtag = newDefaultHashtags.find(
      (item) => item.name === inputTag,
    );

    if (targetHashtag) {
      targetHashtag.isSelected = !targetHashtag.isSelected;
    }
    setDefaultHashtags(newDefaultHashtags);
    setSelectedHashtags((selectedHashtags) => [...selectedHashtags, inputTag]);
    setInputTag('');
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      onClickHashTagBtn();
    }
  };

  const textareaAlertMessage = () => {
    dispatch(commonActions.setValue('alertMessage', '텍스트를 입력해주세요.'));
  };

  const [modalConfirmView, setModalConfirmView] = useState(false);
  const [emptyTagMsg, setEmptyTagMsg] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const onClickSaveDiary = async () => {
    if (!isAgreeUseTerm)
      return dispatch(
        commonActions.setValue(
          'alertMessage',
          '이용약관에 동의하셔야 등록이 가능합니다.',
        ),
      );

    const fData = { ...formData };

    if (!fData.content) {
      textareaAlertMessage();
      return;
    }
    if (!selectedHashtags || selectedHashtags.length === 0) {
      setEmptyTagMsg(true);
      return;
    }

    setIsUploading(true);

    const fd = new FormData();

    if (fData.attachments.id) {
      fd.append(`attachments[id]`, String(fData.attachments.id));
    }
    fd.append(`attachments[file]`, fData.attachments.file || '');
    fd.append(
      `attachments[isChanged]`,
      fData.attachments.isChanged ? '1' : '0',
    );
    fd.append(`attachments[type]`, fData.attachments.type || 'image');
    fd.append(
      `attachments[isDeleted]`,
      fData.attachments.isDeleted ? '1' : '0',
    );
    // } else {
    //   fd.append('defaultImage', 'me_01.jpg');
    //   fd.append(`attachments[isChanged]`, '0');
    //   fd.append(`attachments[isDeleted]`, (fData.attachments.isDeleted ? '1' : '0'));
    // }

    if (!fData.attachments.file) {
      const defaultImagePreFix = ['me', 'couple', 'family', 'friend'];
      const prefixNo = Math.ceil(Math.random() * 4);
      const postfixNo = Math.ceil(Math.random() * 10);
      const defaultImage = `${defaultImagePreFix[prefixNo - 1]}_${
        postfixNo < 10 ? 0 : ''
      }${postfixNo}.jpg`;
      fd.append('defaultImage', defaultImage);
    }

    selectedHashtags.forEach((tag) => {
      fd.append('tags[]', tag);
    });
    fd.append('isOpened', fData.isOpened ? '1' : '0');
    fd.append('content', fData.content);

    try {
      let resData = null;
      if (!selectedOpenSpaceId) {
        resData = await axios.post('/openspaces', fd);
      } else {
        fd.append('_method', 'put');
        resData = await axios.post(`/openspaces/${selectedOpenSpaceId}`, fd);
      }

      if (resData && resData.status === 200 && resData.data.success) {
        setIsUploading(false);
        dispatch(commonActions.setValue('isWatchedAd', false));
        if (!selectedOpenSpaceId) {
          removeStackScreen();
        } else {
          removeStackScreen();
        }
        fetchOpenSpaceFn('New');
      }
    } catch (e: any) {
      console.log('save diary error : ', e);
      alert(e.message);
    }
  };

  const onChangeImage = (e: any) => {
    const file = e.target.files[0];
    let url = null;
    if (file && file.type.match(/^image\/(png|jpeg)$/)) {
      url = URL.createObjectURL(file);
      setFormData({
        ...formData,
        attachments: {
          file,
          isChanged: true,
          isDeleted: false,
          type: 'image',
          url,
          default: false,
          id: formData.attachments.id,
        },
      });
    } else {
      // setErrorMessage('이미지 파일만 등록할 수 있습니다.');
      // return;
    }
  };

  const onChangeOpen = (e: any) => {
    setFormData({
      ...formData,
      isOpened: e.target.value === '1',
    });
  };

  const [isAgreeUseTerm, setIsAgreeUseTerm] = useState(false);

  const onClickShowUseTerm = () => {
    addStackScreen('StackUseTerm');
  };

  const onChangeCheckBox = () => {
    setIsAgreeUseTerm((v) => !v);
  };

  return (
    <Container>
      <StackHeader
        title="질문지 만들기"
        btnRight={
          <BtnSave
            type="button"
            active={formData.content.length > 0 && selectedHashtags.length > 0}
            onClick={onClickSaveDiary}
          >
            SAVE
          </BtnSave>
        }
      />

      <Content>
        <div className="inner-wrap">
          <BtnSelectPublic>
            <span className="icon-lock" />
            <select onChange={onChangeOpen}>
              <option value="0" selected={!formData.isOpened}>
                비공개
              </option>
              <option value="1" selected={formData.isOpened}>
                공개
              </option>
            </select>
            <span className="icon-arrow" />
          </BtnSelectPublic>
          <SelectHashtag>
            <p className="ment">
              질문에 맞는 해시태그 (최대 5개) 를 입력해주세요
            </p>
            <div className="tag-btns">
              <ul>
                {defaultHashtags.map(({ id, name, isSelected }) => (
                  <li key={id}>
                    <BtnHashtag
                      active={isSelected}
                      onClick={() => onClickBtnHashtag2(id, name, isSelected)}
                    >
                      # {name}
                    </BtnHashtag>
                  </li>
                ))}
              </ul>
            </div>
            <div className="input-wrap">
              <Input
                type="text"
                style={{ width: '85%', borderBottom: 0 }}
                value={inputTag}
                onChange={onChangeText}
                onKeyPress={onKeyPressHandler}
                placeholder="해시태그를 입력해주세요"
              />
              <button
                type="button"
                onClick={() => {
                  onClickHashTagBtn();
                }}
              >
                입력
              </button>
            </div>
            {emptyTagMsg && (
              <p className="error-message">해시태그를 입력해주세요.</p>
            )}
            <div className="selected-hashtag-list">
              <ul>
                {selectedHashtags &&
                  selectedHashtags.map((tag, index) => (
                    <li>
                      <span className="tag-name">{tag}</span>
                      <button type="button">
                        <img
                          src={btnClose}
                          alt="닫기 버튼"
                          onClick={() => onClickTagDel(tag)}
                        />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </SelectHashtag>

          <textarea
            placeholder="공유하고 싶은 질문을 입력해주세요!"
            onChange={onChangeTextArea}
            defaultValue={formData.content}
            disabled={formData.scrapsCount > 0}
          />
          {!formData.attachments.default && formData.attachments.url && (
            <div className="upload-image">
              <img
                className="image"
                src={
                  formData.attachments.url
                    ? formData.attachments.url
                    : sampleImage1
                }
                alt="업로드 이미지"
              />
              <button type="button">
                <img
                  src={btnCloseCircleGray}
                  alt="닫기 버튼"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      attachments: {
                        id: formData.attachments.id,
                        type: formData.attachments.type,
                        url: '',
                        default: true,
                        isDeleted: true,
                        isChanged: true,
                      },
                    });
                  }}
                />
              </button>
            </div>
          )}
        </div>
        <BtnCamera>
          <label htmlFor="input-openspace-image">
            <img src={btnCamera} alt="카메라 버튼" />
          </label>
        </BtnCamera>
        <input
          id="input-openspace-image"
          type="file"
          accept="image/*"
          onChange={onChangeImage}
        />
        <CheckUseTerm>
          <Checkbox
            className="check-term"
            label="이용약관 동의"
            checked={isAgreeUseTerm}
            onChange={onChangeCheckBox}
          />
          <button
            className="btn-show-term"
            type="button"
            onClick={onClickShowUseTerm}
          >
            이용약관 보기
          </button>
        </CheckUseTerm>
      </Content>

      {isUploading && <LoadingLayer />}

      {modalConfirmView && (
        <ModalConfirm
          visible
          title="확인"
          description="파일을 첨부하지 않으면 기본이미지와
함께 등록 돼요! 그래도 등록 하시겠어요?"
          btnCancel="취소"
          btnConfirm="등록"
        />
      )}
    </Container>
  );
}

const CheckUseTerm = styled.div`
  padding: 20px 20px 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;

  .check-term {
    width: 180px;
  }

  .btn-show-term {
    color: ${({ theme }) => theme.color.gray1};
    text-decoration: underline;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BtnSave = styled.button<{ active: boolean }>`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.color.main1 : theme.color.gray1};
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;
  position: relative;

  .inner-wrap {
    flex: 1;
  }

  textarea {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    border: 0;
    font-family: 'NanumSquare';
    font-size: 1.5rem;

    &::placeholder {
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray2};
    }
  }

  .upload-image {
    position: relative;
    display: inline-block;
    margin: 30px 0 50px;

    .image {
      width: 160px;
      border-radius: 8px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;

      img {
        width: 14px;
      }
    }
  }

  #input-openspace-image {
    display: inline-block;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
`;

const BtnSelectPublic = styled.div`
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.gray2};
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;

  select {
    width: 36px;
    font-size: 1.2rem;
  }

  .icon-lock {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(${iconLock});
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  .icon-arrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(${iconArrowSmall});
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 24px;
    transform: rotate(90deg);
  }
`;

const SelectHashtag = styled.div`
  .ment {
    margin-bottom: 15px;
  }

  .tag-btns {
    margin-bottom: 30px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }

  .input-wrap {
    position: relative;
    border-bottom: 1px solid #ddd;

    input::placeholder {
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray2};
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 10px;
      font-size: 1.4rem;
      color: #007aff;
    }
  }

  .error-message {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.main1};
    margin: 8px 0 20px;
  }

  .selected-hashtag-list {
    margin-top: 8px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
        padding: 2px 6px 2px 10px;
        border-radius: 25px;
        background-color: ${({ theme }) => theme.color.gray3};
        margin-right: 8px;
        margin-bottom: 8px;

        .tag-name {
          font-size: 1.1rem;
          color: ${({ theme }) => theme.color.gray1};
          margin-right: 2px;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        button {
          line-height: 0;

          img {
            width: 16px;
            opacity: 0.5;
          }
        }
      }
    }
  }
`;

const BtnHashtag = styled.button<{ active?: boolean }>`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.color.gray1};
  padding: 2px 10px;
  border: 1px solid ${({ theme }) => theme.color.gray2};
  font-family: 'NanumSquare';
  border-radius: 25px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.color.main1};
      border-color: ${({ theme }) => theme.color.main1};
      color: #fff;
    `}
`;

const BtnCamera = styled.div`
  position: fixed;
  right: 20px;
  bottom: 160px;
  z-index: 2;

  label {
    display: inline-block;

    input {
      display: inline-block;
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
    }

    img {
      width: 44px;
    }
  }
`;

const CommentBtnMenu = styled.div`
  button {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 1.6rem;
  }
`;

export default StackCreateQuestion;

import React, { ReactNode, CSSProperties } from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
  children?: string | ReactNode;
  className?: string;
  color?: 'main' | 'gray';
  size?: string;
  style?: CSSProperties;
  round?: boolean;
  outline?: string;
  fullWidth?: boolean;
  onClick?: () => void;
}
function Button({
  children,
  className,
  color,
  size,
  style,
  round,
  outline,
  fullWidth,
  onClick,
}: ButtonProps): JSX.Element {
  return (
    <ButtonComponent
      className={className}
      color={color}
      size={size}
      style={style}
      round={round}
      outline={outline}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {children}
    </ButtonComponent>
  );
}

Button.defaultProps = {
  children: '',
  className: null,
  color: 'main',
  size: 'large',
  style: null,
  round: false,
  outline: null,
  fullWidth: false,
  onClick: null,
};

const colorStyle = css<ButtonProps>`
  ${({ theme, color }) =>
    color === 'main' &&
    css`
      background-color: ${theme.color.main1};
      color: #fff;
    `}
  ${({ theme, color }) =>
    color === 'gray' &&
    css`
      background-color: ${theme.color.gray3};
      color: ${theme.color.gray1};
    `}
`;
const sizeStyle = css<ButtonProps>`
  ${({ size }) =>
    size === 'large' &&
    css`
      height: 50px;
      font-size: 1.5rem;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      height: 32px;
      font-size: 1.4rem;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 25px;
      font-size: 1.2rem;
    `}
`;
const outlineStyle = css<ButtonProps>`
  ${({ theme, outline }) =>
    outline === 'main' &&
    css`
      background-color: #fff;
      border: 1px solid ${theme.color.main1};
      color: ${theme.color.main1};
    `}

  ${({ theme, outline }) =>
    outline === 'gray' &&
    css`
      background-color: #fff;
      border: 1px solid ${theme.color.gray2};
      color: ${theme.color.gray1};
    `}
`;

const ButtonComponent = styled.button<ButtonProps>`
  font-size: 1.6rem;
  width: 100px;
  border-radius: 8px;

  ${colorStyle}

  ${sizeStyle}

  ${outlineStyle}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ round }) =>
    round &&
    css`
      border-radius: 25px;
    `}
`;

export default Button;

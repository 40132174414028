import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  guide01,
  guide02,
  guide03,
  guide04,
  guide05,
  guide06,
} from '../../../assets/images';
import StackHeader from '../../../layouts/StackHeader';

function StackGuide(): JSX.Element {
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState(0);

  const onSlideChange = useCallback((swiper) => {
    console.log(swiper.activeIndex);
    setCurrentSwiperIndex(swiper.activeIndex);
  }, []);

  return (
    <Container>
      <StackHeader />
      <Content>
        <div className="text-wrap">
          {swiperDatas[currentSwiperIndex].sub}
          {swiperDatas[currentSwiperIndex].title}
        </div>
        <div className="swiper-wrap">
          <div className="swiper-guide-pager" />
          <Swiper
            slidesPerView={1}
            pagination={{
              el: '.swiper-guide-pager',
            }}
            onSlideChange={onSlideChange}
            onSwiper={() => {}}
            onClick={() => {}}
            className="mySwiper"
          >
            <SwiperSlide>
              <img className="guide-image" src={guide01} alt="안내 이미지" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="guide-image" src={guide02} alt="안내 이미지" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="guide-image" src={guide03} alt="안내 이미지" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="guide-image" src={guide04} alt="안내 이미지" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="guide-image" src={guide05} alt="안내 이미지" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="guide-image" src={guide06} alt="안내 이미지" />
            </SwiperSlide>
          </Swiper>
        </div>
      </Content>
    </Container>
  );
}

const swiperDatas = [
  {
    id: 1,
    sub: <p className="sub">매일 하나씩 도착하는 질문</p>,
    title: (
      <p className="title">
        의미 있고 재밌는 질문에
        <br />
        답변하며 가까워지세요!
      </p>
    ),
  },
  {
    id: 2,
    sub: <p className="sub">커플만 쓰기는 아까워요</p>,
    title: (
      <p className="title">
        친구, 연인, 가족과 함께
        <br /> 혼자 써도 좋아요 : )
      </p>
    ),
  },
  {
    id: 3,
    sub: <p className="sub">취향저격 질문들도 받아볼 수 있어요</p>,
    title: (
      <p className="title">
        오픈스페이스에서 맘에드는
        <br /> 질문을 내 다이어리에 받아봐요 : )
      </p>
    ),
  },
  {
    id: 4,
    sub: <p className="sub">두근두근 뭐라고 썼을까요?</p>,
    title: (
      <p className="title">
        나의 답변을 쓰고
        <br /> 멤버의 답변을 확인하세요
      </p>
    ),
  },
  {
    id: 5,
    sub: <p className="sub">1년 뒤엔 같은 질문을 보내드려요</p>,
    title: (
      <p className="title">
        함께한 기록을 간직하고
        <br /> 언제든 추억하세요
      </p>
    ),
  },
  {
    id: 6,
    sub: <p className="sub">똑같은 질문은 그만!</p>,
    title: (
      <p className="title">
        오픈스페이스에서 사용자들과
        <br /> 질문을 공유하고, 받아와요
      </p>
    ),
  },
];

const Container = styled.div`
  height: 100vh;
  background-color: #fff0eb;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .text-wrap {
    text-align: center;

    .sub {
      font-size: 1.3rem;
      margin: 20px 0;
    }

    .title {
      font-size: 2rem;
      font-weight: 700;
      color: ${({ theme }) => theme.color.main1};
      margin-bottom: 30px;
    }
  }

  .swiper-wrap {
    flex: 1;
    text-align: center;

    .swiper-guide-pager {
      height: 6px;

      .swiper-pagination-bullet {
        margin: 0 2px;
        width: 6px;
        height: 6px;
      }

      .swiper-pagination-bullet-active {
        background-color: ${({ theme }) => theme.color.main1};
      }
    }
    .guide-image {
      width: 75%;
      border-radius: 25px;
      margin: 20px 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
  }
`;

export default StackGuide;

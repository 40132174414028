import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import useMyProfile from '../../../hooks/useMyProfile';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import Switch from '../../parts/Switch';

interface switchTypes {
  [key: string]: boolean;
}

function StackSettingAlarm(): JSX.Element {
  const [checkAll, setCheckAll] = useState(false);
  const [checkStatus, setCheckStatus] = useState<switchTypes>({
    isPushComment: false,
    isPushDiary: false,
    isPushLike: false,
    isPushScrap: false,
  });

  const { fetchMyProfile } = useMyProfile();
  const { removeStackScreen } = useStack();

  const onClickSwitchAll = useCallback(() => {
    if (checkAll) {
      setCheckAll(false);
      setCheckStatus({
        isPushComment: false,
        isPushDiary: false,
        isPushLike: false,
        isPushScrap: false,
      });
    } else {
      setCheckAll(true);
      setCheckStatus({
        isPushComment: true,
        isPushDiary: true,
        isPushLike: true,
        isPushScrap: true,
      });
    }
  }, [checkAll]);

  const onChangeSwitch = (switchName: string) => {
    if (!checkStatus[switchName] === false) {
      setCheckAll(false);
    }
    setCheckStatus({
      ...checkStatus,
      [switchName]: !checkStatus[switchName],
    });
  };

  const submitPushSetting = async () => {
    try {
      const { data } = await axios.put('/me/push/setting', checkStatus);
      if (data.success) {
        fetchMyProfile();
        removeStackScreen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (
      checkStatus.isPushComment === true &&
      checkStatus.isPushDiary === true &&
      checkStatus.isPushLike === true &&
      checkStatus.isPushScrap === true
    )
      setCheckAll(true);
  }, [checkStatus]);

  const fetchAlarmSetting = async () => {
    try {
      const { data } = await axios.get('/me/push/setting');
      if (data.success) {
        const { isPushComment, isPushDiary, isPushLike, isPushScrap } =
          data.setting;

        setCheckStatus({
          isPushComment,
          isPushDiary,
          isPushLike,
          isPushScrap,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAlarmSetting();
  }, []);

  return (
    <Container>
      <StackHeader
        title="알림 설정"
        btnRight={
          <button
            className="btn-save"
            type="button"
            onClick={submitPushSetting}
          >
            SAVE
          </button>
        }
      />
      <Content>
        <div className="inner-wrap">
          <SettingSection>
            <div className="setting-item">
              <p>모든 알림</p>
              <Switch active={checkAll} onClick={onClickSwitchAll} />
            </div>
            <div className="setting-item">
              <p>다이어리상태</p>
              <Switch
                active={checkStatus.isPushComment}
                onClick={() => onChangeSwitch('isPushComment')}
              />
              <p className="desc">다이어리 시작 / 종료, 질문도착, 기타...</p>
            </div>
            <div className="setting-item">
              <p>댓글</p>
              <Switch
                active={checkStatus.isPushDiary}
                onClick={() => onChangeSwitch('isPushDiary')}
              />
            </div>
            <div className="setting-item">
              <p>좋아요</p>
              <Switch
                active={checkStatus.isPushLike}
                onClick={() => onChangeSwitch('isPushLike')}
              />
            </div>
            <div className="setting-item">
              <p>스크랩</p>
              <Switch
                active={checkStatus.isPushScrap}
                onClick={() => onChangeSwitch('isPushScrap')}
              />
            </div>
          </SettingSection>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const SettingSection = styled.div`
  padding-top: 30px;

  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    position: relative;

    .desc {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
      position: absolute;
      bottom: -6px;
      left: 0;
    }
  }
`;

export default StackSettingAlarm;

import React from 'react';
import styled from 'styled-components';
import Comment from '../Comment';
import { IComment } from '../../../store/home';

interface IProps {
  listType: 'diary' | 'openSpace';
  commentList: IComment[];
  setBottomToastModalView: (visible: boolean) => void;
  currentScroll?: number;
}

function CommentListNoAnswer({
  listType,
  commentList,
  setBottomToastModalView,
  currentScroll,
}: IProps): JSX.Element {
  return (
    <Container>
      {commentList.map((comment, commentIndex) => (
        <CommentWrap key={comment.id}>
          <Comment
            listType={listType}
            commentType="comment"
            commentData={comment}
            commentIndex={commentIndex}
            currentScroll={currentScroll}
            isAnswered
            isOpen
            setBottomToastModalView={setBottomToastModalView}
          />
          {comment.replies.length > 0 && (
            <ul className="comment-reply">
              {comment.replies.map((reply, replyIndex) => (
                <li key={reply.id}>
                  <Comment
                    listType={listType}
                    commentType="comment"
                    commentData={reply}
                    commentIndex={commentIndex}
                    currentScroll={currentScroll}
                    isAnswered
                    isOpen
                    replyIndex={replyIndex}
                    parentCommentId={comment.id}
                    isCommentReply
                    setBottomToastModalView={setBottomToastModalView}
                  />
                </li>
              ))}
            </ul>
          )}
        </CommentWrap>
      ))}
    </Container>
  );
}

const Container = styled.div``;

const CommentWrap = styled.div`
  .comment-reply {
    padding-left: 30px;
  }
`;

export default CommentListNoAnswer;

import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { radios } from '../../../assets/images';

interface IProps {
  name: string;
  checked: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function Radio({ name, checked, value, onChange }: IProps): JSX.Element {
  return (
    <Container checked={checked}>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
    </Container>
  );
}

const Container = styled.label<Pick<IProps, 'checked'>>`
  width: 20px;
  height: 20px;
  background-image: url(${radios});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 20px;

  ${({ checked }) =>
    checked &&
    css`
      background-position-y: -20px;
    `}

  input {
    width: 0;
    height: 0;
    visibility: hidden;
  }
`;

export default Radio;

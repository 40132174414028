import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import ModalConfirm from '../../common/ModalConfirm';

function StackAccountManagement(): JSX.Element {
  const [modalConfirmWithdrawal, setModalConfirmWithdrawal] = useState(false);
  const { addStackScreen, clearStackScreen } = useStack();

  const logout = async () => {
    try {
      const { data } = await axios.post('/logout');
      if (data.success) {
        window.flutter_inappwebview.callHandler('logout');

        setModalConfirmWithdrawal(false);
        localStorage.removeItem('accessToken');
        clearStackScreen();
        window.location.href = '/login';
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <StackHeader title="계정관리" />
      <Content>
        <div className="inner-wrap">
          <SettingSection>
            <div className="item">
              <p onClick={() => setModalConfirmWithdrawal(true)}>로그아웃</p>
            </div>
            <div className="item">
              <p onClick={() => addStackScreen('StackWithdrawal')}>회원탈퇴</p>
            </div>
          </SettingSection>
        </div>
      </Content>
      <ModalConfirm
        visible={modalConfirmWithdrawal}
        title="확인"
        description="로그아웃 하시겠어요?"
        btnCancel="취소"
        btnCancelClick={() => setModalConfirmWithdrawal(false)}
        btnConfirm="로그아웃"
        btnConfirmClick={logout}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const SettingSection = styled.div`
  padding: 15px 0;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
`;

export default StackAccountManagement;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import axios from 'axios';
import { actions as commonActions } from '../../../store/common';

import { btnShare, sampleImage1, sampleProfile1 } from '../../../assets/images';
import {
  iconCaution,
  iconCommentGray,
  iconLock,
  iconMoreMenu2,
  iconScrap,
  iconScrapOutline,
} from '../../../assets/images/icon';
import Thumb from '../../parts/Thumb';
import { RootState } from '../../../store';
import BottomToastModal from '../BottomToastModal';
import SnsShare from '../snsShare';
import ReportUserForm from '../../openSpace/ReportUserForm';

interface IProps {
  openSpaceId?: number;
  className?: string;
  thumb?: string;
  nickname?: string;
  isMyFeed?: boolean;
  createdAt: string;
  hashtags: string;
  question?: string;
  coverImage?: string;
  isScrap: boolean;
  countScrap: number;
  countComment: number;
  isFeedRemoved?: boolean;
  isFeedDetail?: boolean;
  isMyQuestion?: boolean;
  isPublic?: boolean;
  // fetchListFn?: (param: any | null) => void;
}
interface IToastView {
  type: string;
  openSpaceId: number;
  shareUrl?: string | null;
  questions?: string | null;
}

function QuestionFeed({
  openSpaceId,
  className,
  thumb,
  nickname,
  isMyFeed,
  createdAt,
  hashtags,
  question,
  coverImage,
  isScrap,
  countScrap,
  countComment,
  isFeedRemoved,
  isFeedDetail,
  isMyQuestion,
  isPublic,
}: // fetchListFn,
IProps): JSX.Element {
  const dispatch = useDispatch();
  const [countScrap2, setCountScrap] = useState(countScrap);
  const { stackScreens, fetchOpenSpaceFn } = useSelector(
    (state: RootState) => state.common,
  );
  const [scrap, setScrap] = useState(isScrap);
  const showFeedDetail = useCallback(() => {
    if (!isFeedDetail) {
      dispatch(commonActions.addStackScreen('StackCreateQuestion'));
      // dispatch(commonActions.setValue('selectedOpenSpaceId', 13));
    }
  }, [isFeedDetail]);

  const [bottomToastModalView, setBottomToastModalView] =
    useState<IToastView | null>(null);

  const onClickDel = () => {
    axios
      .delete(`/openspaces/${bottomToastModalView?.openSpaceId}`)
      .then((res) => {
        // console.log("delete", fetchOpenSpaceFn)
        if (fetchOpenSpaceFn) {
          fetchOpenSpaceFn(null);
        }
      });
    setBottomToastModalView(null);
  };

  const onClickMoreMenu = useCallback((openSpaceId) => {
    setBottomToastModalView({ type: '', openSpaceId });
  }, []);

  useEffect(() => {
    // console.log('useEffect countScrap', countScrap);
    setScrap(isScrap);
    setCountScrap(countScrap);
  }, [isScrap]);

  const onClickDetail = (openSpaceId: number) => {
    if (stackScreens[stackScreens.length - 1] !== 'StackQuestionFeedDetail') {
      dispatch(commonActions.addStackScreen('StackQuestionFeedDetail'));
      dispatch(commonActions.setValue('selectedOpenSpaceId', openSpaceId));
    }
  };

  const onClickShare = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    image: string,
    questions: string,
  ) => {
    e.stopPropagation();

    const domain = `${window.location.protocol}//${window.location.hostname}`;

    let shareUrl = `${domain}${window.location.pathname}?openSpaceId=${openSpaceId}`;
    // if(isAndroid){
    shareUrl = `${domain}/gateway?redirect=${shareUrl}`;
    // }

    console.log(shareUrl);
    const content = `${questions} \n\n ${shareUrl}`;

    window.flutter_inappwebview.callHandler('share', content);
    // window.flutter_inappwebview.callHandler('kakaoLink', [ image, shareUrl, questions  ]);
  };

  const onClickScrap = () => {
    if (scrap) {
      axios
        .put(`/openspaces/${openSpaceId}/scraps`, { scrapYn: 'N' })
        .then((res) => {
          const count = countScrap2 - 1;
          setCountScrap(count < 0 ? 0 : count);
          setScrap(!scrap);
          // if (fetchOpenSpaceFn) fetchOpenSpaceFn(null);
          if(isFeedDetail) fetchOpenSpaceFn(null);
        });
    } else {
      axios.post(`/openspaces/${openSpaceId}/scraps`).then((res) => {
        const count = countScrap2 + 1;
        setCountScrap(count);
        setScrap(true);
        // if (fetchOpenSpaceFn) fetchOpenSpaceFn(null);
        if(isFeedDetail) fetchOpenSpaceFn(null);
      });
    }
  };

  const [bottomToastReport, setBottomToastReport] = useState(false);

  const onClickReportUser = async () => {
    try {
      const { data } = await axios.get('');
      if (data.success) {
        // ...
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container className={className}>
      {!isFeedRemoved && (
        <>
          <div className="inner-wrap">
            {thumb && (
              <div className="user-info">
                <Thumb image={thumb} />
                <div className="info-wrap">
                  <div className="nickname-wrap">
                    <span className="nickname">{nickname}</span>
                    <span className="created-at">{createdAt}</span>
                  </div>
                  <p className="tags">{hashtags}</p>
                </div>
              </div>
            )}
            {!thumb && (
              <div className="feed-info">
                <p className="created-at">
                  {createdAt} {isMyQuestion && <IconLock active={isPublic} />}
                </p>
                <p className="tags">{hashtags}</p>
              </div>
            )}
            <p className="question">
              <span className="label">Q.</span>
              <span>{question}</span>
            </p>
          </div>
          <div
            role="button"
            className="image"
            style={{ backgroundImage: `url(${coverImage})` }}
            onClick={() => {
              onClickDetail(openSpaceId || 0);
            }}
          >
            {isPublic && (
              <button
                className="btn-share"
                type="button"
                onClick={(e) => {
                  onClickShare(e, `${coverImage}`, `${question}`);
                }}
              >
                <img src={btnShare} alt="공유 버튼" />
              </button>
            )}
          </div>
        </>
      )}
      {isFeedRemoved && (
        <div className="feed-removed">
          <div className="message">
            <img src={iconCaution} alt="경고 이미지" />
            <p className="ment1">게시물이 삭제되었습니다.</p>
            <p className="ment2">
              회원님의 게시물이 혐오 발언 또는 상징에 대한
              <br /> 커뮤니티 가이드라인을 위반하므로 삭제되었습니다.
            </p>
          </div>
        </div>
      )}
      <div className="inner-wrap">
        <div className="counter">
          <div className="count-scrap">
            <img src={iconScrapOutline} alt="스크랩 아이콘" />
            <span>{countScrap2}</span>
          </div>
          <div role="button" className="count-comment">
            <img src={iconCommentGray} alt="댓글 아이콘" />
            <span>{countComment}</span>
          </div>
        </div>
      </div>
      {!isFeedRemoved && (
        <button
          className={`btn-scrap ${scrap ? 'active' : null}`}
          type="button"
          onClick={() => {
            onClickScrap();
          }}
        >
          <span />
        </button>
      )}
      {isMyQuestion && (
        <BtnMoreMenu
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            onClickMoreMenu && onClickMoreMenu(openSpaceId || null);
          }}
        >
          <img src={iconMoreMenu2} alt="더보기 버튼" />
        </BtnMoreMenu>
      )}

      {bottomToastModalView && (
        <BottomToastModal
          visible={!!bottomToastModalView}
          hideToastModal={() => setBottomToastModalView(null)}
        >
          {bottomToastModalView.type === 'snsShare' ? (
            <SnsShare
              questions={bottomToastModalView.questions || ''}
              url={bottomToastModalView.shareUrl || ''}
            />
          ) : isMyFeed ? (
            <CommentBtnMenu>
              <button
                type="button"
                onClick={() => {
                  dispatch(commonActions.addStackScreen('StackCreateQuestion'));
                  dispatch(
                    commonActions.setValue(
                      'selectedOpenSpaceId',
                      bottomToastModalView?.openSpaceId,
                    ),
                  );
                  setBottomToastModalView(null);
                }}
              >
                수정하기
              </button>
              <button
                type="button"
                onClick={() => {
                  onClickDel();
                }}
              >
                삭제하기
              </button>
            </CommentBtnMenu>
          ) : (
            <CommentBtnMenu>
              <button
                type="button"
                onClick={() => {
                  setBottomToastModalView(null);
                  setBottomToastReport(true);
                }}
              >
                신고하기
              </button>
            </CommentBtnMenu>
          )}
        </BottomToastModal>
      )}

      {bottomToastReport && (
        <BottomToastModal
          visible={bottomToastReport}
          hideToastModal={() => setBottomToastReport(false)}
        >
          <ReportUserForm
            reportId={openSpaceId || 0}
            hideReportForm={() => setBottomToastReport(false)}
          />
        </BottomToastModal>
      )}
    </Container>
  );
}

QuestionFeed.defaultProps = {
  isPublic: true,
};

const Container = styled.div`
  background-color: #fff;
  position: relative;
  padding: 15px 0;

  .user-info {
    display: flex;
    margin-bottom: 20px;

    .info-wrap {
      flex: 1;
      margin-left: 10px;

      .nickname-wrap {
        display: flex;
        margin-bottom: 2px;

        .nickname {
          font-size: 1.2rem;
          font-weight: 700;
          margin-right: 10px;
        }

        .created-at {
          font-size: 1.2rem;
          color: ${({ theme }) => theme.color.gray1};
          font-family: 'NanumSquare';
        }
      }

      .tags {
        width: 260px;
        word-break: normal;
        font-size: 1.2rem;
        color: ${({ theme }) => theme.color.gray1};
        font-family: 'NanumSquare';
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .feed-removed {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .message {
      width: 300px;

      img {
        width: 50px;
        margin-bottom: 25px;
      }
      .ment1 {
        font-size: 1.5rem;
        margin-bottom: 30px;
      }
      .ment2 {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.color.gray1};
      }
    }
  }
  .feed-info {
    .created-at {
      font-size: 1.1rem;
      color: ${({ theme }) => theme.color.gray1};
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      font-family: 'NanumSquare';
    }
    .tags {
      word-break: normal;
      font-size: 1.1rem;
      color: ${({ theme }) => theme.color.gray1};
      margin-bottom: 12px;
      font-family: 'NanumSquare';
    }
  }

  .question {
    margin-bottom: 15px;

    .label {
      font-weight: 700;
      color: ${({ theme }) => theme.color.main1};
      margin-right: 10px;
    }
  }

  .image {
    height: 280px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .btn-share {
      position: absolute;
      right: 15px;
      bottom: 15px;

      img {
        width: 30px;
      }
    }
  }

  .counter {
    margin-top: 15px;
    display: flex;

    .count-scrap {
      margin-right: 10px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        margin-right: 5px;
      }
      span {
        color: ${({ theme }) => theme.color.gray1};
        font-family: 'NanumSquare';
        line-height: 18px;
      }
    }

    .count-comment {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        margin-right: 5px;
      }
      span {
        color: ${({ theme }) => theme.color.gray1};
        font-family: 'NanumSquare';
        line-height: 18px;
      }
    }
  }

  .btn-scrap {
    position: absolute;
    top: 0;
    right: 15px;

    span {
      display: inline-block;
      width: 28px;
      height: 27px;
      background-image: url(${iconScrap});
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
    }

    &.active span {
      background-position-y: -28px;
    }
  }
`;

const IconLock = styled.span<{ active?: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(${iconLock});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 20px;

  ${({ active }) =>
    active &&
    css`
      background-position-y: -20px;
    `}
`;

const BtnMoreMenu = styled.button`
  position: absolute;
  right: 15px;
  bottom: 12px;

  img {
    width: 28px;
  }
`;
const CommentBtnMenu = styled.div`
  button {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 1.6rem;
  }
`;
export default QuestionFeed;

// src/assets/styles/theme.ts
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  color: {
    main1: '#FF6957',
    main2: '#FACEC1',

    black: '#222222',

    gray1: '#9E9E9E',
    gray2: '#D2D2D2',
    gray3: '#F5F5F5',

    placeholder: '#D2D2D2',
  },
};

export { theme };

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  sampleProfile1,
  sampleProfile2,
  sampleProfile3,
  sampleProfile4,
} from '../../../assets/images';
import { iconManager } from '../../../assets/images/icon';
import { RootState } from '../../../store';
import { IMember } from '../../../store/home';
import Button from '../../parts/Button';

interface IProps {
  members: IMember[] | undefined;
  onClickDeleteMember: (id: number | undefined, nickname: string) => void;
}

function InviteList({ members, onClickDeleteMember }: IProps): JSX.Element {
  const { selectedDiaryInfo, myProfile } = useSelector(
    (state: RootState) => ({
      selectedDiaryInfo: state.common.selectedDiaryInfo,
      myProfile: state.common.myProfile,
    }),
    shallowEqual,
  );

  return (
    <Container>
      {members && (
        <Creator>
          <p className="label">생성자</p>
          <User>
            <div className="thumb-wrap">
              <span
                className="thumb"
                style={{ backgroundImage: `url(${members[0].profileUrl})` }}
              />
              <img
                className="icon-manager"
                src={iconManager}
                alt="방장 아이콘"
              />
            </div>
            <p className="name">{members[0].nickname}</p>
          </User>
        </Creator>
      )}
      <Participants>
        <p className="label">참여자</p>
        <div>
          {members &&
            members.map((member, index) => {
              if (index === 0) return null;
              return (
                <User key={member.id}>
                  <span
                    className="thumb"
                    style={{ backgroundImage: `url(${member.profileUrl})` }}
                  />
                  <span className="name">{member.nickname}</span>
                  {selectedDiaryInfo.data?.ownerId === myProfile?.id && (
                    <Button
                      onClick={() =>
                        onClickDeleteMember(member.id, member.nickname)
                      }
                      style={{ width: '76px' }}
                      size="small"
                      outline="gray"
                      round
                    >
                      내보내기
                    </Button>
                  )}
                </User>
              );
            })}
        </div>
      </Participants>
    </Container>
  );
}

const Container = styled.div`
  .label {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.gray1};
  }
`;

const User = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  .thumb-wrap {
    position: relative;
  }

  .thumb {
    display: inline-block;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .icon-manager {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
  }

  .name {
    flex: 1;
    font-weight: 700;
    padding-left: 15px;
  }
`;

const Creator = styled.div`
  margin-bottom: 40px;

  .thumb {
    width: 56px;
    height: 56px;
  }
`;

const Participants = styled.div`
  margin-bottom: 40px;

  .thumb {
    width: 48px;
    height: 48px;
  }
`;

export default InviteList;

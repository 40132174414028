import React from 'react';
import styled from 'styled-components';
import { loading } from '../../../assets/images';

function LoadingLayer(): JSX.Element {
  return (
    <Container>
      <div className="image-wrap">
        <img src={loading} alt="로딩 이미지" />
        <p>잠시만 기다려주세요</p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);

  .image-wrap {
    padding: 10px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;

    img {
      width: 60px;
    }

    p {
      margin-top: 10px;
    }
  }
`;

export default LoadingLayer;

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  asyncSetDiaries,
  IDiaryDetailApi,
  actions as homeActions,
} from '../store/home';
import { actions as commonActions } from '../store/common';
import { actions as openSpaceActions } from '../store/openSpace';
import { RootState } from '../store';

function useFetchData() {
  const dispatch = useDispatch();
  const { selectedDiaryId, selectedDiaryDate, selectedOpenSpaceId } =
    useSelector((state: RootState) => ({
      selectedDiaryId: state.common.selectedDiaryId,
      selectedDiaryDate: state.common.selectedDiaryDate,
      selectedOpenSpaceId: state.common.selectedOpenSpaceId,
    }));

  const { currentSlideIndex } = useSelector((state: RootState) => state.home);

  const fetchMainDiaries = (fetchType?: 'create' | 'init') => {
    if (fetchType === undefined)
      dispatch(homeActions.setValue('slideToIndex', currentSlideIndex));

    dispatch(asyncSetDiaries.request(fetchType !== undefined ? fetchType : ''));
  };

  const fetchDiaryInfo = async () => {
    dispatch(
      commonActions.setValue('selectedDiaryInfo', {
        loading: true,
        data: null,
        error: null,
      }),
    );
    try {
      const { data } = await axios.get(`/diaries/${selectedDiaryId}`);
      if (data.success) {
        console.log('fetch diary info : ', data);
        dispatch(
          commonActions.setValue('selectedDiaryInfo', {
            loading: false,
            data: data.diary,
            error: null,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const initDiaryInfo = () => {
    dispatch(
      commonActions.setValue('selectedDiaryInfo', {
        loading: false,
        data: null,
        error: null,
      }),
    );
  };

  const fetchDiaryDetail = async (date = '') => {
    date = selectedDiaryDate || date;

    dispatch(
      commonActions.setValue('selectedDiaryDetail', {
        loading: true,
        data: null,
        error: null,
      }),
    );
    try {
      const { data } = await axios.get<IDiaryDetailApi>(
        `/diaries/${selectedDiaryId}/questions/latest`,
        {
          params: {
            date,
          },
        },
      );
      if (data.success) {
        console.log('fetch diary detail : ', data);
        dispatch(
          commonActions.setValue('selectedDiaryDetail', {
            loading: false,
            data: {
              answers: data.answers,
              isAnswered: data.isAnswered,
              isOpen: data.isOpen,
              noAnsweredMembers: data.noAnsweredMembers,
              question: data.question,
              date: data.date,
            },
            error: null,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        commonActions.setValue('selectedDiaryDetail', {
          loading: false,
          data: null,
          error: null,
        }),
      );
      if (e.response.status === 404) {
        dispatch(
          commonActions.setValue(
            'alertMessage',
            e.response.data.errors.message[0],
          ),
        );
      }
    }
  };

  const initDiaryDetail = () => {
    dispatch(
      commonActions.setValue('selectedDiaryDetail', {
        loading: false,
        data: null,
        error: null,
      }),
    );
  };

  const fetchOpenSpaceDetail = async () => {
    const { data } = await axios.get(`/openspaces/${selectedOpenSpaceId}`);

    if (data && data.success) {
      if (data.tags && data.tags.length > 0) {
        data.tagStr = `#${data.tags.join(' #')}`;
      }
      dispatch(openSpaceActions.setValue('openSpaceDetail', data.data));
    }
  };

  return {
    fetchMainDiaries,
    fetchDiaryInfo,
    initDiaryInfo,
    fetchDiaryDetail,
    initDiaryDetail,
    fetchOpenSpaceDetail,
  };
}

export default useFetchData;

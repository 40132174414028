import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import LoadingContent from '../../common/LoadingContent';
import NoList from '../../common/NoList';
import DiaryList from '../DiaryList';

function StackMyDiary(): JSX.Element {
  const dispatch = useDispatch();
  const { myDiaries } = useSelector((state: RootState) => state.common);

  const fetchMyDiaries = async () => {
    dispatch(
      commonActions.setValue('myDiaries', {
        ...myDiaries,
        loading: true,
      }),
    );

    try {
      const { data } = await axios.get('/diaries');
      if (data.success) {
        dispatch(
          commonActions.setValue('myDiaries', {
            loading: false,
            data: data.diaries,
            error: null,
          }),
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchMyDiaries();
  }, []);

  return (
    <Container>
      <StackHeader title="내 다이어리" />
      <Content>
        {myDiaries.loading && !myDiaries.data && <LoadingContent />}
        {myDiaries.data && myDiaries.data.length > 0 && (
          <DiaryList listType="myDiary" listData={myDiaries.data} />
        )}
        {myDiaries.data && myDiaries.data.length === 0 && (
          <NoList ment="아직 내 다이어리가 없어요" />
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding: 10px 0 30px;
  box-sizing: border-box;
`;

export default StackMyDiary;

import React, { useCallback, useState, CSSProperties } from 'react';
import styled, { css } from 'styled-components';

interface InputTypes {
  type: string;
  placeholder?: string;
  value: string;
  pattern?: string;
  maxLength?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  active?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  error?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputTypes>(
  (
    {
      type,
      placeholder,
      value,
      pattern,
      maxLength,
      onChange,
      onKeyPress,
      style,
      disabled,
      error,
    },
    ref,
  ) => {
    const [activeInput, setActiveInput] = useState(false);

    const onFocusInput = useCallback(() => {
      setActiveInput(true);
    }, []);

    const onBlurInput = useCallback(() => {
      setActiveInput(false);
    }, []);

    return (
      <TextInput
        type={type}
        placeholder={placeholder}
        value={value}
        pattern={pattern}
        maxLength={maxLength}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        active={activeInput}
        ref={ref}
        style={style}
        disabled={disabled}
        error={error}
      />
    );
  },
);

const TextInput = styled.input<InputTypes>`
  width: 100%;
  height: 32px;
  outline: none;
  border: 0;
  border-bottom: 1px solid #ddd;
  font-size: 1.5rem;
  border-radius: 0;
  font-family: 'NanumSquare';

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.color.placeholder};
  }

  &:disabled {
    background-color: transparent;
    border-bottom: 0;
  }

  ${({ active, theme }) =>
    active &&
    css`
      border-bottom: 1px solid ${theme.color.main1};
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-bottom: 1px solid ${theme.color.main1};
    `}
`;

export default Input;

import React from 'react';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  InstapaperIcon,
  PinterestIcon,
  PinterestShareButton,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import styled from 'styled-components';
import {
  btnShareFacebook,
  btnShareKakao,
  btnShareMessage,
  btnShareTwitter,
} from '../../../assets/images';

interface IProps {
  questions: string;
  url: string;
}

function SnsShare({ url, questions }: IProps): JSX.Element {
  const content =
    '나, 친구, 연인, 가족이 함께 쓰는 질문 다이어리. 서로가 몰랐던 부분을 알아가는 과정. 질문다이어리 아모르.';
  const snsContent = `${content} \n\n ${questions}`;
  const smsContent = `${content} \n\n ${questions} \n\n ${url}`;
  const smsStr = `sms:${isIOS ? '&' : '?'}body=${smsContent}`;

  // console.log(smsStr)
  return (
    <Container>
      {/*      <button type='button'>
        <img src={btnShareKakao} alt='카카오 아이콘' />
      </button> */}
      {/*      <button type='button'>
        <img src={btnShareFacebook} alt='페이스북 아이콘' />
      </button> */}
      <FacebookShareButton url={url} quote={snsContent}>
        <button type="button">
          <img src={btnShareFacebook} alt="페이스북 아이콘" />
        </button>
      </FacebookShareButton>
      <TwitterShareButton url={url} title={snsContent}>
        <button type="button">
          <img src={btnShareTwitter} alt="트위터 아이콘" />
        </button>
      </TwitterShareButton>
      <button
        type="button"
        onClick={() => {
          window?.flutter_inappwebview?.callHandler('openUrlInBrowser', smsStr);
        }}
      >
        <img src={btnShareMessage} alt="문자메시지 아이콘" />
      </button>
    </Container>
  );
  // return (
  //   <>
  //     <FacebookShareButton url={url}>
  //       <FacebookIcon size={48} round borderRadius={24} />
  //     </FacebookShareButton>
  //     <TwitterShareButton url={url}>
  //       <TwitterIcon size={48} round borderRadius={24} />
  //     </TwitterShareButton>
  //     <EmailShareButton url={url} onClick={(e) => {
  //       window?.flutter_inappwebview?.callHandler(
  //         'openUrlInBrowser', `mailto:?body=${url}`,
  //       );
  //     }}>
  //       <EmailIcon size={48} round borderRadius={24} />
  //     </EmailShareButton>
  //   </>
  // );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 10px;

    img {
      width: 50px;
    }
  }
`;

export default SnsShare;

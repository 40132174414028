import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { CommonActions, CommonState } from './types';
import {
  asyncSetUser,
  setValue,
  addStackScreen,
  removeStackScreen,
  replaceStackScreen,
  clearStackScreen,
  questionScrapToggle,
  commentLikeToggleOnDiary,
  replyLikeToggleOnDiary,
  deleteCommentOnDiary,
} from './actions';
import {
  asyncState,
  createAsyncReducer,
  transformToArray,
} from '../../utils/reducerUtils';

const initialState: CommonState = {
  currentPage: null,
  isScreenLock: false,
  alertMessage: '',
  stackScreens: [],
  user: asyncState.initial(), // 현재까지 사용하지 않음. saga 샘플. (불필요시 삭제 예정)
  myProfile: null,
  selectedDiaryId: null,
  selectedDiaryDetail: asyncState.initial(),
  selectedDiaryInfo: asyncState.initial(),
  selectedDiaryDate: null,
  selectedComment: null,
  selectedOpenSpaceId: null,
  fetchOpenSpaceFn: () => {},
  myDiaries: asyncState.initial(),
  endedDiaries: asyncState.initial(),
  isWatchedAd: false,
  isTutorialShow: false,
  scrollToComment: {
    commentId: null,
    commentOffset: null,
  },
  safeArea: {
    top: 0,
    bottom: 0,
  },
  notices: asyncState.initial(),
  selectedNoticeId: null,
  visibleSideMenu: false,
};

const reducer = createReducer<CommonState, CommonActions>(initialState)
  .handleAction(setValue, (state, action) => ({
    ...state,
    [action.payload.key]: action.payload.value,
  }))
  .handleAction(addStackScreen, (state, action) => {
    return produce(state, (draft) => {
      draft.stackScreens.push(action.payload.screenName);
    });
  })
  .handleAction(removeStackScreen, (state) => {
    return produce(state, (draft) => {
      draft.stackScreens.pop();
    });
  })
  .handleAction(replaceStackScreen, (state, action) => {
    return produce(state, (draft) => {
      draft.stackScreens.splice(
        draft.stackScreens.length - 1,
        1,
        action.payload.screenName,
      );
    });
  })
  .handleAction(clearStackScreen, (state) => {
    return produce(state, (draft) => {
      draft.stackScreens.splice(0, draft.stackScreens.length);
    });
  })
  .handleAction(questionScrapToggle, (state) => {
    return produce(state, (draft) => {
      if (draft.selectedDiaryDetail?.data?.question.scrapYn === 'Y') {
        // eslint-disable-next-line no-param-reassign
        draft.selectedDiaryDetail.data.question.scrapYn = 'N';
      } else if (draft.selectedDiaryDetail?.data?.question.scrapYn === 'N') {
        // eslint-disable-next-line no-param-reassign
        draft.selectedDiaryDetail.data.question.scrapYn = 'Y';
      }
    });
  })
  .handleAction(commentLikeToggleOnDiary, (state, action) => {
    return produce(state, (draft) => {
      const targetComment =
        draft.selectedDiaryDetail.data?.answers[action.payload.index];
      if (targetComment) {
        if (targetComment.isLiked) {
          targetComment.likesCount -= 1;
          targetComment.isLiked = !targetComment.isLiked;
        } else {
          targetComment.likesCount += 1;
          targetComment.isLiked = !targetComment.isLiked;
        }
      }
    });
  })
  .handleAction(replyLikeToggleOnDiary, (state, action) => {
    return produce(state, (draft) => {
      const targetComment =
        draft.selectedDiaryDetail.data?.answers[action.payload.commentIndex]
          .replies[action.payload.replyIndex];
      console.log('target comment : ', targetComment);
      if (targetComment) {
        if (targetComment.isLiked) {
          targetComment.likesCount -= 1;
          targetComment.isLiked = !targetComment.isLiked;
        } else {
          targetComment.likesCount += 1;
          targetComment.isLiked = !targetComment.isLiked;
        }
      }
    });
  })
  .handleAction(deleteCommentOnDiary, (state, action) => {
    return produce(state, (draft) => {
      const answers = draft.selectedDiaryDetail.data?.answers;
      const commentIndex = answers?.findIndex(
        (item) => item.id === action.payload.commentId,
      );
      if (commentIndex !== undefined) {
        if (commentIndex === -1) {
          console.log('answer 1');
          answers?.forEach((item) => {
            const replyIndex = item.replies.findIndex(
              (reply) => reply.id === action.payload.commentId,
            );
            if (replyIndex !== -1) item.replies.splice(replyIndex, 1);
          });
        } else {
          answers?.splice(commentIndex, 1);
          if (draft.selectedDiaryDetail?.data)
            draft.selectedDiaryDetail.data.isAnswered = false;
        }
      }
    });
  })
  .handleAction(
    transformToArray(asyncSetUser),
    createAsyncReducer(asyncSetUser, 'user'),
  );

export default reducer;

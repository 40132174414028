import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import {
  sampleImage1,
  diaryDefault,
  btnAddDiary,
  sampleProfile1,
} from '../../assets/images';
import { iconBookmark, iconManager } from '../../assets/images/icon';
import Header from '../../layouts/Header';
import UserThumbList from '../../components/common/UserThumbList';
import useStack from '../../hooks/useStack';
import useGlobalFunctions from '../../hooks/useGlobalFunctions';
import useNavigator from '../../hooks/useNavigator';
import useSetToken from '../../hooks/useSetToken';
import useTokenValidationCheck from '../../hooks/useTokenValidationCheck';
import {
  asyncSetDiaries,
  IDiary,
  actions as homeActions,
  IMember,
} from '../../store/home';
import { actions as commonActions } from '../../store/common';
import { RootState } from '../../store';
import useMyProfile from '../../hooks/useMyProfile';
import useFetchData from '../../hooks/useFetchData';
import useAxiosConfiguration from '../../hooks/useAxiosConfiguration';
import Tutorial from '../../components/common/Tutorial';
import BlockApp from '../../components/common/BlockApp';

SwiperCore.use([Pagination]);

function Home({ location }: RouteComponentProps): JSX.Element {
  const query = queryString.parse(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setValue('currentPage', location.pathname));
  }, []);

  const { addStackScreen } = useStack();

  useEffect(() => {
    // 푸시로 들어온 경우
    if (query.diaryId)
      dispatch(
        commonActions.setValue('selectedDiaryId', Number(query.diaryId)),
      );
    if (query.date)
      dispatch(commonActions.setValue('selectedDiaryDate', query.date));
    if (query.stack === 'StackDiaryDetail') addStackScreen('StackDiaryDetail');
    if (query.stack === 'StackInvitationWaiting')
      addStackScreen('StackInvitationWaiting');
    if (query.commentId) {
      dispatch(
        commonActions.setValue('scrollToComment', {
          commentId: Number(query.commentId),
          commentOffset: null,
        }),
      );
    }
  }, []);

  const { fetchMainDiaries } = useFetchData();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    fetchMainDiaries();
    setIsMounted(true);
    checkFirstLogin();
    intervalDiaryUpdate();
  }, []);

  const [showNavigator, hideNavigator] = useNavigator();

  const checkFirstLogin = () => {
    try {
      const isJoined = localStorage.getItem('isJoined');
      if (!isJoined) {
        addStackScreen('StackProfileForm');
        hideNavigator();
        dispatch(commonActions.setValue('isTutorialShow', true));
      }
    } catch (e: any) {
      alert(e.message);
    }
  };

  const intervalDiaryUpdate = () => {
    // 10분 단위로 다이어리 가져오기
    const minutes = Number(dayjs().format('mm'));
    const firstUpdateTime = (10 - (minutes % 10)) * (1000 * 60);

    let interval: NodeJS.Timeout;
    const timer = setTimeout(() => {
      fetchMainDiaries();
      interval = setInterval(() => {
        fetchMainDiaries();
      }, 1000 * 60 * 10);
    }, firstUpdateTime);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  };

  const [currentDiary, setCurrentDiary] = useState<IDiary | null>(null);
  const { diaries, currentSlideIndex, slideToIndex } = useSelector(
    (state: RootState) => state.home,
  );
  const { isWatchedAd, isTutorialShow } = useSelector(
    (state: RootState) => ({
      isWatchedAd: state.common.isWatchedAd,
      isTutorialShow: state.common.isTutorialShow,
    }),
    shallowEqual,
  );

  const changeCurrentDiary = (index: number) => {
    if (diaries.data !== null) {
      const targetDiary = diaries.data[index];

      if (targetDiary) setCurrentDiary(targetDiary);
      else setCurrentDiary(null);
    }
  };

  const onSlideChange = useCallback((swiper) => {
    dispatch(homeActions.setValue('currentSlideIndex', swiper.activeIndex));
  }, []);

  useEffect(() => {
    changeCurrentDiary(currentSlideIndex);
  }, [currentSlideIndex]);

  useEffect(() => {
    changeCurrentDiary(slideToIndex || 0);
  }, [diaries]);

  const onClickAddDiary = useCallback(() => {
    if (diaries.data && diaries.data.length >= 10)
      return dispatch(
        commonActions.setValue(
          'alertMessage',
          '다이어리 최대 보유 수는 10개 입니다.',
        ),
      );

    // 앱함수 호출
    if (
      process.env.REACT_APP_ENV === 'dev' ||
      isWatchedAd ||
      !window.flutter_inappwebview
    ) {
      addStackScreen('StackCreateDiary');
    } else {
      // 광고 노출 오류로 인한 임시 주석처리
      // window.flutter_inappwebview.callHandler('showRewardAd');
      window.flutter_inappwebview
        .callHandler('showRewardAd')
        .then((result: boolean) => {
          if (result === true || result === false) {
            addStackScreen('StackCreateDiary');
          }
        });
    }
  }, [diaries, isWatchedAd]);

  const onClickFavorite = useCallback(
    async (e, id: number, isFavorite?: 'Y' | 'N') => {
      e.stopPropagation();
      const favorite = isFavorite === 'Y' ? 'N' : 'Y';

      try {
        const { data } = await axios.put(`/diaries/${id}/favorite`, {
          favorite,
        });
        if (data.success) {
          if (isFavorite === 'N') fetchMainDiaries('init');
          else fetchMainDiaries();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [fetchMainDiaries],
  );

  const onClickDiaryCard = useCallback(
    (diaryId) => {
      console.log('current : ', currentDiary);
      dispatch(commonActions.setValue('selectedDiaryId', diaryId));

      if (
        currentDiary?.status === 'P' &&
        currentDiary?.currentQuestion !== null
      ) {
        addStackScreen('StackDiaryDetail');
      }

      if (
        (currentDiary?.status === 'P' &&
          currentDiary?.currentQuestion === null) ||
        currentDiary?.status === 'R'
      ) {
        if (currentDiary?.group === '나')
          addStackScreen('StackSetQuestionTime');
        else addStackScreen('StackInvitationWaiting');
      }
    },
    [currentDiary, addStackScreen],
  );

  const [memberThumbList, setMemberThumbList] = useState<IMember[]>([]);
  const makeMemberThumbList = () => {
    if (currentDiary) {
      const memberList = [...currentDiary?.members];
      memberList.shift();
      setMemberThumbList(memberList);
    }
  };

  useEffect(() => {
    makeMemberThumbList();
  }, [currentDiary]);

  const [swiper, setSwiper] = useState<any>(null);

  const moveToSlide = (index: number) => {
    swiper?.slideTo(index);
  };

  const { myProfile } = useSelector((state: RootState) => state.common);

  const [screenHeight, setScreenHeight] = useState<number | null>(null);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  return (
    <Container>
      <Header />
      {/* <button type="button" style={{ marginTop: '100px' }} onClick={alert}>
        테스트 버튼
      </button> */}
      <Content>
        {!currentDiary && diaries.loading && <div className="info" />}

        {!currentDiary && !diaries.loading && (
          <div className="info guide">
            <h2 className="title">다이어리 생성</h2>
            <p className="ment">
              함께 질문일기를 작성할 친구를
              <br /> 초대해 추억을 쌓아보세요!
            </p>
          </div>
        )}

        {currentDiary && (
          <div className="info">
            <h2 className="title">{currentDiary.name}</h2>
            <p className="category">{currentDiary.group}</p>
            <div className="users">
              <div
                className={`main-thumb ${
                  currentDiary.members.length === 1 ? 'only' : ''
                }`}
                style={{
                  backgroundImage: `url(${currentDiary.members[0].profileUrl})`,
                }}
              >
                {currentDiary.members[0].isOwner && (
                  <img
                    className="icon-manager"
                    src={iconManager}
                    alt="방장 아이콘"
                  />
                )}
              </div>
              {currentDiary.members.length > 1 && (
                <UserThumbList userList={memberThumbList} isWithLargeThumb />
              )}
            </div>
          </div>
        )}

        <SwiperArea
          showPager={!!(diaries && diaries.data && diaries.data.length >= 1)}
          screenHeight={screenHeight || 0}
        >
          <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            direction="vertical"
            pagination={{
              el: '.swiper-vertical-pager',
              dynamicBullets: true,
            }}
            onSlideChange={onSlideChange}
            onClick={() => {}}
            className="mySwiper"
            onUpdate={() => moveToSlide(slideToIndex)}
          >
            {diaries.data && diaries.data.length === 0 && (
              <SwiperSlide>
                <div
                  className="swiper-card"
                  style={{
                    backgroundImage: `url(${diaryDefault})`,
                  }}
                />
              </SwiperSlide>
            )}

            {diaries.data &&
              diaries.data.length > 0 &&
              diaries.data.map((diary) => (
                <SwiperSlide key={diary.id}>
                  {diaries.data &&
                    diaries.data.length > 0 &&
                    diaries.data.length <= 10 && (
                      <div
                        role="button"
                        className="swiper-card"
                        style={{
                          backgroundImage: `url(${diary.coverUrl})`,
                        }}
                        onClick={() => onClickDiaryCard(diary.id)}
                      >
                        <div className="layer">
                          <BtnBookmark
                            active={diary.isFavorite === 'Y'}
                            onClick={(e) =>
                              onClickFavorite(e, diary.id, diary.isFavorite)
                            }
                          >
                            <span className="icon-bookmark" />
                          </BtnBookmark>
                          {diary.status === 'P' && diary.currentQuestion && (
                            <div className="title-wrap">
                              <div className="status">
                                <p className="num">
                                  Q. {diary.currentQuestion.number}
                                  <span className="complete">
                                    {diary.currentQuestion.isAnswered
                                      ? '작성완료'
                                      : ''}
                                  </span>
                                </p>
                                <p className="count">
                                  <strong>
                                    {diary.currentQuestion.answeredMemberCount}
                                  </strong>
                                  /
                                  {diary.currentQuestion.activeTotalMemberCount}
                                </p>
                              </div>
                              <p className="title">
                                {diary.currentQuestion.content}
                              </p>
                            </div>
                          )}
                          {((diary.status === 'P' &&
                            diary.currentQuestion === null) ||
                            diary.status === 'R') && (
                            <div className="title-wrap">
                              <div className="status">
                                <p className="num">
                                  <span className="complete" />
                                </p>
                                <p className="count">
                                  <strong>{diary.members.length}</strong>/
                                  {diary.members.length}
                                </p>
                              </div>
                              <p className="title">
                                초대한 분들의 <br />
                                참여를 기다리고 있어요
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </SwiperSlide>
              ))}

            {diaries.data &&
              diaries.data.length > 0 &&
              diaries.data.length < 10 && (
                <SwiperSlide>
                  <div
                    className="swiper-card"
                    style={{
                      backgroundImage: `url(${diaryDefault})`,
                    }}
                  />
                </SwiperSlide>
              )}
          </Swiper>
          {!diaries.loading && (
            <BtnAddDiary
              onClick={onClickAddDiary}
              positionCenter={
                !!(
                  (diaries && diaries.data && diaries.data.length === 0) ||
                  (diaries.data &&
                    diaries.data.length < 10 &&
                    diaries.data.length + 1 === currentSlideIndex + 1)
                )
              }
            >
              <img src={btnAddDiary} alt="다이어리 추가 버튼" />
            </BtnAddDiary>
          )}
          <SwiperPager className="swiper-container-vertical">
            <div className="swiper-vertical-pager " />
          </SwiperPager>
        </SwiperArea>
      </Content>

      {isTutorialShow && <Tutorial />}

      {myProfile && !myProfile.isAvailable && <BlockApp />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  padding-top: 50px;

  .info {
    height: 210px;
    padding-top: 30px;
    text-align: center;

    &.guide {
      padding-top: 50px;
    }

    .title {
      font-size: 1.8rem;
    }
    .ment {
      margin-top: 20px;
      color: ${({ theme }) => theme.color.gray1};
    }
    .category {
      margin-top: 10px;
      color: ${({ theme }) => theme.color.gray1};
    }

    .users {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .main-thumb {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        margin-top: 12px;
        margin-bottom: 6px;

        &.only {
          width: 64px;
          height: 64px;
          margin-top: 18px;
        }
      }

      .icon-manager {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
      }
    }
  }
`;

const SwiperArea = styled.div<{
  showPager: boolean;
  screenHeight: number;
}>`
  width: 75%;
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme }) => theme.color.gray3};
  border-radius: 16px;

  .mySwiper {
    height: ${({ screenHeight }) =>
      screenHeight > 770 ? '50vh' : screenHeight > 700 ? '45vh' : '40vh'};
    border-radius: 16px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .swiper-card {
    height: ${({ screenHeight }) =>
      screenHeight > 770 ? '50vh' : screenHeight > 700 ? '45vh' : '40vh'};
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4962359943977591) 0%,
        rgba(255, 255, 255, 0) 50%
      );

      .title-wrap {
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        padding: 0 20px;

        .status {
          display: flex;
          justify-content: space-between;

          .num {
            color: #fff;
            font-size: 1.2rem;
            margin-bottom: 10px;

            .complete {
              color: #fff;
              font-size: 1.2rem;
              margin-left: 6px;
            }
          }
          .count {
            color: #fff;
            font-size: 1.2rem;
            font-weight: 300;

            strong {
              color: #fff;
              font-size: 1.2rem;
            }
          }
        }

        .title {
          color: #fff;
          font-size: 1.4rem;
        }
      }
    }
  }

  .swiper-vertical-pager {
    opacity: ${({ showPager }) => (showPager ? 1 : 0)};
  }
`;

const BtnAddDiary = styled.button.attrs({ type: 'button' })<{
  positionCenter: boolean;
}>`
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -24px);
  transition: all 0.5s ease;

  img {
    width: 48px;
  }

  ${({ positionCenter }) =>
    positionCenter &&
    css`
      top: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
    `}
`;

const BtnBookmark = styled.button<{
  active: boolean;
}>`
  position: absolute;
  top: 15px;
  right: 15px;

  .icon-bookmark {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: url(${iconBookmark}) no-repeat 0 0;
    background-size: 28px;

    ${({ active }) =>
      active &&
      css`
        background-position-y: -28px;
      `};
  }
`;

const SwiperPager = styled.div`
  position: absolute;
  top: 50%;
  right: -30px;
`;

export default Home;

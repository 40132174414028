import { createAsyncAction, deprecated } from 'typesafe-actions';
import { AxiosError } from 'axios';
import { ISelectableDiaryList } from './types';

const { createAction, createStandardAction } = deprecated;

export const Types = {
  SET_VALUE: 'scrap/SET_VALUE' as const,
  SET_SCRAP_MY_DIARY: 'scrap/SET_SCRAP_MY_DIARY' as const,
  SET_SCRAP_MY_DIARY_SUCCESS: 'scrap/SET_SCRAP_MY_DIARY_SUCCESS' as const,
  SET_SCRAP_MY_DIARY_ERROR: 'scrap/SET_SCRAP_MY_DIARY_ERROR' as const,
  SET_SCRAP_OPEN_SPACE: 'scrap/SET_SCRAP_OPEN_SPACE' as const,
  SET_SCRAP_OPEN_SPACE_SUCCESS: 'scrap/SET_SCRAP_OPEN_SPACE_SUCCESS' as const,
  SET_SCRAP_OPEN_SPACE_ERROR: 'scrap/SET_SCRAP_OPEN_SPACE_ERROR' as const,
  TOGGLE_SCRAP_MY_DIARY: 'scrap/TOGGLE_SCRAP_MY_DIARY' as const,
  TOGGLE_SCRAP_OPEN_SPACE: 'scrap/TOGGLE_SCRAP_OPEN_SPACE' as const,
  SET_SCRAP_OPEN_SPACE_READY_COUNT:
    'scrap/SET_SCRAP_OPEN_SPACE_READY_COUNT' as const,
  SET_SELECTABLE_DIARY_LIST: 'scrap/SET_SELECTABLE_DIARY_LIST' as const,
  SET_SELECTABLE_DIARY_LIST_SUCCESS:
    'scrap/SET_SELECTABLE_DIARY_LIST_SUCCESS' as const,
  SET_SELECTABLE_DIARY_LIST_ERROR:
    'scrap/SET_SELECTABLE_DIARY_LIST_ERROR' as const,
};

export const setValue = createAction(
  Types.SET_VALUE,
  (action) => (key: string, value: any) =>
    action({
      key,
      value,
    }),
);

export const setScrapMyDiary = createStandardAction(Types.SET_SCRAP_MY_DIARY)();

export const setScrapMyDiarySuccess = createAction(
  Types.SET_SCRAP_MY_DIARY_SUCCESS,
  (action) => (list: any, nextPageUrl: string) =>
    action({
      list,
      nextPageUrl,
    }),
);

export const setScrapMyDiaryError = createAction(
  Types.SET_SCRAP_MY_DIARY_ERROR,
  (action) => (error: any) =>
    action({
      error,
    }),
);

export const setScrapOpenSpace = createStandardAction(
  Types.SET_SCRAP_OPEN_SPACE,
)();

export const setScrapOpenSpaceSuccess = createAction(
  Types.SET_SCRAP_OPEN_SPACE_SUCCESS,
  (action) => (list: any, nextPageUrl: string) =>
    action({
      list,
      nextPageUrl,
    }),
);

export const setScrapOpenSpaceError = createAction(
  Types.SET_SCRAP_OPEN_SPACE_ERROR,
  (action) => (error: any) =>
    action({
      error,
    }),
);

export const toggleScrapMyDiary = createAction(
  Types.TOGGLE_SCRAP_MY_DIARY,
  (action) => (index: number) =>
    action({
      index,
    }),
);

export const toggleScrapOpenSpace = createAction(
  Types.TOGGLE_SCRAP_OPEN_SPACE,
  (action) => (index: number) =>
    action({
      index,
    }),
);

export const setScrapOpenSpaceReadyCount = createAction(
  Types.SET_SCRAP_OPEN_SPACE_READY_COUNT,
  (action) => (openSpaceId: number, readyCount: number) =>
    action({
      openSpaceId,
      readyCount,
    }),
);

export const setSelectableDiaryList = createStandardAction(
  Types.SET_SELECTABLE_DIARY_LIST,
)();

export const setSelectableDiaryListSuccess = createAction(
  Types.SET_SELECTABLE_DIARY_LIST_SUCCESS,
  (action) => (list: ISelectableDiaryList[]) =>
    action({
      list,
    }),
);

export const setSelectableDiaryListError = createAction(
  Types.SET_SELECTABLE_DIARY_LIST_ERROR,
  (action) => (error: any) =>
    action({
      error,
    }),
);

export const actions = {
  setValue,
  setScrapMyDiary,
  setScrapMyDiarySuccess,
  setScrapMyDiaryError,
  setScrapOpenSpace,
  setScrapOpenSpaceSuccess,
  setScrapOpenSpaceError,
  toggleScrapMyDiary,
  toggleScrapOpenSpace,
  setScrapOpenSpaceReadyCount,
  setSelectableDiaryList,
  setSelectableDiaryListSuccess,
  setSelectableDiaryListError,
};

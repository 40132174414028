import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import Button from '../../parts/Button';
import { RootState } from '../../../store';

function StackSendInvitation(): JSX.Element {
  const { replaceStackScreen } = useStack();

  const { myProfile } = useSelector((state: RootState) => state.common);
  const { diaryTempData } = useSelector((state: RootState) => state.home);

  const onClickInvitationBtn = () => {
    window.flutter_inappwebview.callHandler(
      'share',
      `
    같이 쓰는 질문 다이어리
    💌 ‘아모르’ 초대장💌이 도착했어요!
    당신을 궁금해하는 [${myProfile?.nickname}]님과 [${diaryTempData?.name}] 다이어리를 함께 쓰시겠어요??? 코드: ${diaryTempData?.code}
    https://markerbiz.page.link/zLL2
        `,
    );

    replaceStackScreen('StackInvitationWaiting');
  };

  return (
    <Container>
      <StackHeader />
      <Content>
        <div>
          <p className="ment">
            이제 함께할 분들을
            <br /> 불러볼까요?
          </p>
          <p className="caution">
            *본인 포함 최대 10명이 함께 질문
            <br /> 다이어리를 쓸 수 있어요.
          </p>
        </div>
      </Content>
      <BtnWrap>
        <Button fullWidth onClick={onClickInvitationBtn}>
          초대하기
        </Button>
      </BtnWrap>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .ment {
    font-size: 2.8rem;
    margin-bottom: 30px;
  }
  .caution {
    font-size: 1.2rem;
    ${({ theme }) =>
      theme &&
      css`
        color: ${({ theme }) => theme.color.main1};
      `}
  }
`;
const BtnWrap = styled.div`
  padding: 0 15px 40px;
`;

export default StackSendInvitation;

import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  btnBack,
  btnDeleteSmall,
  btnDeleteTag,
  logoHeader,
} from '../../assets/images';
import { iconAlarm, iconMenu, iconSearch } from '../../assets/images/icon';
import SideMenu from '../../components/sidemenu';
import useStack from '../../hooks/useStack';
import { actions as commonActions } from '../../store/common';
import useNavigator from '../../hooks/useNavigator';
import { RootState } from '../../store';

interface IProps {
  title?: string;
  btnRight?: JSX.Element;
  selectedTabMenu?: string;
  tabMenuLeft?: string;
  tabMenuRight?: string;
  tabMenuClick?: (tabName: string) => void;
  callBackHashTags?: (tags: string[] | []) => void;
  hasTagList?: boolean;
  setIsShowSearchTags?: (status: boolean) => void;
}

interface ITag {
  tag: string;
  count: string;
}

interface ITagCookie {
  tag: string;
  date: string;
}

function Header({
  title,
  btnRight,
  selectedTabMenu,
  tabMenuLeft,
  tabMenuRight,
  tabMenuClick,
  callBackHashTags,
  hasTagList = false,
  setIsShowSearchTags,
}: IProps): JSX.Element {
  const [cookies, setCookie, removeCookie] = useCookies(['tagHistory']);
  const [searchInputFocus, setSearchInputFocus] = useState(false);
  const [searchText, setSearchText] = useState<string | ''>('');
  const [searchTags, setSearchTags] = useState<string[] | []>([]);
  const [autoTagList, setAutoTagList] = useState<ITag[] | []>([]);
  // const [visibleSidemenu, setVisibleSidemenu] = useState(false);
  const [searchHistory, setSearchHistory] = useState<ITagCookie[] | []>(
    cookies.tagHistory,
  );

  const { addStackScreen } = useStack();
  const dispatch = useDispatch();

  const { visibleSideMenu } = useSelector((state: RootState) => state.common);

  const onToggleSidemenu = useCallback(() => {
    if (!visibleSideMenu) {
      getScreenLockStatus();
    }

    dispatch(commonActions.setValue('visibleSideMenu', !visibleSideMenu));
    // setVisibleSidemenu((v) => !v);
  }, [visibleSideMenu]);

  const getScreenLockStatus = () => {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview
        .callHandler('getScreenLockEnabled')
        .then(function (result: any) {
          dispatch(commonActions.setValue('isScreenLock', result));
        });
    }
  };

  useEffect(() => {
    setSearchHistory(cookies.tagHistory);
  }, cookies.tagHistory);

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);

    if (!e.target.value) {
      return;
    }

    axios.get('/tags', { params: { tag: e.target.value } }).then((data) => {
      if (data && data.status === 200) {
        setAutoTagList(data.data.list);
      } else {
        setAutoTagList([]);
      }
    });
  };

  const onClickTag = (newTag: string) => {
    let isExist = false;
    const tagList = [...searchTags];
    searchTags.forEach(function (tag, index) {
      if (tag === newTag) {
        isExist = true;
      }
    });

    if (!isExist) {
      tagList.push(newTag);
    }

    setSearchTags(tagList);

    if (callBackHashTags) {
      callBackHashTags(tagList || []);
    }

    setSearchText('');
    setSearchInputFocus(false);

    addTagIntoCookie(newTag);
  };

  function leftPad(value: number) {
    if (value > 9) {
      return value;
    }
    return `0${value}`;
  }

  function toStringByFormatting(source: Date, delimiter = '-') {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());
    return [year, month, day].join(delimiter);
  }

  const addTagIntoCookie = (tag: string) => {
    const addedTag = { tag, date: toStringByFormatting(new Date(), '.') };
    if (cookies.tagHistory && cookies.tagHistory.length > 0) {
      let tempList: ITagCookie[] = [...cookies.tagHistory];
      tempList = tempList.filter((data) => data.tag !== tag);
      tempList.unshift(addedTag);
      setCookie('tagHistory', tempList);
    } else {
      setCookie('tagHistory', [addedTag]);
    }
  };

  const onClickTagDel = (delTag: string) => {
    if (callBackHashTags) {
      const newTags = searchTags.filter((tag) => tag !== delTag);
      setSearchTags(newTags);
      callBackHashTags(newTags);
    }
  };

  const onClickTagHistoryDel = (delTag: string) => {
    if (cookies.tagHistory && cookies.tagHistory.length > 0) {
      let tempList: ITagCookie[] = [...cookies.tagHistory];
      tempList = tempList.filter((data) => data.tag !== delTag);
      setCookie('tagHistory', tempList);
    }
  };
  const onClickTagHistoryDelAll = () => {
    removeCookie('tagHistory');
  };

  const onTabMenuClick = (tabName: string) => {
    if (tabMenuClick) {
      tabMenuClick(tabName);
    }
  };

  useEffect(() => {
    if (setIsShowSearchTags) {
      if (searchTags.length > 0) setIsShowSearchTags(true);
      else setIsShowSearchTags(false);
    }
  }, [searchTags]);

  const [showNavigator, hideNavigator] = useNavigator();
  useEffect(() => {
    if (title === 'Open Space') {
      if (searchInputFocus) {
        hideNavigator();
      } else {
        showNavigator();
      }
    }
  }, [searchText, searchInputFocus]);

  return (
    <Container isSearching={!!''?.length}>
      <HeaderBar>
        <div className="btn-left">
          <button
            type="button"
            className="icon-menu"
            onClick={onToggleSidemenu}
          >
            <img src={iconMenu} alt="메뉴 버튼" />
          </button>
        </div>

        <h1 className="logo">{title || <img src={logoHeader} alt="로고" />}</h1>
        <div className="btn-right">
          {btnRight}
          <BtnAlarm onClick={() => addStackScreen('StackAlarm')}>
            <img src={iconAlarm} alt="알림 아이콘" />
            {/* <span className="dot" /> */}
          </BtnAlarm>
        </div>
      </HeaderBar>
      {title === 'Open Space' && (
        <SearchArea isSearching={!!searchText?.length}>
          <div className="search-bar-wrap">
            {searchInputFocus && (
              <button
                className="btn-search-close"
                type="button"
                onClick={() => {
                  setSearchText('');
                  setSearchInputFocus(false);
                }}
              >
                <img src={btnBack} alt="닫기버튼" />
              </button>
            )}
            <div className="search-bar">
              <img className="icon-search" src={iconSearch} alt="검색 아이콘" />
              <input
                type="text"
                placeholder="# 해시태그입력"
                value={searchText}
                onChange={onChangeSearchText}
                onFocus={() => setSearchInputFocus(true)}
                // onBlur={() => setSearchInputFocus(false)}
                // onKeyPress={onKeyPressHandler}
              />
            </div>
          </div>
          {searchInputFocus && searchText?.length === 0 && (
            <div className="search-result ">
              <div className="top">
                <span className="label">최근 검색</span>
                <button type="button" onClick={() => onClickTagHistoryDelAll()}>
                  전체삭제
                </button>
              </div>
              <ul className="list">
                {searchHistory &&
                  searchHistory.map((history) => {
                    return (
                      <>
                        <li>
                          <p
                            onClick={() => {
                              if (onClickTag) {
                                setSearchInputFocus(false);
                                onClickTag(history.tag);
                              }
                            }}
                          >
                            {history.tag}
                          </p>
                          <div className="btn-wrap">
                            <span className="date">{history.date}</span>
                            <button
                              type="button"
                              onClick={() => onClickTagHistoryDel(history.tag)}
                            >
                              <img src={btnDeleteSmall} alt="삭제 버튼" />
                            </button>
                          </div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          )}
          {!!searchText?.length && (
            <div className="search-result auto-complete">
              <ul className="list">
                {autoTagList &&
                  autoTagList.map((data) => {
                    return (
                      <>
                        <li
                          onClick={() => {
                            onClickTag(data.tag);
                          }}
                        >
                          <p>{data.tag}</p>
                          <div className="btn-wrap">{data.count}</div>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          )}
        </SearchArea>
      )}
      {tabMenuLeft && tabMenuRight && (
        <TabMenu>
          <button
            type="button"
            className={selectedTabMenu === tabMenuLeft ? 'active' : ''}
            onClick={() => onTabMenuClick(tabMenuLeft)}
          >
            {tabMenuLeft}
          </button>
          <button
            type="button"
            className={selectedTabMenu === tabMenuRight ? 'active' : ''}
            onClick={() => onTabMenuClick(tabMenuRight)}
          >
            {tabMenuRight}
          </button>
        </TabMenu>
      )}
      {searchTags && searchTags.length > 0 && (
        <TagList>
          <ul>
            {searchTags &&
              searchTags.map((tag) => {
                return (
                  <>
                    <li className="tag">
                      <span className="name">{tag}</span>
                      <button className="btn-delete" type="button">
                        <img
                          src={btnDeleteTag}
                          alt="삭제 버튼"
                          onClick={() => {
                            onClickTagDel(tag);
                          }}
                        />
                      </button>
                    </li>
                  </>
                );
              })}
          </ul>
        </TagList>
      )}
      <SideMenu visible={visibleSideMenu} hideSideMenu={onToggleSidemenu} />
    </Container>
  );
}

const TagList = styled.div`
  background-color: #fff;
  overflow-x: auto;

  ul {
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 15px;

    .tag {
      margin-right: 8px;
      display: flex;
      align-items: center;
      padding: 4px 12px;
      border-radius: 25px;
      background-color: ${({ theme }) => theme.color.main2};

      .name {
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.main1};
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-delete {
        line-height: 0;
        margin-left: 10px;
        img {
          width: 12px;
        }
      }
    }
  }
`;

const Container = styled.div<{ isSearching: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  ${({ isSearching }) =>
    isSearching &&
    css`
      height: 100vh;
    `}
`;

const HeaderBar = styled.div`
  position: relative;

  .btn-left {
    position: absolute;
    top: 0;
    left: 0;
    padding: 13px;
    margin-left: 2px;

    .icon-menu {
      -webkit-tap-highlight-color: transparent;

      img {
        width: 24px;
      }
    }
  }

  .btn-right {
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px;
    margin-right: 2px;
  }

  h1 {
    text-align: center;
    line-height: 5rem;

    img {
      margin-top: 14px;
      width: 80px;
    }
  }
`;

const BtnAlarm = styled.button`
  position: relative;

  img {
    width: 24px;
  }

  .dot {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.main1};
    border: 2px solid #fff;

    position: absolute;
    top: 0;
    right: 0;
  }
`;

const TabMenu = styled.div`
  display: flex;

  button {
    height: 40px;
    flex: 1;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid transparent;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.gray2};

    &.active {
      color: #222;
      border-bottom: 3px solid ${({ theme }) => theme.color.main1};
    }
  }
`;
const SearchArea = styled.div<{ isSearching: boolean }>`
  padding: 0 15px;

  .search-bar-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .btn-search-close {
      img {
        width: 28px;
        margin-right: 10px;
      }
    }

    .search-bar {
      flex: 1;
      height: 32px;
      position: relative;
      background-color: ${({ theme }) => theme.color.gray3};
      border-radius: 8px;
      display: flex;
      align-items: center;

      .icon-search {
        width: 15px;
        margin: 0 10px;
      }

      input {
        flex: 1;
        margin-right: 15px;
        border: 0;
        background-color: transparent;
      }
    }
  }

  .search-result {
    .top {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 10px;

      .label {
        font-size: 1.2rem;
      }

      button {
        font-size: 1.2rem;
        color: ${({ theme }) => theme.color.gray1};
      }
    }

    .list {
      height: calc(100vh - 130px);
      overflow-y: auto;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;

        p {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 1.4rem;
          color: ${({ theme }) => theme.color.gray1};
        }

        .btn-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 110px;

          .date {
            font-size: 1.2rem;
            color: ${({ theme }) => theme.color.gray1};
          }

          button {
            display: flex;
            align-items: center;

            img {
              width: 12px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    &.auto-complete .list {
      height: calc(100vh - 88px);
    }
  }
`;
export default Header;

import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { loading, sampleImage1 } from '../../assets/images';
import { actions as commonActions } from '../../store/common';
import { actions as scrapActions } from '../../store/scrap';
import BottomToastModal from '../../components/common/BottomToastModal';
import NoList from '../../components/common/NoList';
import Button from '../../components/parts/Button';
import FilterSetting from '../../components/scrap/FilterSetting';
import ScrapItem from '../../components/scrap/ScrapItem';
import useGlobalFunctions from '../../hooks/useGlobalFunctions';
import Header from '../../layouts/Header';
import ModalConfirm from '../../components/common/ModalConfirm';
import useTokenValidationCheck from '../../hooks/useTokenValidationCheck';
import useAxiosConfiguration from '../../hooks/useAxiosConfiguration';
import useMyProfile from '../../hooks/useMyProfile';
import { RootState } from '../../store';
import LoadingContent from '../../components/common/LoadingContent';
import MyDiary from '../../components/scrap/MyDiary';
import OpenSpace from '../../components/scrap/OpenSpace';
import useNavigator from '../../hooks/useNavigator';

export interface ISortingOption {
  category: number | null;
  month: number | null;
  order: string;
}

function Scrap({ location }: RouteComponentProps): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.setValue('currentPage', location.pathname));
  }, []);

  const [selectedTabMenu, setSelectedTabMenu] = useState('My Diary');

  const onTabMenuClick = useCallback((tabName: string) => {
    setSelectedTabMenu(tabName);
  }, []);

  const [bottomToastModalView, setBottomToastModalView] = useState(false);

  const [modalConfirmView, setModalConfirmView] = useState(false);

  const [sortingMyDiary, setSortingMyDiary] = useState<ISortingOption>({
    category: null,
    month: 3,
    order: 'desc',
  });

  const [sortingOpenSpace, setSortingOpenSpace] = useState<
    Omit<ISortingOption, 'category'>
  >({
    month: 3,
    order: 'desc',
  });

  const onClickOrdering = (orderType: string) => {
    if (selectedTabMenu === 'My Diary') {
      setSortingMyDiary((v) => ({
        ...v,
        order: orderType,
      }));
    }
    if (selectedTabMenu === 'Open Space') {
      setSortingOpenSpace((v) => ({
        ...v,
        order: orderType,
      }));
    }
  };

  const { scrapMyDiary, scrapOpenSpace } = useSelector(
    (state: RootState) => ({
      scrapMyDiary: state.scrap.scrapMyDiary,
      scrapOpenSpace: state.scrap.scrapOpenSpace,
    }),
    shallowEqual,
  );

  const fetchScrapMyDiary = async (isFirstFetch = false) => {
    if (isFirstFetch) dispatch(scrapActions.setScrapMyDiary());

    let url = `/scraps/questions`;
    if (!isFirstFetch && scrapMyDiary.data?.nextPageUrl)
      url = scrapMyDiary.data?.nextPageUrl;

    try {
      const { data } = await axios.get(url, {
        params: {
          ...sortingMyDiary,
          length: 5,
        },
      });
      if (data.success) {
        console.log('my diary list : ', data.list);
        dispatch(
          scrapActions.setScrapMyDiarySuccess(data.list, data.nextPageUrl),
        );
      }
    } catch (e) {
      dispatch(scrapActions.setScrapMyDiaryError(e));
    }
  };

  const fetchScrapOpenSpace = async (isFirstFetch = false) => {
    if (isFirstFetch) dispatch(scrapActions.setScrapOpenSpace());

    let url = `/scraps/openspaces`;
    if (!isFirstFetch && scrapOpenSpace.data?.nextPageUrl)
      url = scrapOpenSpace.data?.nextPageUrl;

    try {
      const { data } = await axios.get(url, {
        params: {
          ...sortingOpenSpace,
          length: 5,
        },
      });
      if (data.success) {
        console.log('open space list : ', data.list);
        dispatch(
          scrapActions.setScrapOpenSpaceSuccess(data.list, data.nextPageUrl),
        );
      }
    } catch (e) {
      dispatch(scrapActions.setScrapOpenSpaceError(e));
    }
  };

  useEffect(() => {
    fetchScrapMyDiary(true);
  }, [sortingMyDiary]);

  useEffect(() => {
    fetchScrapOpenSpace(true);
  }, [sortingOpenSpace]);

  const setSortingOption = (category: number | null, month: number | null) => {
    if (selectedTabMenu === 'My Diary') {
      setSortingMyDiary((option) => ({
        ...option,
        category,
        month,
      }));
    }
    if (selectedTabMenu === 'Open Space') {
      setSortingOpenSpace((option) => ({
        ...option,
        month,
      }));
    }

    setBottomToastModalView(false);
  };

  const [prevScroll, setPrevScroll] = useState(0);
  const [scrollStatus, setScrollStatus] = useState<'up' | 'down'>('up');

  const scrollHandler = (e: React.UIEvent<HTMLDivElement>) => {
    const currentScroll = e.currentTarget.scrollTop;

    if (prevScroll < 0) {
      setPrevScroll(currentScroll);
      return;
    }

    if (prevScroll < currentScroll && scrollStatus === 'up') {
      setScrollStatus('down');
    }

    if (prevScroll > currentScroll && scrollStatus === 'down') {
      setScrollStatus('up');
    }

    setPrevScroll(currentScroll);
  };

  const [showNavigator, hideNavigator] = useNavigator();

  useEffect(() => {
    if (scrollStatus === 'up') showNavigator();
    if (scrollStatus === 'down') hideNavigator();
  }, [scrollStatus]);

  return (
    <Container>
      <Header
        title="스크랩"
        selectedTabMenu={selectedTabMenu}
        tabMenuLeft="My Diary"
        tabMenuRight="Open Space"
        tabMenuClick={onTabMenuClick}
      />

      <Content id="ScrapScrollWrap" onScroll={scrollHandler}>
        {selectedTabMenu === 'My Diary' && (
          <div className="top">
            <div className="filter-btns">
              <Button
                style={{ width: '72px' }}
                size="small"
                round
                onClick={() => setBottomToastModalView(true)}
              >
                {sortingMyDiary.category === null
                  ? '전체보기'
                  : sortingMyDiary.category === 1
                  ? '나'
                  : sortingMyDiary.category === 2
                  ? '가족'
                  : sortingMyDiary.category === 3
                  ? '연인'
                  : '친구'}
              </Button>
              <Button
                style={{ width: '72px' }}
                size="small"
                round
                onClick={() => setBottomToastModalView(true)}
              >
                {sortingMyDiary.month === 1
                  ? '1개월'
                  : sortingMyDiary.month === 3
                  ? '3개월'
                  : '6개월'}
              </Button>
            </div>
            <div className="order-btns">
              <button
                className={sortingMyDiary.order === 'desc' ? 'active' : ''}
                type="button"
                onClick={() => onClickOrdering('desc')}
              >
                최신순
              </button>
              <span className="dot" />
              <button
                className={sortingMyDiary.order === 'asc' ? 'active' : ''}
                type="button"
                onClick={() => onClickOrdering('asc')}
              >
                과거순
              </button>
            </div>
          </div>
        )}
        {selectedTabMenu === 'Open Space' && (
          <div className="top">
            <div className="filter-btns">
              <Button
                style={{ width: '72px' }}
                size="small"
                round
                onClick={() => setBottomToastModalView(true)}
              >
                {sortingOpenSpace.month === 1
                  ? '1개월'
                  : sortingOpenSpace.month === 3
                  ? '3개월'
                  : sortingOpenSpace.month === 6
                  ? '6개월'
                  : '전체보기'}
              </Button>
            </div>
            <div className="order-btns">
              <button
                className={sortingOpenSpace.order === 'desc' ? 'active' : ''}
                type="button"
                onClick={() => onClickOrdering('desc')}
              >
                최신순
              </button>
              <span className="dot" />
              <button
                className={sortingOpenSpace.order === 'asc' ? 'active' : ''}
                type="button"
                onClick={() => onClickOrdering('asc')}
              >
                과거순
              </button>
            </div>
          </div>
        )}
        {selectedTabMenu === 'My Diary' && (
          <MyDiary fetchScrapList={fetchScrapMyDiary} />
        )}
        {selectedTabMenu === 'Open Space' && (
          <OpenSpace fetchScrapList={fetchScrapOpenSpace} />
        )}
      </Content>

      {bottomToastModalView && (
        <BottomToastModal
          visible={bottomToastModalView}
          hideToastModal={() => setBottomToastModalView(false)}
        >
          <>
            {selectedTabMenu === 'My Diary' ? (
              <FilterSetting
                selectedTabMenu={selectedTabMenu}
                categoryId={sortingMyDiary.category}
                monthValue={sortingMyDiary.month}
                setSortingOption={setSortingOption}
              />
            ) : undefined}
            {selectedTabMenu === 'Open Space' ? (
              <FilterSetting
                categoryId={null}
                selectedTabMenu={selectedTabMenu}
                monthValue={sortingOpenSpace.month}
                setSortingOption={setSortingOption}
              />
            ) : undefined}
          </>
        </BottomToastModal>
      )}

      <ModalConfirm
        visible={modalConfirmView}
        title="자리가 없어요 :-("
        description="모든 다이어리가 적용 대기 중입니다"
        btnConfirm="확인"
        btnConfirmClick={() => setModalConfirmView(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding-top: 90px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.gray3};
  position: relative;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-btns {
      padding: 15px;

      button {
        margin-right: 10px;
      }
    }

    .order-btns {
      display: flex;
      align-items: center;
      margin-right: 15px;

      button {
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.gray1};

        &.active {
          color: #676767;
        }
      }

      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.gray2};
        margin: 0 8px;
      }
    }
  }

  /* .list-content {
    flex: 1;
  } */
`;

export default Scrap;

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { iconArrowSmall } from '../../../assets/images/icon';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';

function StackSettings(): JSX.Element {
  const { myProfile, isScreenLock } = useSelector(
    (state: RootState) => ({
      myProfile: state.common.myProfile,
      isScreenLock: state.common.isScreenLock,
    }),
    shallowEqual,
  );
  const { addStackScreen } = useStack();

  const languageToKorean = (language: string) => {
    if (language === 'kr') {
      return '한국어';
    }

    if (language === 'en') {
      return '영어';
    }

    if (language === 'cn') {
      return '중국어';
    }

    if (language === 'jp') {
      return '일본어';
    }

    return '';
  };

  const onClickNotice = () => {
    addStackScreen('StackNotice');
  };

  const onClickFaq = () => {
    addStackScreen('StackFaq');
  };

  return (
    <Container>
      <StackHeader title="설정" />
      <Content>
        <div className="inner-wrap">
          <SettingSection>
            <p className="title">알림</p>
            <div
              role="button"
              className="setting-item"
              onClick={() => addStackScreen('StackSettingAlarm')}
            >
              <p>푸시알림</p>
              <div className="status">
                <span>
                  {myProfile?.setting &&
                  Object.values(myProfile.setting).includes(true)
                    ? '켜짐'
                    : '꺼짐'}
                </span>
                <img
                  className="icon-arrow"
                  src={iconArrowSmall}
                  alt="화살표 아이콘"
                />
              </div>
            </div>
          </SettingSection>
          <SettingSection>
            <p className="title">개인/보안</p>
            <div
              role="button"
              className="setting-item"
              onClick={() => addStackScreen('StackSettingScreenLock')}
            >
              <p>화면 잠금</p>
              <div className="status">
                <span>{isScreenLock ? '켜짐' : '꺼짐'}</span>
                <img
                  className="icon-arrow"
                  src={iconArrowSmall}
                  alt="화살표 아이콘"
                />
              </div>
            </div>
            <div className="setting-item">
              <p onClick={() => addStackScreen('StackAccountManagement')}>
                계정 관리
              </p>
            </div>
            <div
              role="button"
              className="setting-item"
              onClick={() => addStackScreen('StackSettingLanguage')}
            >
              <p>질문 언어 설정</p>
              <div className="status">
                <span>
                  {myProfile?.language && languageToKorean(myProfile.language)}
                </span>
                <img
                  className="icon-arrow"
                  src={iconArrowSmall}
                  alt="화살표 아이콘"
                />
              </div>
            </div>
          </SettingSection>
          <SettingSection>
            <p className="title">정보</p>
            <div className="setting-item">
              <p onClick={() => addStackScreen('StackUseTerm')}>이용약관</p>
            </div>
            <div className="setting-item">
              <p onClick={() => addStackScreen('StackPrivacyPolicy')}>
                개인정보 처리 방침
              </p>
            </div>
            <div className="setting-item">
              <p onClick={onClickNotice}>공지사항</p>
            </div>
            <div className="setting-item">
              <p onClick={onClickFaq}>FAQ</p>
            </div>
          </SettingSection>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const SettingSection = styled.div`
  padding: 15px 0;

  .title {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.gray1};
    margin-bottom: 6px;
  }
  .setting-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;

    .status {
      display: flex;
      align-items: center;

      .icon-arrow {
        width: 24px;
        margin-left: 6px;
      }
    }
  }
`;

export default StackSettings;

import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import common, { userSaga } from './common';
import home, { diariesSaga } from './home';
import openSpace from './openSpace';
import scrap from './scrap';

const rootReducer = combineReducers({
  common,
  home,
  scrap,
  openSpace
});

export function* rootSaga() {
  yield all([userSaga(), diariesSaga()]);
}

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

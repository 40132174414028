import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Parser from 'html-react-parser';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';

interface attachFile {
  attachFileId: number;
  fileExt: string;
  filePath: string;
  originFilename: string;
}
interface INoticeDetail {
  title: string;
  createdAt: string;
  detail: string;
  attachFiles: attachFile[];
}

function StackNoticeDetail(): JSX.Element {
  const { selectedNoticeId } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const dateFormat = (time: string) => {
    const date = dayjs(time);
    const month = Number(date.format('MM'));
    const day = Number(date.format('DD'));
    const isAm = date.format('a') === 'am' ? '오전' : '오후';

    return date.format(`YYYY년${month}월${day}일 ${isAm} h:mm`);
  };

  const [noticeDetail, setNoticeDetail] = useState<INoticeDetail | null>(null);

  const fetchNoticeDetail = async () => {
    try {
      const { data } = await axios.get(
        `https://api-mboard.el-bigs.com/api/v1/cms/notices/${selectedNoticeId}`,
      );
      if (data.success) {
        console.log(data);
        setNoticeDetail(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchNoticeDetail();

    return () => {
      dispatch(commonActions.setValue('selectedNoticeId', null));
    };
  }, []);

  if (!noticeDetail) return <></>;

  return (
    <Container>
      <StackHeader title="공지사항" />
      <Content>
        <div className="inner-wrap">
          <h3 className="title">{noticeDetail.title}</h3>
          <p className="date">{dateFormat(noticeDetail.createdAt)}</p>
          <p className="detail">
            {Parser(noticeDetail.detail.replace(/(\n)/g, '<br />'))}
          </p>

          {noticeDetail.attachFiles.length > 0 && (
            <ul className="image-list">
              {noticeDetail.attachFiles.map((file) => {
                if (
                  file.fileExt === 'jpg' ||
                  file.fileExt === 'jpeg' ||
                  file.fileExt === 'png'
                ) {
                  return (
                    <li key={file.attachFileId}>
                      <img
                        src={`https://elbigs.azureedge.net/mboard/${file.filePath}`}
                        alt="첨부 이미지"
                      />
                    </li>
                  );
                }

                return null;
              })}
            </ul>
          )}
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
  padding-top: 20px;

  .title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: 'NanumSquare';
  }

  .date {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.gray1};
    font-family: 'NanumSquare';
  }

  .detail {
    margin: 30px 0;
    font-family: 'NanumSquare';
  }

  .image-list {
    margin: 20px 0 100px;

    li {
      width: 80%;
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }
  }

  .image {
    width: 80%;
  }
`;

export default StackNoticeDetail;

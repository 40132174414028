import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { commentBubbleGray, sampleImage1 } from '../../../assets/images';
import { iconMoreMenu } from '../../../assets/images/icon';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import BottomToastModal from '../../common/BottomToastModal';
import QuestionFeed from '../../common/QuestionFeed';
import WriteCommentInput from '../../common/WriteCommentInput';
import ReportUserForm from '../ReportUserForm';
import CommentListNoAnswer from '../../common/CommentListNoAnswer';

import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import { actions as openSpaceActions } from '../../../store/openSpace';
import ModalConfirm from '../../common/ModalConfirm';
import LoadingContent from '../../common/LoadingContent';

function StackQuestionFeedDetail(): JSX.Element {
  const { openSpaceDetail } = useSelector(
    (state: RootState) => state.openSpace,
  );
  const {
    myProfile,
    stackScreens,
    selectedOpenSpaceId,
    selectedComment,
    scrollToComment,
    fetchOpenSpaceFn,
  } = useSelector(
    (state: RootState) => ({
      myProfile: state.common.myProfile,
      stackScreens: state.common.stackScreens,
      selectedOpenSpaceId: state.common.selectedOpenSpaceId,
      selectedComment: state.common.selectedComment,
      scrollToComment: state.common.scrollToComment,
      fetchOpenSpaceFn: state.common.fetchOpenSpaceFn,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedOpenSpaceId) {
      // fetchOpenspaceDetail();
    }

    return () => {
      dispatch(
        commonActions.setValue('scrollToComment', {
          commentId: null,
          commentOffSet: null,
        }),
      );
      dispatch(openSpaceActions.setValue('openSpaceDetail', null));
      dispatch(commonActions.setValue('selectedComment', null));
    };
  }, []);

  useEffect(() => {
    if (
      stackScreens.length > 0 &&
      stackScreens[stackScreens.length - 1] === 'StackQuestionFeedDetail'
    ) {
      fetchOpenspaceDetail();
    }
  }, [stackScreens]);

  const fetchOpenspaceDetail = () => {
    axios.get(`/openspaces/${selectedOpenSpaceId}`).then((res) => {
      if (res.data && res.data.success) {
        const { data } = res.data;
        if (data.tags && data.tags.length > 0) {
          data.tagStr = `#${data.tags.join(' #')}`;
        }
        dispatch(openSpaceActions.setValue('openSpaceDetail', data));
      }
    });
  };

  const onClickMoreMenu = useCallback(() => {
    setBottomToastFeedMenu(true);
  }, []);

  const [bottomToastFeedMenu, setBottomToastFeedMenu] = useState(false);
  const [bottomToastCommentMenu, setBottomToastCommentMenu] = useState(false);
  const [bottomToastReportUser, setBottomToastReportUser] = useState(false);

  const { addStackScreen, removeStackScreen } = useStack();

  const onClickDel = () => {
    axios.delete(`/openspaces/${selectedOpenSpaceId}`).then((res) => {
      setBottomToastFeedMenu(false);
      // dispatch(
      //   asyncSetOpenSpace.request({
      //     best: 0,
      //     tags: [],
      //   }),
      // );
      removeStackScreen();

      if (fetchOpenSpaceFn) {
        fetchOpenSpaceFn(null);
      }
    });
  };

  const [modalConfirmView, setModalConfirmView] = useState(false);

  const onClickCommentEdit = useCallback(() => {
    setBottomToastCommentMenu(false);
    setCommentWriteStatus('edit');
  }, []);

  const onClickCommentDelete = useCallback(() => {
    setBottomToastCommentMenu(false);
    setModalConfirmView(true);
  }, []);

  const onDeleteComment = useCallback(async () => {
    try {
      const { data } = await axios.delete(`/comments/${selectedComment?.id}`);
      if (data.success) {
        if (selectedComment && selectedComment.id !== null)
          dispatch(openSpaceActions.deleteCommentOnFeed(selectedComment?.id));
        setModalConfirmView(false);
        // fetchMainDiaries();
      }
    } catch (e) {
      console.log(e);
    }
  }, [selectedComment]);

  const [commentWriteStatus, setCommentWriteStatus] = useState<'post' | 'edit'>(
    'post',
  );

  const hideToastCommetMenu = useCallback(() => {
    dispatch(commonActions.setValue('selectedComment', null));
    setBottomToastCommentMenu(false);
  }, []);

  const scrollContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollContentRef.current && scrollToComment?.commentOffset)
      scrollContentRef.current?.scrollTo({
        top: scrollToComment.commentOffset,
      });
  }, [scrollToComment]);

  const [currentScroll, setCurrentScroll] = useState(0);
  const onChangeScrollTop = (e: any) => {
    setCurrentScroll(e.target.scrollTop + window.innerHeight);
  };

  return (
    <Container>
      {!openSpaceDetail && <LoadingContent />}
      <StackHeader
        title={openSpaceDetail?.writer.nickname}
        btnRight={
          <BtnMoreMenu>
            <button type="button" onClick={onClickMoreMenu}>
              <img src={iconMoreMenu} alt="더보기 메뉴 버튼" />
            </button>
          </BtnMoreMenu>
        }
      />
      <Content onScroll={onChangeScrollTop} ref={scrollContentRef}>
        <QuestionFeed
          openSpaceId={selectedOpenSpaceId || 0}
          nickname={openSpaceDetail?.writer.nickname}
          isMyFeed={openSpaceDetail?.writer.nickname === myProfile?.nickname}
          createdAt={openSpaceDetail?.createdAt || ''}
          hashtags={openSpaceDetail?.tagStr || ''}
          question={openSpaceDetail?.content || ''}
          coverImage={openSpaceDetail?.attachments}
          isScrap={openSpaceDetail?.scrapYn === 'Y'}
          countScrap={openSpaceDetail?.scrapsCount || 0}
          countComment={openSpaceDetail?.commentsCount || 0}
          isFeedDetail
          isFeedRemoved={false}
        />

        <div className="inner-wrap">
          <div className="comment-list">
            {openSpaceDetail?.comments.length === 0 && (
              <NoCommentList>
                <img src={commentBubbleGray} alt="말풍선 이미지" />
                <p>아직 작성한 댓글이 없어요.</p>
              </NoCommentList>
            )}

            {openSpaceDetail && openSpaceDetail.comments.length > 0 && (
              <CommentListNoAnswer
                listType="openSpace"
                commentList={openSpaceDetail.comments}
                currentScroll={currentScroll}
                setBottomToastModalView={setBottomToastCommentMenu}
              />
            )}
          </div>
        </div>

        <WriteCommentInput
          commentWriteStatus={commentWriteStatus}
          setCommentWriteStatus={setCommentWriteStatus}
        />
      </Content>

      {bottomToastFeedMenu && (
        <BottomToastModal
          visible={bottomToastFeedMenu}
          hideToastModal={() => setBottomToastFeedMenu(false)}
        >
          <CommentBtnMenu>
            {openSpaceDetail?.writer.nickname === myProfile?.nickname ? (
              <>
                <button
                  type="button"
                  onClick={() => {
                    addStackScreen('StackCreateQuestion');
                    setBottomToastFeedMenu(false);
                  }}
                >
                  수정하기
                </button>
                <button type="button" onClick={onClickDel}>
                  삭제하기
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={() => {
                  setBottomToastReportUser(true);
                  setBottomToastFeedMenu(false);
                }}
              >
                신고하기
              </button>
            )}
          </CommentBtnMenu>
        </BottomToastModal>
      )}

      {bottomToastCommentMenu && (
        <BottomToastModal
          visible={bottomToastCommentMenu}
          hideToastModal={hideToastCommetMenu}
        >
          <CommentBtnMenu>
            <button type="button" onClick={onClickCommentEdit}>
              수정하기
            </button>
            <button type="button" onClick={onClickCommentDelete}>
              삭제하기
            </button>
          </CommentBtnMenu>
        </BottomToastModal>
      )}

      {bottomToastReportUser && (
        <BottomToastModal
          visible={bottomToastReportUser}
          hideToastModal={() => setBottomToastReportUser(false)}
        >
          {openSpaceDetail ? (
            <ReportUserForm
              reportId={openSpaceDetail.id}
              hideReportForm={() => setBottomToastReportUser(false)}
            />
          ) : (
            <></>
          )}
        </BottomToastModal>
      )}

      <ModalConfirm
        visible={modalConfirmView}
        title="확인"
        description="정말 삭제 하시겠어요?"
        btnCancel="취소"
        btnCancelClick={() => setModalConfirmView(false)}
        btnConfirm="삭제"
        btnConfirmClick={onDeleteComment}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;

  .comment-list {
    padding-top: 25px;
    border-top: 1px solid ${({ theme }) => theme.color.gray3};
    margin-bottom: 120px;
  }
`;

const BtnMoreMenu = styled.div`
  position: relative;

  .menu {
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 2;
    width: 104px;
    padding: 4px 0;

    li {
      padding: 6px 14px;
      font-size: 1.2rem;
    }
  }
`;

const CommentBtnMenu = styled.div`
  button {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 48px;
    font-size: 1.6rem;
  }
`;

const NoCommentList = styled.div`
  text-align: center;
  img {
    width: 90px;
    margin-bottom: 10px;
  }
  p {
    color: 1.3rem;
    color: ${({ theme }) => theme.color.gray2};
  }
`;

export default StackQuestionFeedDetail;

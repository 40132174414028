import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';
import { RootState } from '../../../store';
import { actions as commonActions } from '../../../store/common';
import Switch from '../../parts/Switch';

function StackSettingScreenLock(): JSX.Element {
  const { isScreenLock } = useSelector((state: RootState) => state.common);
  const [lockStatus, setLockStatus] = useState(isScreenLock);
  const dispatch = useDispatch();

  const { removeStackScreen } = useStack();

  const onClickSwitch = useCallback(() => {
    window.flutter_inappwebview.callHandler(
      'setScreenLockEnabled',
      !lockStatus,
    );

    setLockStatus((v) => !v);
  }, [lockStatus]);

  const getScreenLockStatus = () => {
    window.flutter_inappwebview
      .callHandler('getScreenLockEnabled')
      .then(function (result: any) {
        dispatch(commonActions.setValue('isScreenLock', result));
        removeStackScreen();
      });
  };

  return (
    <Container>
      <StackHeader
        title="화면 잠금 설정"
        btnRight={
          <button
            className="btn-save"
            type="button"
            onClick={getScreenLockStatus}
          >
            SAVE
          </button>
        }
      />
      <Content>
        <div className="inner-wrap">
          <SettingSection>
            <div className="setting-item">
              <p>화면 잠금</p>
              <Switch active={lockStatus} onClick={onClickSwitch} />
              <p className="desc">
                암호를 분실했을 경우, 앱을 삭제하고 재설치 해야합니다.
              </p>
            </div>
          </SettingSection>
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const SettingSection = styled.div`
  padding-top: 30px;

  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;
    position: relative;

    .desc {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.color.gray1};
      position: absolute;
      bottom: -6px;
      left: 0;
    }
  }
`;

export default StackSettingScreenLock;

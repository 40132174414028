import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Parser from 'html-react-parser';
import { RootState } from '../../../store';
import { actions } from '../../../store/common';

function AlertMessage(): JSX.Element | null {
  const [localVisible, setLocalVisible] = useState(false);
  const { alertMessage } = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alertMessage) {
      setLocalVisible(true);

      setTimeout(() => {
        setLocalVisible(false);
      }, 1500);
    }

    setTimeout(() => {
      dispatch(actions.setValue('alertMessage', ''));
    }, 2000);
  }, [alertMessage]);

  if (!alertMessage) return null;
  return (
    <Container fadeVisible={localVisible}>
      <p className="message">{Parser(alertMessage)}</p>
    </Container>
  );
}

const Container = styled.div<{ fadeVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;

  ${({ fadeVisible }) =>
    fadeVisible &&
    css`
      opacity: 1;
    `}

  .message {
    max-width: 80%;
    font-size: 1.5rem;
    color: #fff;
    padding: 6px 16px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
`;

export default AlertMessage;

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Parser from 'html-react-parser';
import { iconArrowSmall } from '../../../assets/images/icon';
import useStack from '../../../hooks/useStack';
import StackHeader from '../../../layouts/StackHeader';

function StackFaq(): JSX.Element {
  const [selectedTabMenu, setSelectedTabMenu] = useState('회원정보');
  const [selectedMemberFaqId, setSelectedMemberFaqId] = useState<number | null>(
    null,
  );
  const [selectedDiaryFaqId, setSelectedDiaryFaqId] = useState<number | null>(
    null,
  );

  const onTabMenuClick = useCallback((tabName: string) => {
    setSelectedTabMenu(tabName);
    setSelectedMemberFaqId(null);
    setSelectedDiaryFaqId(null);
  }, []);

  const onClickMemberFaq = useCallback(
    (id: number) => {
      if (selectedMemberFaqId === id) return setSelectedMemberFaqId(null);
      setSelectedMemberFaqId(id);
    },
    [selectedMemberFaqId],
  );

  const onClickDiaryFaq = useCallback(
    (id: number) => {
      if (selectedDiaryFaqId === id) return setSelectedDiaryFaqId(null);
      setSelectedDiaryFaqId(id);
    },
    [selectedDiaryFaqId],
  );

  return (
    <Container>
      <StackHeader
        title="FAQ"
        selectedTabMenu={selectedTabMenu}
        tabMenuLeft="회원정보"
        tabMenuRight="다이어리"
        tabMenuClick={onTabMenuClick}
      />
      <Content>
        {selectedTabMenu === '회원정보' && (
          <div>
            {memberFaqs.map((faq) => (
              <ListItem
                key={faq.id}
                className="list-item"
                open={selectedMemberFaqId === faq.id}
              >
                <div
                  role="button"
                  className="title-wrap"
                  onClick={() => onClickMemberFaq(faq.id)}
                >
                  <p className="title">
                    <span>Q.</span> {faq.title}
                  </p>
                  <img src={iconArrowSmall} alt="화살표 아이콘" />
                </div>
                <div className="content">{Parser(faq.content)}</div>
              </ListItem>
            ))}
          </div>
        )}

        {selectedTabMenu === '다이어리' && (
          <div>
            {diaryFaqs.map((faq) => (
              <ListItem
                key={faq.id}
                className="list-item"
                open={selectedDiaryFaqId === faq.id}
              >
                <div
                  role="button"
                  className="title-wrap"
                  onClick={() => onClickDiaryFaq(faq.id)}
                >
                  <p className="title">
                    <span>Q.</span> {faq.title}
                  </p>
                  <img src={iconArrowSmall} alt="화살표 아이콘" />
                </div>
                <div className="content">{Parser(faq.content)}</div>
              </ListItem>
            ))}
          </div>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.section`
  flex: 1;
  overflow-y: auto;
`;

const ListItem = styled.div<{ open: boolean }>`
  .title-wrap {
    display: flex;
    align-items: center;
    padding: 25px 15px;
    border-bottom: 1px solid ${({ theme }) => theme.color.gray3};

    .title {
      flex: 1;

      span {
        color: ${({ theme }) => theme.color.main1};
      }
    }

    img {
      margin-right: 8px;
      width: 24px;
      transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }

  .content {
    display: ${({ open }) => (open ? 'block' : 'none')};
    font-size: 1.2rem;
    line-height: 2.2rem;
    padding: 25px 15px;
    background-color: ${({ theme }) => theme.color.gray3};
  }
`;

const memberFaqs = [
  {
    id: 1,
    title: '다른 방법으로 가입한 계정을 통합할 수 있나요?',
    content:
      '각 계정은 통합할 수 없습니다. 편하신 방법으로 가입/로그인하셔서 이용해주시고 1개 이상의 계정이 있으신 경우 개별적으로 이용 부탁드려요.',
  },
  {
    id: 2,
    title: '회원탈퇴하고 싶어요.',
    content: `설정>계정관리>회원탈퇴 메뉴에서 탈퇴하실 수 있어요<br />
      - 탈퇴하시면 회원님이 참여중인 질문 다이어리는 모두 종료됩니다<br />
      - 탈퇴하시면 그동안 회원님이 작성했던 답변은 자동으로 삭제되지 않습니다`,
  },
];

const diaryFaqs = [
  {
    id: 1,
    title: '다이어리는 몇 개까지 만들 수 있나요?',
    content:
      '한 계정당 동시에 진행할 수 있는 다이어리는 10개입니다. 새로운 다이어리를 만들거나 참여하고 싶은데 이미 10개의 다이어리를 진행중이시라면, 아쉽지만 진행중인 다이어리를 최소 1개 이상 종료하셔야 해요.',
  },
  {
    id: 2,
    title: '다이어리를 삭제할 수는 없나요?',
    content: `- 진행중인 다이어리는 곧바로 삭제할 수 없어요 ❌<br />
- 종료된 다이어리에 한해 삭제할 수 있어요 ⭕<br />
- 진행중인 다이어리의 '다이어리 설정' 화면에서 [그만하기]를 누르면 다이어리가 종료됩니다. ※ 다이어리 상세 화면에서 상단 우측의 아이콘(점 세개)을 누르면 다이어리 설정화면이 나타납니다.<br />
- 내 다이어리>종료된 다이어리 터치>다이어리 설정화면에서 [삭제하기]를 누르면 다이어리를 삭제할 수 있습니다.<br />
- 다이어리를 종료하거나 삭제하면 되돌릴 수 없으니 유의해주세요`,
  },
  {
    id: 3,
    title: '다이어리를 그만하기를 하면 어떻게 되나요?',
    content: `다이어리 그만하기는 다이어리 종료를 의미해요. 매일 전송받던 질문을 더 이상 받지 않고, 따라서 답변 기록도 더 이상 하지 않겠다는 것입니다. 그렇지만 언제든 '내 다이어리' 화면에서 볼 수 있으니 걱정마세요. ※ '나'타입 다이어리를 제외한 그룹 다이어리는 구성원 중 한 명이라도 다이어리를 그만하거나 탈퇴하게 되면 다른 참여자도 함께 다이어리가 종료되므로 유의해주세요.`,
  },
  {
    id: 4,
    title: '답변을 수정하고 싶어요',
    content: `이미 쓴 나의 답변 텍스트 영역을 꾹👆 누르면 수정 버튼이 나타나요<br />
- 나의 답변만 수정할 수 있어요<br />
- 진행 중인 다이어리의 답변만 수정할 수 있어요<br />
- 답변을 쓴 후 24시간 이내에만 수정할 수 있어요`,
  },
  {
    id: 5,
    title: '답변을 삭제하고 싶어요',
    content: `이미 쓴 나의 답변 텍스트 영역을 꾹👆 누르면 삭제 버튼이 나타나요<br />
- 나의 답변만 삭제할 수 있어요<br />
- 삭제는 언제든 가능하지만, 삭제 후 재작성은 질문받은 시간으로부터 24시간 이내에만 가능해요`,
  },
];

export default StackFaq;
